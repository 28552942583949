import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EventNoteIcon from '@material-ui/icons/EventNote';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import Tooltip from '@material-ui/core/Tooltip';
import PopupButton from './PopupButton'
import '../index.css'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#FFF',
  },
  addWidget: {
    backgroundColor: '#ECECEC',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  tertiaryButton: {
    backgroundColor: '#61A487',
    borderRadius: 5,
    color: '#DEF3DF',
    height: 35,
    padding: theme.spacing(2),
  },
  widget: {
    backgroundColor: '#ECECEC',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
  },
  widgetLabel: {
    display: 'flex',
    flexDirection: 'row',
  },
  widgetPadding: {
    marginRight: theme.spacing(2),
  },
  widgetColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  widgetRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(1),
  },
}));

export default function Summary(props) {
  const classes = useStyles();

  // Node Status
  let numActive =  props.nodes.filter( node => (Date.now() - new Date(node.lastHeardTimestamp)) < 1000 * 60 * 60 * 24 * 3).length;

  // Node Location
  let stemCount = props.nodes.filter(node => node.nodeLocation ? node.nodeLocation === 'Stem' : false).length;
  let rootCount = props.nodes.filter(node => node.nodeLocation ? node.nodeLocation === 'Root' : false).length;

  // Plant Location
  let locations = props.nodes.map(node => node.plantLocation ? node.plantLocation : 'None');
  // locations = locations.filter(loc => loc.length < 10);
  let locationCount = locations.reduce((allLoc, loc) =>  {return {...allLoc, [loc]: ++allLoc[loc] || 1}}, {})

  // Plant Type
  let types = props.nodes.map(node => node.plant ? node.plant : 'None');
  let typeCount = types.reduce((allTypes, type) =>  {return {...allTypes, [type]: ++allTypes[type] || 1}}, {})


  return (
    <React.Fragment>
      <Tooltip title="Numerical overview of all node properties. " placement="top-start">
        <div className={classes.widgetLabel}>
          <EventNoteIcon
            color="disabled"
            fontSize="large"
            className={classes.widgetPadding}
          />
          <h2>Summary</h2>
        </div>
      </Tooltip>
      <div className={classes.widgetColumn}>
        <div className={classes.widgetRow}>
          <h4 className={classes.widgetPadding}>Node status</h4>
          <p className="body4">Active ({numActive}) Inactive ({props.nodes.length - numActive})</p>
        </div>
        <div className={classes.widgetRow}>
          <h4 className={classes.widgetPadding}>Node location</h4>
          <p className="body4">Stem({stemCount}) Root({rootCount})</p>
        </div>
      </div>
      <div className={classes.widgetColumn} style={{maxWidth: '40%'}}>
        <div className={classes.widgetRow}>
          <h4 className={classes.widgetPadding}>Plant location</h4>
          <p className="body4">{Object.keys(locationCount).map(loc => `${loc} (${locationCount[loc]}) `)}</p>
        </div>
        <div className={classes.widgetRow}>
          <h4 className={classes.widgetPadding}>Plant type</h4>
          <p className="body4">{Object.keys(typeCount).map(type => `${type} (${typeCount[type]}) `)}</p>
        </div>
      </div>
      <div className={classes.widgetRow}>
        <PopupButton />
      </div>
      <div>
        <ZoomOutMapIcon htmlColor={props.isResize ? "#000000" : "#818181"} fontSize="small" onClick={()=>props.setResize(true)}/>
        <DragIndicatorIcon htmlColor={props.isResize ? "#818181" : "#000000"} fontSize="small" onClick={()=>props.setResize(false)} />
      </div>
    </React.Fragment>
  );
}
