import React from "react";

export default function Privacy() {
  return (
    <div>
      <p><b><u>Introduction</u></b></p>

      <p>Dotmote Labs (<b>&quot;Company&quot;</b> or<b>&nbsp;&quot;We&quot;</b>)
        respect your privacy and are committed to protecting it through our compliance
        with this policy.</p>

      <p>This policy describes the types of information we may
        collect from you or that you may provide when you visit the website https://plantbeats.io
        (our &quot;<b>Website</b>&quot;) and our practices for collecting, using,
        maintaining, protecting, and disclosing that information.</p>

      <p>This policy applies to information we collect:</p>

      <ul>
        <li>On this Website.</li>

        <li>In email, text, and other electronic messages between you and
          this Website.</li>

        <li>Through mobile and desktop applications you download from this
          Website, which provide dedicated non-browser-based interaction between you and
          this Website.</li>

        <li>When you interact with our advertising and applications on
          third-party websites and services, if those applications or advertising include
          links to this policy.</li>
      </ul>

      <p>It does not apply to information collected by:</p>

      <ul>
        <li>Us offline or through any other means, including on any other
          website operated by Company or any third party; or </li>

        <li>Any third party, including through any application or content
          (including advertising) that may link to or be accessible from or on the
          Website</li>
      </ul>

      <p>Please read this policy carefully to understand our policies
        and practices regarding your information and how we will treat it. If you do
        not agree with our policies and practices, your choice is not to use our
        Website. By accessing or using this Website, you agree to this privacy policy.
        This policy may change from time to time (see Changes to Our Privacy Policy). Your continued use of this Website
        after we make changes is deemed to be acceptance
        of those changes, so please check the policy periodically for updates. </p>

      <p><b><u>Children Under the Age of 16</u></b></p>

      <p>Our Website is not intended for children under 16 years of
        age. No one under age 16 may provide any personal information to or on the
        Website. We do not knowingly collect personal information from children under 16.
        If you are under 16, do not use or provide any information on this Website or through
        any of its features, register on the Website, make any purchases through the
        Website, use any of the interactive or public comment features of this Website,
        or provide any information about yourself to us, including your name, address,
        telephone number, email address, or any screen name or user name you may use. If
        we learn we have collected or received personal information from a child under 16
        without verification of parental consent, we will delete that information. If
        you believe we might have any information from or about a child under 16,
        please contact us at info@dotmotelabs.com.</p>

      <p><b><u>Information We Collect About You and How We Collect It</u></b></p>

      <p>We collect several types of information from and about users
        of our Website, including information:</p>

      <ul>
        <li>By which you may be personally identified, such as name, postal
          address, e-mail address, telephone number, or any other identifier by which you
          may be contacted online or offline (&quot;<b>personal information</b>&quot;);</li>

        <li>That is about you but individually does not identify you, such as
          your institution; and/or</li>

        <li>About your internet connection, the equipment you use to access
          our Website, and usage details.</li>
      </ul>
      <p>We collect this information:</p>

      <ul>
        <li>Directly from you when you provide it to us.</li>

        <li>Automatically as you navigate through the site. Information
          collected automatically may include usage details, IP addresses, and
          information collected through cookies.</li>
      </ul>
      <p><span><i>Information You Provide to Us</i></span>
      </p>

      <p>The information we collect on or through our Website may
        include:</p>

      <ul>
        <li>Information that you provide by filling in forms on our Website.
          This includes information provided at the time of registering to use our
          Website, posting material, or requesting further services. We may also ask you
          for information when you report a problem with our Website.</li>

        <li>Records and copies of your correspondence (including email
          addresses), if you contact us.</li>

        <li>Details of transactions you carry out through our Website and of
          the fulfillment of your orders. You may be required to provide financial
          information before placing an order through our Website.</li>

        <li>Your search queries on the Website.</li>
      </ul>

      <p>You also may provide information to be published or
        displayed (hereinafter, &quot;<b>posted</b>&quot;) on public areas of the
        Website, or transmitted to other users of the Website or third parties (collectively,
        &quot;<b>User Contributions</b>&quot;). Your User Contributions are posted on
        and transmitted to others at your own risk. Although we limit access to certain
        pages, please be aware that no security measures are perfect or impenetrable.
        Additionally, we cannot control the actions of other users of the Website with
        whom you may choose to share your User Contributions. Therefore, we cannot and
        do not guarantee that your User Contributions will not be viewed by
        unauthorized persons.</p>

      <p><span><i>Information We Collect Through Automatic
Data Collection Technologies</i></span><i> </i></p>

      <p>As you navigate through and interact with our Website, we
        may use automatic data collection technologies to collect certain information
        about your equipment, browsing actions, and patterns, including:</p>

      <ul>
        <li> Details of your visits to our Website, including traffic data, location
          data, logs, and other communication data and the resources that you access and
          use on the Website.</li>

        <li>Information about your computer and internet connection,
          including your IP address, operating system, and browser type.</li>
      </ul>
      <p>The information we collect automatically is only statistical
        data and does not include personal information, but we may maintain it or associate
        it with personal information we collect in other ways or receive from third
        parties. It helps us to improve our Website and to deliver a better and more
        personalized service, including by enabling us to:</p>

      <ul>
        <li>Estimate our audience size and usage patterns.</li>

        <li>Store information about your preferences, allowing us to
          customize our Website according to your individual interests.</li>

        <li>Speed up your searches.</li>

        <li>Recognize you when you return to our Website.</li>
      </ul>

      <p>The technologies we use for this automatic data collection
        may include:</p>

      <ul>
      <li><b>Cookies (or browser cookies).</b> A cookie is a small file
        placed on the hard drive of your computer. You may refuse to accept browser
        cookies by activating the appropriate setting on your browser. However, if you
        select this setting you may be unable to access certain parts of our Website.
        Unless you have adjusted your browser setting so that it will refuse cookies,
        our system will issue cookies when you direct your browser to our Website. </li>
      </ul>

      <p>We do not collect personal information automatically, but we
        may tie this information to personal information about you that we collect from
        other sources or you provide to us.</p>

      <p><b><u>How We Use Your Information</u></b></p>

      <p>We use information that we collect about you or that you
        provide to us, including any personal information:</p>

      <ul>
        <li>To present our Website and its contents to you.</li>

        <li>To provide you with information, products, or services that you
          request from us.</li>

        <li>To fulfill any other purpose for which you provide it.</li>

        <li>To provide you with notices about your account, including
          expiration and renewal notices.</li>

        <li>To carry out our obligations and enforce our rights arising from
          any contracts entered into between you and us, including for billing and
          collection.</li>

        <li>To notify you about changes to our Website or any products or
          services we offer or provide though it.</li>

        <li>To allow you to participate in interactive features on our
          Website.</li>

        <li>In any other way we may describe when you provide the
          information.</li>

        <li>For any other purpose with your consent.</li>
      </ul>

      <p><b><u>Disclosure of Your Information</u></b></p>

      <p>We may disclose aggregated information about our users, and
        information that does not identify any individual, without restriction. </p>

      <p>We may disclose personal information that we collect or you
        provide as described in this privacy policy:</p>

      <ul>
        <li>To our subsidiaries and affiliates.</li>

        <li>To contractors, service providers, and other third parties we use
          to support our business and who are bound by contractual obligations to keep
          personal information confidential and use it only for the purposes for which we
          disclose it to them.</li>

        <li>To a buyer or other successor in the event of a merger,
          divestiture, restructuring, reorganization, dissolution, or other sale or transfer
          of some or all of Dotmote Lab's assets, whether as a going concern or as part
          of bankruptcy, liquidation, or similar proceeding, in which personal
          information held by Dotmote Labs about our Website users is among the assets
          transferred.</li>

        <li>To fulfill the purpose for which you provide it.</li>

        <li>For any other purpose disclosed by us when you provide the
          information.</li>

        <li>With your consent.</li>
      </ul>

      <p>We may also disclose your personal information:</p>

      <ul>
        <li>To comply with any court order, law, or legal process, including
          to respond to any government or regulatory request.</li>

        <li>To enforce or apply our <a href="/terms" style={{color: "#74bb88"}} target="_blank">terms of use</a> and other agreements, including for billing and collection
          purposes.</li>

        <li>If we believe disclosure is necessary or appropriate to protect
          the rights, property, or safety of Dotmote Labs, our customers, or others. This
          includes exchanging information with other companies and organizations for the
          purposes of fraud protection and credit risk reduction.</li>
      </ul>

      <p><b><u>Choices About How We Use and Disclose Your Information</u></b></p>

      <p>We strive to provide you with choices regarding the personal
        information you provide to us. We have created mechanisms to provide you with
        the following control over your information: </p>

      <ul>
        <li><b>Tracking Technologies and Advertising.</b> You can set your
          browser to refuse all or some browser cookies, or to alert you when cookies are
          being sent. </li>
      </ul>

      <p><b><u>Changes to Our Privacy Policy</u></b></p>

      <p>It is our policy to post any changes we make to our privacy
        policy on this page. If we make material changes to how we treat our users'
        personal information, we will notify you by email to email address specified in
        your account or through a notice on the Website home page. The date the privacy
        policy was last revised is identified at the top of the page. You are
        responsible for ensuring we have an up-to-date active and deliverable email
        address for you, and for periodically visiting our Website and this privacy
        policy to check for any changes.</p>

      <p><b><u>Contact Information</u></b></p>

      <p>To ask questions or comment about this privacy policy and
        our privacy practices, contact us at: </p>

      <p>info@dotmotelabs.com.</p>
    </div>
  );
}