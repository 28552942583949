import React, {useState, useEffect, useContext} from 'react'
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Button from "./Button";
import {UserContext} from "../App";

const SUBSCRIPTION_PRICE_ID = "price_1JY5YuDWnxRi2VSj1uBUREyL";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    '& > *': {
      margin: theme.spacing(1),
      width: '48ch',
    },
  },
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
  },
  containerSmall: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  widgetLabel: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(3),
  },
}));

export default function PaymentForm(props) {
  const [processing, setProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [newSubscription, setNewSubscription] = useState();
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const classes = useStyles();
  const {user, apiKey} = useContext(UserContext);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (!props.stripe.customer) {
      setProcessing(true);
      setError("Error: Not connected to Stripe")
    }
  }, []);

  const handleSubscribe = async () => {
    setProcessing(true);
    // Create subscription for customer. Note: assumes that user has an associated stripe account, which should've
    // been generated upon registration
    await createSubscription(props.stripe.customer.id);
    setProcessing(false);
  }

  const createSubscription = (customerId) => {
    return fetch('https://api.plantbeats.io/create-subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': user['signInUserSession']['idToken']['jwtToken'],
        'x-api-key': apiKey
      },
      body: JSON.stringify({
        priceId: SUBSCRIPTION_PRICE_ID,
        customerId: customerId,
      }),
    }).then(res => res.json()).then(data => {
      setNewSubscription(data.subscription);
      setClientSecret(data.clientSecret);
    });
  }

  const handleSubPayment = async e => {
    e.preventDefault();
    setProcessing(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: name
        }
      }
    });

    if (payload.error) {
      setProcessing(false);
      setError("Payment failed: " + payload.error.message);
    } else {
      setProcessing(false);
      props.setStripe({...props.stripe, subscription: newSubscription});
      setError('');
    }
  }

  const handleCancelSubscription = async () => {
    setProcessing(true);

    fetch('https://api.plantbeats.io/cancel-subscription', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': user['signInUserSession']['idToken']['jwtToken'],
        'x-api-key': apiKey
      },
      body: JSON.stringify({
        subscriptionId: props.stripe.subscription.id,
      }),
    })
      .then(response => {
        return response.json();
      })
      .then(cancelSubscriptionResponse => {
        // Display to the user that the subscription has been cancelled.
        console.log(cancelSubscriptionResponse);
        props.setStripe({...props.stripe, subscription: null});
        setClientSecret('');
        setProcessing(false);
      }).catch((err) => {
        console.log(err);
        setProcessing(false);
      });
  }

  return (
    <React.Fragment>
      <h1>Billing</h1>
      { props.stripe.subscription && (
        <div>
          <p>You are currently on the paid subscription</p>
          <p>Your next payment of $11.00 is on {new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(new Date(props.stripe.subscription.current_period_end * 1000))}.</p>

          <h3>Click here to cancel your subscription.</h3>
          <div style={{display: "flex", justifyContent: "center", marginTop: 50}}>
            <Button onClick={handleCancelSubscription} disabled={processing}>Cancel Subscription</Button>
          </div>
        </div>
      )}
      { !props.stripe.subscription  && (
        <div>
          <p>You are currently on a free subscription. In the free version, you can still use Plantbeats, but you will have access to fewer features. </p>
          <p>To unlock more features, you can subscribe now for a monthly payment of $11.00.</p>
          <br/>

          {clientSecret === ''  &&
          <div style={{display: "flex", justifyContent: "center", marginTop: 50}}>
            <Button onClick={handleSubscribe} disabled={processing}>Subscribe</Button>
          </div>
          }

          {clientSecret !== '' &&
            <div>
              <h3>Enter your card details below to change to the paid subscription.</h3>

              <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubPayment} style={{display: "flex", alignItems: "center", marginTop: 50}}>
                <TextField
                  id="fullName"
                  name="fullName"
                  label="Full Name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  variant="outlined" />
                <CardElement/>
              </form>
              <div style={{display: "flex", justifyContent: "center", marginTop: 50}}>
                <Button onClick={handleSubPayment} disabled={processing}>Subscribe</Button>
              </div>
            </div>
          }
          <p style={{color: 'red', textAlign: 'center'}}>{error}</p>
        </div>
      )}
    </React.Fragment>
  )
}