import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import SingleNodeInfo from './SingleNodeInfo'
import { makeStyles } from '@material-ui/core/styles';
import '../index.css'
import Tooltip from "@material-ui/core/Tooltip";
import NodeFilterB from "./NodeFilterB";

const useStyles = makeStyles((theme) => ({
  disabledIcon: {
    color: '#ECECEC',
    margin: theme.spacing(1),
  },
  iconStar: {
    color: '#FFC700',
    margin: theme.spacing(1),
  },
  widgetBorder: {
    border: '1 pt solid #61A487',
    borderRadius: 5,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
  },
  widgetColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  widgetLabel: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
  },
  widgetMargin: {
    marginRight: theme.spacing(2),
  },
  widgetPadding: {
    marginRight: theme.spacing(1),
  },
  widgetRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(1),
  },
  header: {
    margin: 0,
    padding: 0,
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  content: {
    overflow: 'auto',
    height: '85%',
  }
}));

export default function NodeInfo(props) {
  const classes = useStyles();
  const [filter, setFilter] = React.useState('all');

  let filteredNodes = props.nodes.filter(
    node => filter === 'all' ||
      (filter === 'active') === ((Date.now() - new Date(node.lastHeardTimestamp)) < 1000 * 60 * 60 * 3)
  )

  return (
    <React.Fragment>
      <div className={classes.header}>
        <Tooltip title="Overview of individual node info. " placement="top-start">
          <div className={classes.widgetLabel}>
            <InfoIcon
              color="disabled"
              fontSize="large"
              className={classes.widgetMargin}
            />
            <h2>Node Info</h2>
          </div>
        </Tooltip>
        <NodeFilterB value={filter} onChange={(e) => setFilter(e.target.value)}/>
      </div>
      <div className={classes.content}>
        {filteredNodes.map(node =>
          <SingleNodeInfo
            key={node.nodeId}
            node={node}
            nodeFavorites={props.nodeFavorites}
            setNodeFavorites={props.setNodeFavorites}
            metrics={props.metrics}
          />
        )}
      </div>
    </React.Fragment>
  );
}
