import React, {useEffect, useState} from 'react'
import {useTheme} from '@material-ui/core/styles'
import {LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, Tooltip, Legend} from 'recharts'
import {makeStyles} from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Checkbox from './Checkbox'
import {Link} from 'react-router-dom';
import CircularProgress from "@material-ui/core/circularprogress";

const oneDay = 1000 * 60 * 60 * 24;

const attributeName = {
  'meanHeatRatio': 'Mean Heat Ratio',
  'meanSoilMoisture': 'Mean Soil Moisture',
  'meanOutsideTemp': 'Mean Outside Temperature',
  'meanOutsideHumidity': 'Mean Outside Humidity'
};

const useStyles = makeStyles((theme) => ({
  chartRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(1),
    justifyContent: 'space-evenly',
    alignItems: 'center',
    border: '1pt solid #61A487',
    borderRadius: 5,
  },
  widgetRow: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1),
    justifyContent: 'space-evenly',
    alignItems: 'space-evenly',
    alignContent: 'space-evenly'
  },
  iconStar: {
    color: '#FFC700',
    marginLeft: theme.spacing(2),
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(1)
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    height: 150
  }
}));

export default function ChartB(props) {
  const [chartData, setChartData] = useState([]);
  const theme = useTheme();
  const classes = useStyles();

  useEffect(() => {
    initChartData();
  }, [props.filteredCharts, props.charts, props.sapflows]);

  const initChartData = () => {
    setChartData([]);
    props.sapflows[props.chart['gid']].map((sapflow) => {
      setChartData(chartData =>
        [...chartData,
          {
            timestamp: sapflow['timestamp'],
            [sapflow['customerId#nodeId'].substring(2)]: props.chart['inverse'] ? 1 / sapflow[props.chart['attribute']] : sapflow[props.chart['attribute']]
          }
        ]
      );
    });
  };

  const handleFavoriteOnChange = () => {
    props.chart['favorite'] = !props.chart['favorite'];
    props.fetchEditPersonalization(JSON.stringify([...props.charts]));
    props.setCharts([...props.charts]);
  };

  const handleSelectOnChange = (e) => {
    console.log(chartData)
    if (e.target.checked) {
      props.setSelectedCharts([...props.selectedCharts, props.chart['gid']])
    } else {
      props.setSelectedCharts(props.selectedCharts.filter((gid) => gid !== props.chart['gid']))
    }
  };


  return (
    <div className={classes.chartRow}>
      {props.selectedCharts ?
        <Checkbox name={props.chart['gid']} checked={props.selectedCharts.includes(props.chart['gid'])}
                  onChange={handleSelectOnChange}/> : null}
        {chartData.length === 0 ?
          <div style={{width: "40%", height: 200, padding: 50}}>
              <h3>No data could be retrieved from current date range</h3>
          </div> :
          <ResponsiveContainer width="40%" height={200}>
              <LineChart
                data={chartData}
                margin={{
                    top: 16,
                    right: 16,
                    bottom: 0,
                    left: 24,
                }}
              >
                  <XAxis
                    dataKey="timestamp"
                    scale="time"
                    tickFormatter={(unixTime) => {
                        let date = new Date(unixTime * 1000);
                        let hours = date.getHours();
                        let minutes = "0" + date.getMinutes();
                        return hours + ':' + minutes.substr(-2);
                    }}
                    stroke={theme.palette.text.secondary}>
                  </XAxis>
                  <YAxis stroke={theme.palette.text.secondary}
                    //domain={[props.chart['yMin'] ? props.chart['yMin'] : 'auto', props.chart['yMax']? props.chart['yMax'] : 'auto']}
                         allowDataOverflow={true}>
                      <Label
                        angle={270}
                        position="left"
                        style={{textAnchor: 'middle', fill: theme.palette.text.primary}}
                      >
                          {attributeName[props.chart['attribute']]}
                      </Label>
                  </YAxis>
                  {props.chart['lines'].map((line) =>
                    <Line key={line['name']} type="monotone" dataKey={line['name']} stroke={line['stroke']}
                          dot={false}/>
                  )
                  }
              </LineChart>
          </ResponsiveContainer>
        }
      <Link to={{
        pathname: '/rosestemmhr',
        state: {chart: props.chart}
      }} style={{color: '#000'}}>
        <div className={classes.widgetRow}>
          <p className="body4"><strong>Graph ID: </strong>{props.chart['gid']}</p>
          <p className="body4"><strong>Graph Title: </strong>{props.chart['graphTitle']}</p>
          <p className="body4">
            <strong>Nodes: </strong>{props.chart['lines'].map((line) => (line['name'] + '\n'))}</p>
          <p className="body4"><strong>Attribute: </strong>{attributeName[props.chart['attribute']]}</p>
        </div>
      </Link>
      {props.chart['favorite'] ? <StarIcon className={classes.iconStar} onClick={handleFavoriteOnChange}/> :
        <StarBorderIcon className={classes.iconStar} onClick={handleFavoriteOnChange}/>}
    </div>
  );
}
