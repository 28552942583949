import React from 'react';
import {Link} from 'react-router-dom'
import Button from './components/Button';
import Input from './components/Input';
import Password from './components/Password';
import './Login.css';
import {AuthState} from "@aws-amplify/ui-components";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Verification from "./components/Verification";
import {Auth} from "aws-amplify";
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import {DialogContentText} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ButtonM from '@material-ui/core/Button';
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse";



function Signup({setUser, authState, setAuthState, setStripe, setApiKey}) {
  const [form, setForm] = React.useState({
    email: '',
    password: '',
    confirmPassword: '',
    verificationCode: '',
  });

  const [terms, setTerms] = React.useState({
    open: false,
    checked: false,
  });

  const [privacy, setPrivacy] = React.useState({
    open: false,
    checked: false,
  })

  const [notification, setNotification] = React.useState({
    open: false,
    message: undefined,
  });

  // Changes form state and clears input state
  function changeFormState(authState) {
    setAuthState(authState);
    setForm({
      email: '',
      password: '',
      confirmPassword: '',
      verificationCode: '',
    });
    setNotification({open: false, message: undefined});
  }

  // Signs up using the email and password in the form
  async function signUp() {
    if (form.password !== form.confirmPassword) {
      setNotification({
        open: true,
        message: "Passwords do not match."
      });
    } else if (!terms.checked || !privacy.checked) {
      setNotification({
        open: true,
        message: "You must accept the Terms of Use and Privacy Policy."
      });
    } else {
      try {
        await Auth.signUp({
          username: form.email,
          password: form.password,
          attributes: {
            email: form.email
          }
        });
        setNotification({open: false, message: undefined});
        setAuthState(AuthState.ConfirmSignUp)
      } catch (err) {
        console.log({err});
        setNotification({open: true, message: err.message});
      }
    }
  }

  // Signs up using the provided verification code
  async function confirmSignUp() {
    try {
      await Auth.confirmSignUp(form.email, form.verificationCode);
      const user = await Auth.signIn(form.email, form.password);
      setUser(user);
      setNotification({open: false, message: undefined});
      setAuthState(AuthState.SignedIn);
      const apiKey = fetch('https://api.plantbeats.io/config', {
        method: 'GET',
        headers: {
          'Authorization': user['signInUserSession']['idToken']['jwtToken']
        }
      })
        .then(r => r.json())
        .then(data => {
          setApiKey(data['PB_API_KEY']);
          return data['PB_API_KEY']
        }).catch(console.log);

      const customer = await fetch('https://api.plantbeats.io/create-customer', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': user['signInUserSession']['idToken']['jwtToken'],
          'x-api-key': apiKey
        },
        body: JSON.stringify({
          email: user.getSignInUserSession().getIdToken().payload.email,
          sub: user.attributes.sub
        })
      })
        .then(r => r.json())
        .then(data => {
          return data.customer
        });
      setStripe({customer: customer, subscription: null})
    } catch (err) {
      console.log({ err });
      setNotification({open: true, message: err.message});
    }
  }

  // Resend conformation code
  async function resendConfirmationCode() {
    try {
      await Auth.resendSignUp(form.email);
    } catch (err) {
      console.log(err);
      setNotification({open: true, message: err.message});
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (authState === AuthState.SignUp) {
      signUp();
    } else if (authState === AuthState.ConfirmSignUp) {
      confirmSignUp();
    }
  }

  const handleNotificationClose = () => {
    setNotification({...notification, open: false});
  }

  const openTerms = () => {
    setTerms({open: true, checked: !terms.checked});
  }

  const openPrivacy = () => {
    setPrivacy({open: true, checked: !privacy.checked});

  }

  // Display for signup page
  if (authState === AuthState.SignUp) {
    return (
      <div className="Login">
        <header className="Login-header">
          <Link to="/home">
            <img src={require('./img/largeLogo.png')} alt="logo"/>
          </Link>
        </header>
        <div className="Login-toggle">
          <Link to="/login" onClick={() => { changeFormState(AuthState.SignIn); }} className="link-faded"><h1>Log in</h1></Link>
          <h1>Sign up</h1>
        </div>
        <form onSubmit={handleSubmit} className="Login-form" noValidate autoComplete="off" style={{
          height: "300px"
        }}>
          <Input onChange={(e) => setForm({...form, email: e.target.value})} />
          <Password onChange={(e) => setForm({...form, password: e.target.value})}/>
          <Password onChange={(e) => setForm({...form, confirmPassword: e.target.value})} title='Confirm Password'/>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={terms.checked}
                  onChange={() => setTerms({...terms, checked: !terms.checked})}
                  color="inherit"
                  style={{color: '#74BB88'}}
                />
              }
              label={
                <div>
                  <span>I accept the </span>
                  <span onClick={openTerms} style={{color: "blue"}}>Terms of Use</span>
                </div>
              }
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={privacy.checked}
                  onChange={() => setPrivacy({...privacy, checked: !privacy.checked})}
                  color="inherit"
                  style={{color: '#74BB88'}}
                />
              }
              label={
                <div>
                  <span>I accept the </span>
                  <span onClick={openPrivacy} style={{color: "blue"}}>Privacy Policy</span>
                </div>
              }
            />
          </FormControl>
        </form>
        <div className="Login-confirmation" style={{marginTop: 0, marginBottom: "1vh"}}>
          <Link><Button onClick={signUp}> Sign up</Button></Link>
        </div>
        <Snackbar
          message={notification.message}
          open={notification.open}
          action={
            <React.Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={handleNotificationClose}
              >
                <CloseIcon/>
              </IconButton>
            </React.Fragment>
          }
        />
        <div className="Login-3rd-party">
          <Link>
            <img src={require('./img/Google.png')} alt="Signup with Google" onClick={() =>
              Auth.federatedSignIn({ provider: "Google" })
            }/>
          </Link>
          <Link>
            <img src={require('./img/Microsoft.png')} alt="Signup with Microsoft" onClick={() => Auth.federatedSignIn()}/>
          </Link>
        </div>
        <Dialog
          open={terms.open}
        >
          <DialogTitle>Terms of Use</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TermsOfUse/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonM onClick={() => setTerms({...terms, open: false})}>
              Cancel
            </ButtonM>
            <ButtonM onClick={() => setTerms({open: false, checked: true})}>
              Agree
            </ButtonM>
          </DialogActions>
        </Dialog>
        <Dialog
          open={privacy.open}
        >
          <DialogTitle>Privacy Policy</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <PrivacyPolicy/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonM onClick={() => setPrivacy({...privacy, open: false})}>
              Cancel
            </ButtonM>
            <ButtonM onClick={() => setPrivacy({open: false, checked: true})}>
              Agree
            </ButtonM>
          </DialogActions>
        </Dialog>

      </div>
    );
  }

  // Display for confirming sign up
  if (authState === AuthState.ConfirmSignUp) {
    return (
      <div className="Login">
        <header className="Login-header">
          <img src={require('./img/largeLogo.png')} alt="logo"/>
        </header>
        <div className="Login-toggle">
          <Link to="/login" onClick={() => changeFormState(AuthState.SignIn)} className="link-faded"><h1>Log in</h1></Link>
          <h1>Sign up</h1>
        </div>
        <form onSubmit={handleSubmit} className="Login-form" noValidate autoComplete="off">
          <Input onChange={(e) => setForm({...form, email: e.target.value})} disabled={true}/>
          <Verification onChange={(e) => setForm({...form, verificationCode: e.target.value})}/>
        </form>
        <div className="Login-confirmation">
          <Link><Button onClick={confirmSignUp}> Confirm </Button></Link>
          <Link onClick={resendConfirmationCode} className="body2 link-underline">Resend Code</Link>
        </div>
        <Snackbar
          message={notification.message}
          open={notification.open}
          action={
            <React.Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={handleNotificationClose}
              >
                <CloseIcon/>
              </IconButton>
            </React.Fragment>
          }
        />
      </div>
    );
  }

  // Invalid signup auth state. Reset authState and rerender.
  setAuthState(AuthState.SignUp);
  return (
    <div> error </div>
  );
}

export default Signup;
