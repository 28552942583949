import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from './Button';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import Checkbox from './Checkbox';
import {Auth} from "aws-amplify";
import Avatar from '@material-ui/core/Avatar';
import BackupIcon from '@material-ui/icons/Backup';
import { UserContext } from "../App"
import Paper from "@material-ui/core/Paper";

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}
TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#FFF',
  },
  appBar: {
    color: '#000',
    backgroundColor: '#FFF',
    flexDirection: 'row',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: 'auto',
    // overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
  },
  containerSmall: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  formDisplay: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  formDisplayB: {
    margin: theme.spacing(1),
  },
  inputDisplay: {
    width: '40ch',
  },
  avatar: {
    width: 100,
    height: 100,
    fontSize: 50,
    position: 'absolute'
  },
  avatarContainer: {
    position: 'relative',
    height: 100,
    width: 100,
    borderRadius: "50%",
    marginBottom: 30
  },
  upload: {
    width: 100,
    height: 100,
    padding: 20,
    position: 'absolute',
    opacity: '0%',
    background: 'black',
    color: 'white',
    borderRadius: "50%",
    '&:hover': {
      opacity: '25%',
      cursor: 'pointer'
    }
  },
  buttonContainer: {
    position: 'relative',
    width: "100%",
    display: "flex",
    justifyContent: "center",
  }
}));

export default function Profile() {
  const {user, apiKey} = useContext(UserContext);
  const classes = useStyles();
  const attributes = user.getSignInUserSession().getIdToken()["payload"];
  const [fields, setFields] = React.useState({
    "name": attributes["name"] || '',
    "email": attributes["email"] || '',
    "phone_number": attributes["phone_number"] || '',
    "custom:organization": attributes["custom:organization"] || '',
    "custom:role": attributes["custom:role"] || '',
    "custom:apikey": attributes["custom:apikey"] || '',
    "custom:notifyviaphone": attributes["custom:notifyviaphone"] || "false",
  });
  const [picture, setPicture] = React.useState(attributes["picture"]);
  const [saved, setSaved] = React.useState(false);
  const uploader = React.useRef();

  const handleChange = (name) => (event) => {
    setFields({...fields, [name]: event.target.value});
  }

  const handlePhoneChange = (event) => {
    let number = event.target.value.replace(/[^\d\+]/g, '');
    if (number === "+1") {
      number = "";
    }
    setFields({...fields, "phone_number": number});
  }

  const handleCheck = (name) => (event) => {
    setFields({...fields, [name]: event.target.checked.toString()});
  }

  const handleUpload = (e) => {
    if (e.target.files.length !== 0) {
      setPicture(URL.createObjectURL(e.target.files[0]));
    }
  }

  const saveProfilePicture = () => {
    // TODO
  }

  const updateAttributes = () => {
    Auth.updateUserAttributes(user, fields)
      .then(res => {
        if (res === 'SUCCESS') {
          setSaved(true);
        }
      })
      .catch(err => console.log(err));
    saveProfilePicture();
  }


  return (
    <Grid container spacing={0}>
      <Grid item xs={10}>
        <div className={classes.avatarContainer} onClick={() => uploader.current.click()}>
          <Avatar
            className={classes.avatar}
            alt={fields["name"]}
            src={picture || '/'}
          />
          <BackupIcon className={classes.upload}/>
          <input
            ref={uploader}
            type="file"
            accept="image/png"
            onChange={handleUpload}
            style={{display: 'none'}}/>
        </div>
      </Grid>

      <Grid item xs={5}>
        <form className={classes.formDisplay} noValidate autoComplete="off">
          <TextField className={classes.inputDisplay} id="fullName" label="Full Name" value={fields["name"]} onChange={handleChange('name')} variant="outlined"/>
        </form>
      </Grid>

      <Grid item xs={5}>
        <form className={classes.formDisplay} noValidate autoComplete="off">
          <TextField className={classes.inputDisplay} id="email" label="Email Address" value={fields["email"]} onChange={handleChange('email')} variant="outlined" disabled/>
        </form>
      </Grid>

      <Grid item xs={5}>
        <form className={classes.formDisplay} noValidate autoComplete="off">
          <TextField className={classes.inputDisplay} id="organization" label="Organization" value={fields["custom:organization"]} onChange={handleChange('custom:organization')} variant="outlined" />
        </form>
      </Grid>

      <Grid item xs={5}>
        <form className={classes.formDisplay} noValidate autoComplete="off">
          <TextField className={classes.inputDisplay} id="role" label="Role" value={fields["custom:role"]} onChange={handleChange('custom:role')} variant="outlined" />
        </form>
      </Grid>

      <Grid item xs={5}>
        <form className={classes.formDisplayB} noValidate autoComplete="off">
          <TextField
            className={classes.inputDisplay}
            id="phone"
            name="phone"
            label="Phone Number"
            variant="outlined"
            InputProps={{
              inputComponent: TextMaskCustom,
              value: fields["phone_number"],
              onChange: handlePhoneChange,
            }}
          />
        </form>
      </Grid>

      <Grid item xs={5}>
        <form className={classes.formDisplayB} noValidate autoComplete="off">
          <TextField className={classes.inputDisplay} id="api" label="API Key" value={fields["custom:apikey"]} onChange={handleChange('custom:apikey')} variant="outlined" />
        </form>
      </Grid>

      <Grid item xs={10}>
        <div style={{padding: '1vw'}}>
          <Checkbox checked={fields["custom:notifyviaphone"]==="true"} onChange={handleCheck("custom:notifyviaphone")}/>
          <p style={{display:'inline'}}>Use my phone number to receive node notifications.</p>
        </div>
      </Grid>

      <Grid item xs={10}>
        <div className={classes.buttonContainer}>
          <Button color='secondary' onClick={updateAttributes}>Update</Button>
          <p style={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            margin: 0,
            paddingLeft: 400,
            display: saved ? '': 'none'
          }}>
            ✔ Changes Saved
          </p>
        </div>
      </Grid>
    </Grid>
  );
}
