import React, {useEffect, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import AttributeFilter from './AttributeFilter';
import ValueFilter from './ValueFilter';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import SingleNotificationInfo from './SingleNotificationInfo';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import {CircularProgress} from "@material-ui/core";
import {UserContext} from "../App.js";


const GreenSwitch = withStyles({
  switchBase: {
    color: green[300],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#FFF',
  },
  appBar: {
    color: '#000',
    backgroundColor: '#FFF',
    flexDirection: 'row',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: 'auto',
    // overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
  },
  containerSmall: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  toggle: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    border: '1pt solid #61A487',
    borderRadius: 5,
    marginBottom: '5pt',
    padding: '15pt',
    boxSizing: 'border-box',
  },
  standard: {
    display: 'inline-block',
    marginTop: theme.spacing(0.5),
  },
  standardText: {
    color: '#000000',
    fontFamily: 'Nunito',
    fontSize: 16,
    fontWeight: 500,
    marginTop: theme.spacing(1),
  },
  tertiaryButton: {
    backgroundColor: '#61A487',
    borderRadius: 5,
    color: '#DEF3DF',
    height: 40,
    padding: theme.spacing(2),
  },
  iconCircleMain: {
    color: '#61A487',
    margin: theme.spacing(0, 3, 0, 0),
  },
  widgetLabel: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(3),
  },
  inputDisplay: {
    width: '25ch',
  },
  formControl: {
    backgroundColor: '#ECECEC',
    borderRadius: 5,
    flexDirection: 'row',
    margin: theme.spacing(0, 2),
    maxHeight: '35px',
    padding: theme.spacing(2),
    width: '150px',
  },
  select: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default function Notification(props) {
  const classes = useStyles();
  const {user, apiKey} = useContext(UserContext);
  const [form, setForm] = React.useState({
    attribute: 'all',
    nodeId: 'Select a Node',
    bound: 'equal',
    value: 0,
  });

  const [alerts, setAlerts] = React.useState([]);
  const [updated, setUpdated] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: false,
    checkedC: false,
  });

  const menuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    getContentAnchorEl: null
  };

  useEffect(() => {
    fetchAlerts(user);
  }, [updated]);

  const handleFormChange = (name) => (event) => {
    setForm({...form, [name]: event.target.value})
  }

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const onClickAdd = () => {
    if (form.nodeId !== 'Select a Node') {
      let ranges;
      if (form.bound === 'equal') {
        ranges = [{lowerLimit: form.value, upperLimit: form.value}];
      } else if (form.bound === 'below') {
        ranges = [{lowerLimit: null, upperLimit: form.value}];
      } else if (form.bound === 'above') {
        ranges = [{lowerLimit: form.value, upperLimit: null}];
      }

      addAlert({
        username: user.username,
        typeOfData: form.attribute,
        nodeID: form.nodeId,
        ranges: ranges,
        status: "Active"
      });
    }
  }

  async function fetchAlerts(user) {
    setLoading(true);
    fetch('https://api.plantbeats.io/alerts?username=' + user['username'],
      {
        method: 'GET',
        mode: "cors",
        headers: {
          'Authorization' : user['signInUserSession']['idToken']['jwtToken'],
          'x-api-key' : apiKey
        }
      })
      .then(res => res.json())
      .then(data => {
        setAlerts(data);
        setLoading(false);
      })
      .catch(console.log);
  }

  async function addAlert(body) {
    fetch('https://api.plantbeats.io/alerts',
      {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : user['signInUserSession']['idToken']['jwtToken'],
          'x-api-key' : apiKey
        }
      })
      .then(res => res.json())
      .then((data) => {
        console.log(data);
        setUpdated(!updated);
      })
      .catch(console.log);
  }


  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <FormGroup row className={classes.toggle}>
          <FormControlLabel
            control={
              <GreenSwitch
                disabled
                checked={state.checkedA}
                onChange={handleChange}
                name="checkedA"
                color="primary"
              />
            }
            label="In-app notification"
          />
          <FormControlLabel
            control={
              <GreenSwitch
                disabled
                checked={state.checkedB}
                onChange={handleChange}
                name="checkedB"
                color="primary"
              />
            }
            label="Email notification"
          />
          <FormControlLabel
            control={
              <GreenSwitch
                disabled
                checked={state.checkedC}
                onChange={handleChange}
                name="checkedC"
                color="primary"
              />
            }
            label="Phone notification"
          />
        </FormGroup>
      </Grid>
      <Container maxWidth="lg" className={classes.containerSmall}/>
    <Grid item xs={12}>
        <FiberManualRecordIcon className={classes.iconCircleMain} fontSize="small" />
        <div className={classes.standard}>
          <p className={classes.standardText}>Notify me when attribute </p>
        </div>
        <div className={classes.standard}>
          <AttributeFilter onChange={handleFormChange('attribute')} width={250}/>
        </div>
        <div className={classes.standard}>
          <p className={classes.standardText}> of </p>
        </div>
        <div className={classes.standard}>
          <FormControl className={classes.formControl} style={{width: 250}}>
            <Select
              disableUnderline
              classes={{ root: classes.select }}
              MenuProps={menuProps}
              value={form.nodeId}
              onChange={handleFormChange('nodeId')}
              input={<Input/>}
              fullWidth
            >
              <MenuItem disabled value="Select a Node">
                <p className="body3">Select a Node</p>
              </MenuItem>
              {props.nodes.map(node =>
                <MenuItem value={node.nodeId} key={node.nodeId}>
                  <p className="body3">{node.nodeId}</p>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
      <div className={classes.standard}>
        <p className={classes.standardText} style={{paddingLeft: 44}}>has a value </p>
      </div>
      <div className={classes.standard}>
        <ValueFilter onChange={handleFormChange('bound')}/>
      </div>
      <div className={classes.standard}>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            className={classes.inputDisplay}
            id="standard-basic"
            label="Enter a numerical value"
            type="number"
            onChange={handleFormChange('value')}
          />
        </form>
      </div>
      <div className={classes.standard}>
        <Button
          className={classes.tertiaryButton}
          elevation={0}
          variant="contained"
          startIcon={<AddCircleOutlineIcon htmlColor="#DEF3DF" fontSize="small" />}
          onClick={onClickAdd}
          style={{marginLeft: 30}}
        >
          <p className="body3">Add</p>
        </Button>
      </div>
    </Grid>
      <Container maxWidth="lg" className={classes.containerSmall}/>
      <Grid item xs={12}>
        {loading && <div style={{display: "flex", justifyContent: "center", width: "100%"}}><CircularProgress style={{left: "50%", color: "#61A487"}}/></div>}
        {!loading && alerts.map(alert => <SingleNotificationInfo key={alert.id} alert={alert} user={user}/>)}
      </Grid>
    </Grid>
  );
}
