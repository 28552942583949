import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import '../index.css'


// Original design here: https://github.com/siriwatknp/mui-treasury/issues/539

const FavoriteFilter = (props) => {
  const [val, setVal] = React.useState(props.value || 'all');

  const handleChange = (event) => {
    setVal(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    };
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      backgroundColor: '#ECECEC',
      borderRadius: 5,
      flexDirection: 'row',
      margin: theme.spacing(0, 2),
      maxHeight: '35px',
      padding: theme.spacing(2),
      width: '150px',
    },
    select: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    }
  }));

  const classes = useStyles();

  // moves the menu below the select input
  const menuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    getContentAnchorEl: null
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        disableUnderline
        classes={{ root: classes.select }}
        MenuProps={menuProps}
        value={val}
        onChange={handleChange}
      >
        <MenuItem value={'all'}>
          <p className="body3">All Nodes</p>
        </MenuItem>
        <MenuItem value={'active'}>
          <p className="body3">Active Nodes</p>
        </MenuItem>
        <MenuItem value={'inactive'}>
          <p className="body3">Inactive Nodes</p>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default FavoriteFilter;
