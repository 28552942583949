import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import '../index.css'


const ValueFilter = (props) => {
  const [val,setVal] = useState(props.value || 'equal');

  const handleChange = (event) => {
    setVal(event.target.value);
    props.onChange(event);
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      backgroundColor: '#ECECEC',
      borderRadius: 5,
      flexDirection: 'row',
      margin: theme.spacing(0, 2),
      maxHeight: '35px',
      padding: theme.spacing(2),
      width: '150px',
    },
    select: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    }
  }));

  const classes = useStyles();

  // moves the menu below the select input
  const menuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    getContentAnchorEl: null
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        disableUnderline
        classes={{ root: classes.select }}
        MenuProps={menuProps}
        value={val}
        onChange={handleChange}
        fullWidth
      >
        <MenuItem value="equal">
          <p className="body3">Equal to</p>
        </MenuItem>
        <MenuItem value="below">
          <p className="body3">Below</p>
        </MenuItem>
        <MenuItem value="above">
          <p className="body3">Above</p>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

ValueFilter.defaultProps = {
  onChange: () => {}
}
export default ValueFilter;
