import React, {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {CircularProgress, Container, CssBaseline, FormControlLabel, Grid, Radio, RadioGroup} from '@material-ui/core';
import AnnotateGraph from './components/AnnotateGraph';
import AnnotationTable from './components/AnnotationTable';
import BarGraph from './components/BarGraph';
import Button from './components/Button';
import EditHistory from './components/EditHistory';
import GraphFields from './components/GraphFields';
import NavPrimary from './components/NavPrimary';
import NodeTable from './components/NodeTable';
import RegressionGraph from './components/RegressionGraph';
import ScatterPlot from './components/ScatterPlot';
import Search from "./components/Search";
import SingleNodeInfo from './components/SingleNodeInfo';
import './index.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#FFF',
  },
  appBar: {
    color: '#000',
    backgroundColor: '#FFF',
    flexDirection: 'row',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: 'auto',
  },
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  h2: {
    borderBottom: '1px solid #61A487',
    lineHeight: '0.1em',
    margin: theme.spacing(7, 0),
    textAlign: 'center',
    width: '100%',
  },
  search: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(10),
  },
  radioSelect: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  singleNodeInfo: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  span: {
    backgroundColor: '#FFF',
    padding: theme.spacing(5),
  },
  widgetRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(1),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 150,
    marginBottom: 150,
  }
}));

/**
 * Creates a row of raw data for raw data table.
 * @param date
 * @param timeStamp
 * @param nodeId
 * @param meanHeatRatio
 * @param meanOutsideTemp
 * @param meanSoilMoisture
 * @param meanOutsideHumidity
 * @returns {{date: *, timeStamp: *, meanOutsideHumidity: *, meanHeatRatio: *, meanOutsideTemp: *, nodeId: *, meanSoilMoisture: *}}
 */
function createRawData(date, timeStamp, nodeId, meanHeatRatio, meanOutsideTemp, meanSoilMoisture, meanOutsideHumidity) {
  return {date, timeStamp, nodeId, meanHeatRatio, meanOutsideTemp, meanSoilMoisture, meanOutsideHumidity};
}

/**
 * Creates a row of annotation data for annotation table.
 * @param gid
 * @param label
 * @param annotationType
 * @param fromTimestamp
 * @param toTimestamp
 * @param color
 * @returns {{gid: *, color: *, fromTimestamp: *, annotationType: *, label: *, toTimestamp: *}}
 */
function createAnnotationData(gid, label, annotationType, fromTimestamp, toTimestamp, color) {
  return {gid, label, annotationType, fromTimestamp, toTimestamp, color};
}

/**
 * Initializes ad maintains the page for graph details.
 * @param props - props given by parent component.
 */
export default function RoseStemMHR(props) {
  const classes = useStyles();

  const location = useLocation();
  const {chart} = location.state;

  const [rawDataRows, setRawDataRows] = React.useState([]);
  const [annotationRows, setAnnotationRows] = React.useState([]);
  const [graphType, setGraphType] = React.useState(0);

  const refs = {
    graph: useRef(),
    nodeInfo: useRef(),
    rawData: useRef(),
    annotate: useRef(),
    editHistory: useRef()
  };

  /**
   * Updates raw data rows and annotation rows when charts, annotations, or sapflows are changed.
   */
  useEffect(() => {
    initRawDataRows();
    initAnnotationRows();
  }, [props.annotations, props.charts, props.sapflows]);

  /**
   * Initializes all rows for raw data table.
   */
  function initRawDataRows() {
    setRawDataRows([]);
    props.sapflows[chart['gid']]
      .filter((row) => row['customerId#nodeId'].substring(2) === chart['nodeIds'][0])
      .map((row) => {
        if (chart['inverse']) {
          setRawDataRows(rawDataRows =>
            [...rawDataRows,
              createRawData(
                row['date'],
                row['timestamp'],
                row['customerId#nodeId'].substring(2),
                row['meanHeatRatio'] !== 0 ? 1 / row['meanHeatRatio'] : 0,
                row['meanOutsideTemp'] !== 0 ? 1 / row['meanOutsideTemp'] : 0,
                row['meanSoilMoisture'] !== 0 ? 1 / row['meanSoilMoisture'] : 0,
                row['meanOutsideHumidity'] !== 0 ? 1 / row['meanOutsideHumidity'] : 0
              )]);
        } else {
          setRawDataRows(rawDataRows =>
            [...rawDataRows,
              createRawData(
                row['date'],
                row['timestamp'],
                row['customerId#nodeId'].substring(2),
                row['meanHeatRatio'],
                row['meanOutsideTemp'],
                row['meanSoilMoisture'],
                row['meanOutsideHumidity']
              )]);
        }
      })
  }

  /**
   * Initializes all rows for annotation table.
   */
  function initAnnotationRows() {
    setAnnotationRows([]);
    props.annotations
      .filter((row) => row['gid'] === chart['gid'])
      .map((row) => {
        setAnnotationRows(rows => [...rows, createAnnotationData(row['gid'], row['label'], row['annotationType'], row['fromTimestamp'], row['toTimestamp'], row['color'])])
      })
  }

  /**
   * Converts given rows into csv.
   * @param str - first row of csv
   * @param rows - rows to be converted
   */
  const convertToCSV = (str, rows) => {
    for (let i = 0; i < rows.length; i++) {
      let line = '';
      for (let index in rows[i]) {
        if (line !== '') line += ',';

        line += rows[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  };

  /**
   * Handles the on click function of export to csv button for raw data table.
   */
  const handleRawDataCSVOnClick = () => {
    let str = 'date,timeStamp,nodeId,meanHeatRatio,meanOutsideTemp,meanSoilMoisture,meanOutsideHumidity\r\n';
    let blob = new Blob([convertToCSV(str, rawDataRows)], {type: 'text/csv;charset=utf-8;'});
    let link = document.createElement('a');
    let url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', rawDataRows[0]['nodeId'] + '.csv');
    link.click();
  };

  /**
   * Handles the on change function of the node selected for raw data table.
   * @param e - event
   */
  const handleRawDataRadioGroupOnChange = (e) => {
    setRawDataRows([]);
    props.sapflows[chart['gid']].filter((row) => row['customerId#nodeId'].substring(2) === e.target.value).map((row) => {
      setRawDataRows(rawDataRows =>
        [...rawDataRows,
          createRawData(
            row['date'],
            row['timestamp'],
            row['customerId#nodeId'].substring(2),
            row['meanHeatRatio'],
            row['meanOutsideTemp'],
            row['meanSoilMoisture'],
            row['meanOutsideHumidity']
          )])
    })
  };

  /**
   * Handles the on click function of export to csv button for annotation table.
   */
  const handleAnnotationCSVOnClick = () => {
    let str = 'gid,label,annotationType,fromTimestamp,toTimestamp,color\r\n';
    let blob = new Blob([convertToCSV(str, annotationRows)], {type: 'text/csv;charset=utf-8;'});
    let link = document.createElement('a');
    let url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', chart['graphTitle'] + '-annotations.csv');
    link.click();
  };

  return (
    <div className={classes.root}>
      <CssBaseline/>
      <NavPrimary/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer}/>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div className={classes.search}>
                    <Search graphTitle={chart['graphTitle']}/>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <h2 className={classes.h2}>
                  <span ref={refs.graph} className={classes.span}>Graph</span>
                </h2>
              </Grid>
              <Grid item xs={12}>
                <GraphFields chart={chart}
                             nodes={props.nodes}
                             charts={props.charts}
                             setCharts={props.setCharts}
                             graphType={graphType}
                             setGraphType={setGraphType}
                             sapflows={props.sapflows}
                             setSapflows={props.setSapflows}
                             fetchSapflows={props.fetchSapflows}
                             fetchEditPersonalization={props.fetchEditPersonalization}
                             annotations={props.annotations}
                             setAnnotations={props.setAnnotations}
                             fetchAddAnnotation={props.fetchAddAnnotation}
                             stripe={props.stripe}
                />
              </Grid>
              {props.loading > 0 &&
              <div className={classes.center}>
                <CircularProgress style={{color: "#61A487"}}/>
              </div>
              }
              {props.loading === 0 &&
              <Grid item xs={12}>
                {props.sapflows[chart['gid']].length > 0 ?
                  <Grid item xs={12}>
                    {
                      graphType === 0 ?
                        <AnnotateGraph chart={chart}
                                       rawData={props.sapflows[chart['gid']]}
                                       nodes={props.nodes}
                                       charts={props.charts}
                                       annotations={props.annotations}

                        /> : null
                    }
                    {
                      graphType === 1 ?
                        <BarGraph chart={chart} rawData={props.sapflows[chart['gid']]}
                                  nodes={props.nodes}
                                  charts={props.charts}/> : null
                    }
                    {
                      graphType === 2 ?
                        <ScatterPlot chart={chart} rawData={props.sapflows[chart['gid']]}
                                     nodes={props.nodes}
                                     charts={props.charts}/> : null
                    }
                    {
                      graphType === 3 ?
                        <RegressionGraph chart={chart} rawData={props.sapflows[chart['gid']]}
                                         nodes={props.nodes}
                                         charts={props.charts}/> : null
                    })
                  </Grid> :
                  <Grid item xs={12}>
                    <div style={{height: 650}}>
                      <h2 style={{margin: 'auto'}}>No data could be retrieved from current date range</h2>
                    </div>
                  </Grid>
                }
              </Grid>}
              <Grid item xs={12}>
                <h2 className={classes.h2}>
                  <span ref={refs.nodeInfo} className={classes.span}>Node Info</span>
                </h2>
                <div className={classes.singleNodeInfo}>
                  {props.nodes.filter((node) => chart['nodeIds'].includes(node['nodeId'])).map((node) =>
                    <SingleNodeInfo
                      key={node['nodeId']}
                      node={node}
                      nodeFavorites={props.nodeFavorites}
                      setNodeFavorites={props.setNodeFavorites}
                      metrics={props.metrics}
                    />)
                  }
                </div>
              </Grid>
              <Grid>
                <h2 className={classes.h2}>
                  <span ref={refs.rawData} className={classes.span}>Raw Data</span>
                </h2>
              </Grid>
              <Grid item xs={12} className={classes.radioSelect}>
                <p className="body4" style={{display: 'inline', marginRight: '10px'}}>
                  <strong>Node</strong></p>
                <RadioGroup style={{flexDirection: "row"}} aria-label="radio group"
                            name="radio group"
                            defaultValue={chart['nodeIds'][0]}
                            onChange={handleRawDataRadioGroupOnChange}>
                  {chart['nodeIds'].map((nodeId) =>
                    <FormControlLabel key={nodeId} value={nodeId} control={<Radio/>} label={nodeId}/>)
                  }
                </RadioGroup>
                <Button color='tertiary' style={{marginLeft: '10px'}} onClick={handleRawDataCSVOnClick}>Export
                  to CSV</Button>
              </Grid>
              <div className={classes.widgetRow}/>
              <NodeTable rows={rawDataRows}/>
              <Grid>
                <h2 className={classes.h2}>
                  <span ref={refs.annotate} className={classes.span}>Annotation</span>
                </h2>
              </Grid>
              <Grid item xs={12} className={classes.radioSelect}>
                <Button color='tertiary' style={{marginLeft: '10px'}} onClick={handleAnnotationCSVOnClick}>Export to
                  CSV</Button>
              </Grid>
              <div className={classes.widgetRow}/>
              <AnnotationTable rows={annotationRows}/>
              <Grid>
                <h2 className={classes.h2}>
                  <span ref={refs.editHistory} className={classes.span}>Edit History</span>
                </h2>
              </Grid>
              <EditHistory chart={chart}/>
            </Grid>
            {/*<Grid item xs={2} style={{ position: "fixed", top: "30", right: "0", width: "100%" }}>*/}
            {/*  <NavGraph variant="scrollable" refs={refs}/>*/}
            {/*</Grid>*/}
          </Grid>
        </Container>
      </main>
    </div>
  );
}
