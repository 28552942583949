import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormLabel-root': {
      fontFamily: ['Nunito','sans-serif'],
      fontWeight: 400,
    },
  }
}));

export default function BasicTextFields(props) {
  const classes = useStyles();
  const [name, setName] = React.useState();
  const handleChange = (event) => {
    setName(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField
        disabled = {props.disabled || false}
        id="outlined-primary"
        label="Email"
        value = {name}
        onChange = {handleChange}
        variant="outlined"
        fullWidth />
    </form>
  );
}


// const useStyles = makeStyles((theme) => ({
//   input: {
//     borderRadius: 6,
//     backgroundColor: theme.palette.common.white,
//     border: '1px solid #ced4da',
//     fontSize: 20,
//     fontWeight: 400,
//     lineHeight: '130%',
//     padding: '12px 34px',
//     transition: theme.transitions.create(['border-color', 'box-shadow']),
//     fontFamily: ['Nunito', 'sans-serif'],
//     '&:focus': {
//       boxShadow: `${fade('#DEF3DF', 0.25)} 0 0 0 0.2rem`,
//       borderColor: '#97D89A',
//     },
//   },
// }))(InputBase);
