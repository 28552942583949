import React from 'react';
import EventNoteIcon from '@material-ui/icons/EventNote';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { makeStyles } from '@material-ui/core/styles';
import '../index.css'
import Tooltip from "@material-ui/core/Tooltip";
import AttributeFilter from "./AttributeFilter";


const useStyles = makeStyles((theme) => ({
  caption: {
    color: '#878686',
    fontFamily: 'Nunito',
    fontSize: 14,
    fontStyle: 'italic',
    fontWeight: 400,
    margin: theme.spacing(1),
    textAlign: 'right',
  },
  disabledIcon: {
    color: '#ECECEC',
    margin: theme.spacing(1),
  },
  iconCircle: {
    color: '#74BB88',
    margin: theme.spacing(1, 3, 0, 0),
  },
  widgetColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  widgetLabel: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
  },
  widgetMargin: {
    marginRight: theme.spacing(2),
  },
  widgetRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(1),
  },
  header: {
    margin: 0,
    padding: 0,
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  content: {
    overflow: 'auto',
    height: '85%',
  }
}));

const allAttributes = {
  meanHeatRatio: "Mean Heat Ratio",
  meanOutsideTemp: "Mean Outside Temperature",
  meanSoilMoisture: "Mean Soil Moisture"
}

export default function LastHeard(props) {
  const classes = useStyles();
  const [filter, setFilter] = React.useState('all');

  let attributes = {};
  if (filter === 'all') {
    attributes = allAttributes;
  } else {
    attributes = {[filter]: allAttributes[filter]};
  }

  return (
    <React.Fragment>
      <div className={classes.header}>
        <Tooltip title="Last heard values of attributes from individual nodes." placement="top-start">
          <div className={classes.widgetLabel}>
            <EventNoteIcon
              color="disabled"
              fontSize="large"
              className={classes.widgetMargin}
            />
            <h2>Last Heard</h2>
          </div>
        </Tooltip>
        <AttributeFilter value={filter} onChange={(e) => setFilter(e.target.value)}/>
      </div>
      <div className={classes.content}>
        {props.nodes
          .sort((a, b) => {
            return new Date(b['lastHeardTimestamp']) - new Date(a['lastHeardTimestamp']);
          })
          .map( node =>
          Object.keys(attributes).map( attribute =>
            node.lastHeardValue ?
              node.lastHeardValue[attribute] !== -1 ?
              (
              <div key={node['nodeId'] + attribute} className={classes.widgetLabel}>
                <FiberManualRecordIcon className={classes.iconCircle} fontSize="small" />
                <div className={classes.widgetColumn}>
                  <div className="body3">{node.nodeId} | {node.nodeLocation} | {node.plantLocation} </div>
                  <div className="body3">{attributes[attribute]}: {Number(node.lastHeardValue[attribute]).toPrecision(5)}</div>
                  <div className={classes.caption}> {
                    new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'long' }).format(
                      Date.parse(node.lastHeardTimestamp)
                    )
                  }</div>
                </div>
              </div>
              ) : '' : ''
        ))}
      </div>
    </React.Fragment>
  );
}
