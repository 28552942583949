import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import '../index.css'


// Original design here: https://github.com/siriwatknp/mui-treasury/issues/539

const FavoriteFilter = (props) => {
  const [val,setVal] = useState(props.value);

  const handleChange = (event) => {
    setVal(event.target.value);
    props.onChange(event);
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      backgroundColor: '#97D89A',
      borderRadius: 5,
      flexDirection: 'row',
      margin: theme.spacing(0, 2),
      maxHeight: '35px',
      padding: theme.spacing(2),
      width: '170px',
    },
    select: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    }
  }));

  const classes = useStyles();

  // moves the menu below the select input
  const menuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    getContentAnchorEl: null
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        disableUnderline
        classes={{ root: classes.select }}
        MenuProps={menuProps}
        value={val}
        onChange={handleChange}
        disabled={props.disabled}
      >
        <MenuItem value="favorite">
          <p className="body3">By Favorites</p>
        </MenuItem>
        <MenuItem value="mostRecent">
          <p className="body3">By Most Recent</p>
        </MenuItem>
        <MenuItem value="leastRecent">
          <p className="body3">By Least Recent</p>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

FavoriteFilter.defaultProps = {
  value: 'favorite',
  onChange: () => {},
  disabled: false,
}

export default FavoriteFilter;
