import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import OrdinalFrame from 'semiotic/lib/OrdinalFrame';
import { attributeToString } from './GlobalFunctions'
import '../index.css';

const useStyles = makeStyles((theme) => ({
  relative: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(5),
    position: 'relative',
  },
}));

export default function BarGraph(props) {
  const classes = useStyles();

  useEffect( () => {
  }, [props.charts, props.rawData]);

  const frameProps = {
    /* --- Data --- */
    data: props.rawData,

    /* --- Size --- */
    size: [900, 700],

    /* --- Layout --- */
    type: "clusterbar",
    oPadding: 10,

    /* --- Process --- */
    oAccessor: "timestamp",
    rAccessor: props.chart['attribute'],
    rExtent: [props.chart['yMin'], props.chart['yMax']],

    /* -- Customize -- */
    style: d => {
      return { fill: props.chart['lines'][d.rIndex]['stroke'] }
    },
    axes: [{ orient: "left", label: attributeToString(props.chart['attribute'])},
      {
      orient: "bottom", ticks: 5
    }],

    /* --- Annotate --- */
    oLabel: false
  };

  return (
    <div className={classes.relative}>
      <OrdinalFrame {...frameProps} />
    </div>
  )
}
