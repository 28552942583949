import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTheme, makeStyles} from '@material-ui/core/styles';
import {LineChart, Label, Line, ResponsiveContainer, XAxis, YAxis} from 'recharts';
import {Star, StarBorder} from '@material-ui/icons';
import Checkbox from './Checkbox';
import {attributeToString} from "./GlobalFunctions"

const useStyles = makeStyles((theme) => ({
  widgetRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(1)
  },
  iconStar: {
    color: '#FFC700',
    marginLeft: theme.spacing(2),
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(1)
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    height: 150
  }
}));

export default function Chart(props) {
  const [chartData, setChartData] = useState([]);
  const theme = useTheme();
  const classes = useStyles();

  useEffect(() => {
    initChartData();
  }, [props.filteredCharts, props.charts, props.sapflows]);

  const initChartData = () => {
    setChartData([]);
    props.sapflows[props.chart['gid']].map((sapflow) => {
      setChartData(chartData =>
        [...chartData,
          {
            timestamp: sapflow['timestamp'],
            [sapflow['customerId#nodeId'].substring(2)]: props.chart['inverse'] && sapflow[props.chart['attribute']] !== 0 ? 1 / sapflow[props.chart['attribute']] : sapflow[props.chart['attribute']]
          }
        ]
      );
    });
  };

  const handleFavoriteOnChange = () => {
    props.chart['favorite'] = !props.chart['favorite'];
    props.fetchEditPersonalization(JSON.stringify([...props.charts]));
    props.setCharts([...props.charts]);
  };

  const handleSelectOnChange = (e) => {
    if (e.target.checked) {
      props.setSelectedCharts([...props.selectedCharts, props.chart['gid']])
    } else {
      props.setSelectedCharts(props.selectedCharts.filter((gid) => gid !== props.chart['gid']))
    }
  };


  return (
    <React.Fragment>
      {chartData.length === 0 ?
        <div style={{width: 300, height: 184, padding: 50}}>
          <h3>No data could be retrieved from current date range</h3>
        </div> :
        <ResponsiveContainer>
          <LineChart
            data={chartData}
            margin={{
              top: 16,
              right: 16,
              bottom: 0,
              left: 24,
            }}
          >
            <XAxis
              dataKey="timestamp"
              scale="time"
              tickFormatter={(unixTime) => {
                let date = new Date(unixTime * 1000);
                let hours = date.getHours();
                let minutes = "0" + date.getMinutes();
                return hours + ':' + minutes.substr(-2);
              }}
              stroke={theme.palette.text.secondary}/>
            <YAxis stroke={theme.palette.text.secondary}
              //domain={[props.chart['yMin'] ? props.chart['yMin'] : 'auto', props.chart['yMax']? props.chart['yMax'] : 'auto']}
                   allowDataOverflow={true}>
              <Label
                angle={270}
                position="left"
                style={{textAnchor: 'middle', fill: theme.palette.text.primary}}
              >
                {attributeToString(props.chart['attribute'])}
              </Label>
            </YAxis>
            {props.chart['lines'].map((line) =>
              <Line key={line['name']} type="monotone" dataKey={line['name']} stroke={line['stroke']}
                    dot={false}/>
            )
            }
          </LineChart>
        </ResponsiveContainer>
      }
      <div className={classes.widgetRow}>
        {props.selectedCharts ?
          <Checkbox name={props.chart['gid']} checked={props.selectedCharts.includes(props.chart['gid'])}
                    onChange={handleSelectOnChange}/> : null}
        <Link to={{
          pathname: '/rosestemmhr',
          state: {chart: props.chart}
        }} style={{color: '#000'}}>
          <div className={classes.title}>
            <p className="body3">{props.chart.graphTitle}</p>
          </div>
          <div className={classes.widgetRow}>
            <p
              className="body4">{props.chart['nodeIds'].map((nodeId) => (nodeId + ' '))} | {attributeToString(props.chart['attribute'])}</p>
          </div>
        </Link>
        {props.chart['favorite'] ? <Star className={classes.iconStar} onClick={handleFavoriteOnChange}/> :
          <StarBorder className={classes.iconStar} onClick={handleFavoriteOnChange}/>}
      </div>
    </React.Fragment>
  );
}
