import React from 'react';
import {Link} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import {AuthState} from "@aws-amplify/ui-components";
import {IconButton, Snackbar} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Button from './components/Button';
import Input from './components/Input';
import Password from './components/Password';
import Verification from "./components/Verification";
import './Login.css';

/**
 * Initializes and maintains the login page for the app.
 * @param setUser - set user function
 * @param authState - authorization state
 * @param setAuthState - set authorization state
 */
function Login({setUser, authState, setAuthState, setApiKey}) {
  // Set initial form state
  const [form, setForm] = React.useState({
    email: '',
    password: '',
    confirmPassword: '',
    verificationCode: '',
  });

  const [notification, setNotification] = React.useState({
    open: false,
    message: undefined,
  });

  /**
   * Changes form state and resets form
   * @param authState - authorization state
   */
  function changeFormState(authState) {
    setAuthState(authState);
    setForm({
      email: '',
      password: '',
      confirmPassword: '',
      verificationCode: '',
    });
    setNotification({open: false, message: undefined});
  }

  /**
   * Signs in the user with given email and password.
   */
  async function signIn() {
    try {
      const user = await Auth.signIn(form.email, form.password);
      setUser(user);
      setAuthState(AuthState.SignedIn);
      const apiKey = fetch('https://api.plantbeats.io/config', {
        method: 'GET',
        headers: {
          'Authorization': user['signInUserSession']['idToken']['jwtToken']
        }
      })
        .then(r => r.json())
        .then(data => {
          setApiKey(data['PB_API_KEY']);
          return data['PB_API_KEY']
        }).catch(console.log);
    } catch (err) {
      console.log({err});
      setNotification({open: true, message: err.message});
    }
  }

  /**
   * Forgot password on click.
   */
  async function forgotPassword() {
    try {
      await Auth.forgotPassword(form.email);
      setAuthState(AuthState.ResetPassword);
    } catch (err) {
      console.log({err});
      setNotification({open: true, message: err.message});
    }
  }

  /***
   * Reset password on click.
   */
  async function resetPassword() {
    try {
      await Auth.forgotPasswordSubmit(form.email, form.verificationCode, form.password);
      changeFormState(AuthState.SignIn);
    } catch (err) {
      console.log({err});
      setNotification({open: true, message: err.message});
    }
  }

  /**
   * Handles the notification on close
   */
  const handleClose = () => {
    setNotification({...notification, open: false});
  };

  /**
   * Handles the submit of the login form.
   * @param event - event
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    if (authState === AuthState.SignIn) {
      signIn();
    } else if (authState === AuthState.ForgotPassword) {
      forgotPassword();
    } else if (authState === AuthState) {
      resetPassword();
    }
  };

  if (authState === AuthState.SignIn) {
    return (
      <div className="Login">
        <header className="Login-header">
          <Link to="/home">
            <img src={require('./img/largeLogo.png')} alt="logo"/>
          </Link>
        </header>
        <div className="Login-toggle">
          <h1>Log in</h1>
          <Link to="/signup" onClick={() => {
            changeFormState(AuthState.SignUp);
          }} className="link-faded"><h1>Sign up</h1></Link>
        </div>
        <form onSubmit={handleSubmit} className="Login-form" noValidate autoComplete="off">
          <Input onChange={(e) => setForm({...form, email: e.target.value})}/>
          <Password onChange={(e) => setForm({...form, password: e.target.value})}/>
        </form>
        <div className="Login-confirmation">
          <Link ><Button onClick={signIn}>Log in</Button></Link>
          <Link onClick={() => changeFormState(AuthState.ForgotPassword)} className="body2 link-underline">Forgot
            password?</Link>
        </div>
        <Snackbar
          {...notification}
          action={
            <React.Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon/>
              </IconButton>
            </React.Fragment>
          }
        />
        <div className="Login-3rd-party">
          <Link>
            <img src={require('./img/Google.png')} alt="Signup with Google" onClick={() =>
              Auth.federatedSignIn({provider: "Google"})
            }/>
          </Link>
          <Link>
            <img src={require('./img/Microsoft.png')} alt="Signup with Microsoft"
                 onClick={() => Auth.federatedSignIn()}/>
          </Link>
        </div>
      </div>
    );
  }

  // Display forgot password
  if (authState === AuthState.ForgotPassword) {
    return (
      <div className="Login">
        <header className="Login-header">
          <img src={require('./img/largeLogo.png')} alt="logo"/>
        </header>
        <div className="Login-toggle">
          <h1>Log in</h1>
          <Link to="/signup" onClick={() => {
            changeFormState(AuthState.SignUp);
          }} className="link-faded"><h1>Sign up</h1></Link>
        </div>
        <form onSubmit={handleSubmit} className="Login-form" noValidate autoComplete="off">
          <Input onChange={(e) => setForm({...form, email: e.target.value})}/>
        </form>
        <div className="Login-confirmation">
          <Link><Button onClick={forgotPassword}> Send Code </Button></Link>
          <Link onClick={() => changeFormState(AuthState.SignIn)} className="body2 link-underline">Return to sign
            in</Link>
        </div>
        <Snackbar
          {...notification}
          action={
            <React.Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon/>
              </IconButton>
            </React.Fragment>
          }
        />
      </div>
    );
  }

  // Display password reset
  if (authState === AuthState.ResetPassword) {
    return (
      <div className="Login">
        <header className="Login-header">
          <img src={require('./img/largeLogo.png')} alt="logo"/>
        </header>
        <div className="Login-toggle">
          <h1>Log in</h1>
          <Link to="/signup" onClick={() => {
            changeFormState(AuthState.SignUp);
          }} className="link-faded"><h1>Sign up</h1></Link>
        </div>
        <form onSubmit={handleSubmit} className="Login-form" noValidate autoComplete="off">
          <Password onChange={(e) => setForm({...form, password: e.target.value})}/>
          <Verification onChange={(e) => setForm({...form, verificationCode: e.target.value})}/>
        </form>
        <div className="Login-confirmation">
          <Link><Button onClick={resetPassword}> Reset Password </Button></Link>
          <Link onClick={() => changeFormState(AuthState.SignIn)} className="body2 link-underline">Return to sign
            in</Link>
        </div>
        <Snackbar
          {...notification}
          action={
            <React.Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon/>
              </IconButton>
            </React.Fragment>
          }
        />
      </div>
    );
  }

  // Invalid signup auth state. Reset authState and rerender.
  setAuthState(AuthState.SignIn);
  return (
    <div> error </div>
  );
}

export default Login;
