import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, darken} from "@material-ui/core/styles";

const useStyles = makeStyles({
  button: {
    backgroundColor: ({color}) => COLORS[color],
    "&:hover": {
      backgroundColor: ({color}) =>
        HOVER[color] ? HOVER[color] : darken(COLORS[color], 0.1)
    },
  }
});

const COLORS = {
  primary: '#97D89A',
  secondary: '#97D89A',
  tertiary: '#BABABA',
  ViewAll: '#61A487'
}

const HOVER = {
  primary: '#74BB88',
  secondary: '#74BB88',
}

const Button = ({ color = 'primary', style = {}, children, onClick = null, disabled = false}) => {
  const classes = useStyles({color});

  let buttonStyle = {
    borderRadius: '6.69px',
    border: 'none',
    cursor: 'pointer',
    fontFamily: 'Nunito',
    fontSize: '24px',
    fontWeight: '500',
    lineHeight: '130%',
    margin: '0',
    // opacity: '0.5'
  };

  switch (color) {
    case 'primary':
      buttonStyle = {
        ...buttonStyle,
        width: '340px',
        height: '60px',
        fontSize: '24px'
      };
      break;
    case 'secondary':
      buttonStyle = {
        ...buttonStyle,
        width: '240px',
        height: '50px',
        fontSize: '24px'
      };
      break;
    case 'tertiary':
      buttonStyle = {
        ...buttonStyle,
        width: '180px',
        height: '35px',
        fontSize: '14px',
        color: '#000000',
      };
      break;
     case 'ViewAll':
        buttonStyle = {
          ...buttonStyle,
          width: '160px',
          height: '35px',
          fontSize: '18px',
          color: '#DEF3DF',
        };
        break;
    default:
      break;
  }

  return (
    <button className={classes.button} style={{ ...buttonStyle, ...style }} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  )
};

Button.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.string,
  onClick: PropTypes.func
};

export default Button;
