import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Button from './Button';
import ButtonM from '@material-ui/core/Button';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from "@material-ui/icons/StarBorder";
import DeleteIcon from '@material-ui/icons/Delete';
import PopupImage from './PopupImage'
import {UserContext} from '../App'
import '../index.css'

const statuses = [
  {
    value: 'Active',
    label: 'Active',
  },
  {
    value: 'Inactive',
    label: 'Inactive',
  },
];

const nodeLocation = [
  {
    value: 'Stem',
    label: 'Stem',
  },
  {
    value: 'Root',
    label: 'Root',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#FFF',
    '& > *': {
      margin: theme.spacing(1),
      width: '48ch',
    },
  },
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
  },
  containerSmall: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  widgetLabel: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(3),
  },
  buttonRow: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  favoriteButton: {
    border: '1px solid #FFC700',
    borderRadius: 5,
    height: 35,
    padding: theme.spacing(2),
    marginRight: '20px',
  },
  deleteButton: {
    border: '1px solid #373737',
    borderRadius: 5,
    height: 35,
    padding: theme.spacing(2),
    marginLeft: '20px',
  },
}));

export default function NodeFields(props) {
  const classes = useStyles();

  const [saved, setSaved] = React.useState(false);
  const {user, apiKey} = React.useContext(UserContext);

  const calculateSleepTime = () => {
    let day = Math.floor((Date.now() - new Date(props.node.lastHeardTimestamp))/(1000 * 60 * 60 * 24));
    let hour = Math.floor((Date.now() - new Date(props.node.lastHeardTimestamp))/(1000 * 60 * 60)) % 24;
    let min = Math.floor((Date.now() - new Date(props.node.lastHeardTimestamp))/(1000 * 60)) % 60;
    return day + "d " + hour + "h " + min + "m"
  }
  console.log(props.node.longitude);
  const [values, setValues] = React.useState({
    name: props.node.nodeName,
    nodeLocation: props.node.nodeLocation,
    plantLocation: props.node.plantLocation,
    heatPulseDuration: props.node.lastReceivedShadow ? props.node.lastReceivedShadow.heatPulseDuration : '',
    registrationTime: "Tuesday, Aug. 11, 2020",
    status: (Date.now() - new Date(props.node.lastHeardTimestamp)) < 1000 * 60 * 60 * 24 * 3 ? "Active" : "Inactive",
    plantType: props.node.plant,
    sleepInterval: calculateSleepTime(),
    latitude: props.node.latitude,
    longitude: props.node.longitude,
  });

  const handleChange = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
  };

  async function updateNodes() {
    fetch('https://api.plantbeats.io/nodes?' + new URLSearchParams({
      nodeID: props.node.nodeId,
      nodeName: values.name,
      nodeLocation: values.nodeLocation,
      plant: values.plantType,
      plantLocation: values.plantLocation,
      latitude: values.latitude,
      longitude: values.longitude,
    }),
      {
        method: 'POST',
        headers: {
          'Authorization' : user['signInUserSession']['idToken']['jwtToken'],
          'x-api-key' : apiKey
        }
      }).then(res => {
        if (res.ok) {
          setSaved(true);
        }
    }).catch(console.log);
  }

  const onClickUpdate = () => {
    updateNodes();
    props.setNodes(props.nodes.map(
      node => node.nodeId === props.node.nodeId ?
        {...node,
          nodeName: values.name,
          nodeLocation: values.nodeLocation,
          plant: values.plantType,
          plantLocation: values.plantLocation,
          latitude: values.latitude,
          longitude: values.longitude,
        } : node
    ));
  }

  const onClickFavorite = () => {
    if (props.favorites.includes(props.node)) {
      props.setFavorites(props.favorites.filter(node => node !== props.node));
    } else {
      props.setFavorites([...props.favorites, props.node]);
    }
  }

  const onClickDelete = () => {
    console.log("Delete this node");
  }

  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              id="name"
              name="name"
              label="Node Name"
              value={values.name}
              onChange={handleChange}
              variant="outlined" />
          </form>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              id="nodeLocation"
              name="nodeLocation"
              select
              label="Node Location"
              value={values.nodeLocation}
              onChange={handleChange}
              variant="outlined" >
              {nodeLocation.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </form>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              id="plantLocation"
              name="plantLocation"
              label="Plant Location"
              value={values.plantLocation}
              onChange={handleChange}
              variant="outlined" />
          </form>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              id="plantType"
              name="plantType"
              label="Plant Type"
              value={values.plantType}
              onChange={handleChange}
              variant="outlined" />
          </form>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              type="number"
              id="latitude"
              name="latitude"
              label="Latitude"
              value={values.latitude}
              onChange={handleChange}
              variant="outlined" />
          </form>
        </Grid>
        <Grid item xs={4}>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              disabled
              id="registrationTime"
              name="registrationTime"
              label="Registration Time"
              value={values.registrationTime}
              onChange={handleChange}
              variant="outlined" />
          </form>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              disabled
              id="status"
              name="status"
              select
              label="Active"
              value={values.status}
              onChange={handleChange}
              variant="outlined">
              {statuses.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </form>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              disabled
              id="heatPulseDuration"
              name="heatPulseDuration"
              label="Heat Pulse Duration"
              value={values.heatPulseDuration}
              onChange={handleChange}
              variant="outlined" />
          </form>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              disabled
              id="sleepInterval"
              name="sleepInterval"
              label="Sleep Interval"
              value={values.sleepInterval}
              onChange={handleChange}
              variant="outlined" />
          </form>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              type="number"
              id="longitude"
              name="longitude"
              label="Longitude"
              value={values.longitude}
              onChange={handleChange}
              variant="outlined" />
          </form>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.buttonRow}>
            <ButtonM
              className={classes.favoriteButton}
              elevation={0}
              startIcon={ props.favorites.includes(props.node)
                ? <StarIcon htmlColor="#FFC700" fontSize="small" />
                : <StarBorderIcon htmlColor="#FFC700" fontSize="small"/>
              }
              onClick={onClickFavorite}
            >
              <p className="body3">Favorite</p>
            </ButtonM>
            <ButtonM
              disabled
              className={classes.deleteButton}
              elevation={0}
              startIcon={<DeleteIcon htmlColor="#878686" fontSize="small" />}
              onClick={onClickDelete}
            >
              <p className="body3">Delete</p>
            </ButtonM>
          </div>
          <Container maxWidth="lg" className={classes.containerSmall}>
            <></>
          </Container>
          <PopupImage />
        </Grid>
      </Grid>
      <div className={classes.buttonRow}>
        <Button color='secondary' style={{ marginTop: '40px' }} onClick={onClickUpdate}>Update</Button>
        <p style={{
          position: "absolute",
          top: "50%",
          paddingLeft: 400,
          display: saved ? '': 'none'
        }}>
          ✔ Changes Saved
        </p>
      </div>
    </React.Fragment>
  );
}
