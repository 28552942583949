import React from 'react';
import {useLocation} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {Container, CssBaseline, Grid} from '@material-ui/core';
import NavPrimary from './components/NavPrimary';
import NavSetting from './components/NavSetting';
import './index.css'


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#FFF',
  },
  appBar: {
    color: '#000',
    backgroundColor: '#FFF',
    flexDirection: 'row',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: 'auto',
  },
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
  },
  containerSmall: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  toggle: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginRight: '10vw',
    border: '1pt solid #61A487',
    borderRadius: 5,
    marginBottom: '5pt',
    padding: '15pt',
    boxSizing: 'border-box',
  },
  standard: {
    display: 'inline-block',
    marginTop: theme.spacing(0.5),
  },
  standardText: {
    color: '#000000',
    fontFamily: 'Nunito',
    fontSize: 16,
    fontWeight: 500,
    marginTop: theme.spacing(1),
  },
  tertiaryButton: {
    backgroundColor: '#61A487',
    borderRadius: 5,
    color: '#DEF3DF',
    height: 40,
    padding: theme.spacing(2),
  },
  iconCircleMain: {
    color: '#61A487',
    margin: theme.spacing(0, 3, 0, 0),
  },
  widgetLabel: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(3),
  },
  inputDisplay: {
    width: '25ch',
  },
  formControl: {
    backgroundColor: '#ECECEC',
    borderRadius: 5,
    flexDirection: 'row',
    margin: theme.spacing(0, 2),
    maxHeight: '35px',
    padding: theme.spacing(2),
    width: '150px',
  },
  select: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default function Settings(props) {
  const classes = useStyles();

  const location = useLocation();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <NavPrimary />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <h2 style={{ paddingLeft: 40 }}>Settings</h2>
              <NavSetting nodes={props.nodes} value={location.state} stripe={props.stripe} setStripe={props.setStripe}/>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
