import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import FavoriteFilter from './FavoriteFilter';
import AddGraph from '../AddGraph';
import '../index.css'
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    breadcrumbs: {
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(2),
    },
    formControl: {
        backgroundColor: '#97D89A',
        borderRadius: 5,
        padding: theme.spacing(1),
    },
    grow: {
        flexGrow: 1,
    },
    pageNavigation: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        width: '100%',
    },
    inputInput: {
        color: '#878686',
        display: 'flex',
        fontFamily: 'Nunito',
        fontSize: 14,
        fontWeight: 400,
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 0,
            '&:focus': {
                border: '1px solid #878686',
                borderRadius: 17.5,
                width: '20ch',
            },
        },
    },
    search: {
        alignItems: 'center',
        display: 'flex',
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    widgetPadding: {
        marginRight: theme.spacing(2),
    },
    widgetRow: {
        display: 'flex',
        flexDirection: 'row',
        margin: theme.spacing(1),
    },
}));

export default function Search(props) {
    const classes = useStyles();

    const [favoriteFilterValue, setFavoriteFilterValue] = useState("favorite");

    const sortCharts = (value) => {
        switch (value) {
            case "favorite" :
                props.setFilteredCharts([...props.filteredCharts].sort((a, b) => {
                    if (a['favorite']) {
                        return -1;
                    }
                    return 1;
                }));
                break;
            case "mostRecent" :
                props.setFilteredCharts([...props.filteredCharts].sort((a, b) => {
                    if (a['timestamp'] < b['timestamp']) {
                        return 1;
                    } else if (a['timestamp'] > b['timestamp']) {
                        return -1;
                    }
                    return 0;
                }));
                break;
            case "leastRecent" :
                props.setFilteredCharts([...props.filteredCharts].sort((a, b) => {
                    if (a['timestamp'] < b['timestamp']) {
                        return -1;
                    } else if (a['timestamp'] > b['timestamp']) {
                        return 1;
                    }
                    return 0;
                }));
                break;
            case "graphAZ" :
                props.setFilteredCharts([...props.filteredCharts].sort((a, b) => {
                    if (a['graphTitle'] < b['graphTitle']) {
                        return -1;
                    } else if (a['graphTitle'] > b['graphTitle']) {
                        return 1;
                    }
                    return 0;
                }));
                break;
            case "graphZA" :
                props.setFilteredCharts([...props.filteredCharts].sort((a, b) => {
                    if (a['graphTitle'] < b['graphTitle']) {
                        return 1;
                    } else if (a['graphTitle'] > b['graphTitle']) {
                        return -1;
                    }
                    return 0;
                }));
                break;
            default:
                break;
        }
    };

    const favoriteFilterOnChange = (event) => {
        setFavoriteFilterValue(event.target.value);
        sortCharts(event.target.value);
    };

    const handleSearchOnChange = (e) => {
        props.setFilteredCharts(props.charts.filter(chart => chart['graphTitle'].toLowerCase().includes(e.target.value.toLowerCase())), () => sortCharts(favoriteFilterValue));
    };

    return (
        <React.Fragment>
            <div className={classes.pageNavigation}>
                {/* Breadcrumbs */}
                <div className={classes.breadcrumbs}>
                    <AddGraph
                        nodes={props.nodes}
                        charts={props.charts}
                        setCharts={props.setCharts}
                        sapflows={props.sapflows}
                        setSapflows={props.setSapflows}
                        fetchSapflows={props.fetchSapflows}
                        fetchEditPersonalization={props.fetchEditPersonalization}
                    />
                    <div className={classes.widgetPadding}/>
                    <Link to={"/graphs"} style={{color: 'black'}}><h2>All Graphs </h2></Link>
                    {props.graphTitle !== undefined ? <h2>{`\t/${props.graphTitle}`}</h2> : null}
                </div>
                <div className={classes.grow}/>
                {/* Search bar */}
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon color="disabled"/>
                    </div>
                    <InputBase
                        placeholder="Search by keywords"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        onChange={handleSearchOnChange}
                        inputProps={{'aria-label': 'search'}}
                        disabled={props.graphTitle !== undefined}
                    />
                    {/* Filters */}
                    <FavoriteFilter onChange={favoriteFilterOnChange} disabled={props.graphTitle !== undefined} />
                    {/*<NodeFilter/>*/}
                </div>
            </div>
        </React.Fragment>
    );
}
