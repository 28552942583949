import React, {useEffect} from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { makeStyles } from '@material-ui/core/styles';
import '../index.css'
import {parseDate} from "./GlobalFunctions";

const useStyles = makeStyles((theme) => ({
  standard: {
    color: '#454545',
    fontFamily: 'Nunito',
    fontSize: 16,
    fontWeight: 500,
    margin: theme.spacing(1),
  },
  iconCircle: {
    color: '#999999',
    margin: theme.spacing(1, 3, 0, 0),
  },
  widgetColumn: {
    display: 'flex',
    marginRight: '10pt',
  },
  widgetLabel: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

export default function EditHistory(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      {props.chart['editHistory'].map((edit) => (
          <div className={classes.widgetLabel} key={edit}>
            <FiberManualRecordIcon className={classes.iconCircle} fontSize="small" />
            <div className={classes.widgetColumn}>
              <p className={classes.standard}>{edit} </p>
            </div>
          </div>
      ))}
    </React.Fragment>
  );
}
