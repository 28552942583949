import React, { useEffect, useState, useReducer } from 'react';
import Dashboard from './Dashboard'
import Login from './Login'
import Signup from './Signup'
import { Route, Switch, Redirect } from 'react-router-dom';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { AuthState } from '@aws-amplify/ui-components';
import awsconfig from './aws-exports';
import Graphs from './Graphs';
import Nodes from './Nodes';
import NodeExample from "./NodeExample";
import RoseStemMHR from "./RoseStemMHR";
import Home from './Home';
import Privacy from "./Privacy";
import Terms from "./Terms";
import Footer from "./components/Footer";
import Settings from "./Settings";
import { oneWeekUnixTime, parseDate } from "./components/GlobalFunctions";

Amplify.configure({
  ...awsconfig,
  graphql_headers: async () => {
    const currentSession = await Auth.currentSession();
    return { Authorization: currentSession.getIdToken().getJwtToken() };
  },
  Auth: {
    identityPoolId: 'us-west-2:4908bdda-ea1d-4741-8c1d-c07ac5d414df',
    region: 'us-west-2',
    userPoolId: 'us-west-2_EPspRf8Kq',
    userPoolWebClientId: '445e855797oiuk774r7ee4lgk9',
    oauth: {
      domain: 'sapflow.auth.us-west-2.amazoncognito.com',
      scope: ["phone", "email", "openid", "profile", "aws.cognito.signin.user.admin"],
      redirectSignIn: 'https://www.plantbeats.io/oauth2/idpresponse',
      redirectSignOut: 'https://www.plantbeats.io/',
      responseType: 'code'
    }
  },
});

export const UserContext = React.createContext(null);

/**
 * Handles the loading state of the app.
 * @param loading - # of loading processes
 * @param action - action of current process
 * @returns {int} - updated # of loading processes
 */
function loadingReducer(loading, action) {
  switch (action.type) {
    case 'LOADING':
      return loading + 1;
    case 'SUCCESS':
      return loading - 1;
    default:
      return loading;
  }
}

const contentStyle = { minHeight: "100%", paddingBottom: 165, marginBottom: -165 };

/**
 * Initializes the authorization, nodes, graphs, sapflows, routing, and homepage interface of the app.
 */
function App() {
  const [authState, setAuthState] = useState('');
  const [user, setUser] = useState();
  const [nodes, setNodes] = useState([]);
  const [nodeFavorites, setNodeFavorites] = useState([]);
  const [charts, setCharts] = useState([]);
  const [sapflows, setSapflows] = useState({});
  const [loading, dispatch] = useReducer(loadingReducer, 0);
  const [metrics, setMetrics] = useState([]);
  const [annotations, setAnnotations] = useState([]);
  const [stripe, setStripe] = useState({ customer: null, subscription: null });
  const [apiKey, setApiKey] = useState();

  /**
   * Checks authorization after render.
   */
  useEffect(() => {
    checkUser();
    setAuthListener();
    document.title = "PlantBeats";
  }, []);

  /**
   * Fetches nodes, personalization, metrics, and user stripe info when API key is retrieved
   */
  useEffect(() => {
    if (apiKey) {
      fetchNodes();
      fetchPersonalize();
      fetchMetrics();
      fetchStripe();
    }
  }, [apiKey]);

  /**
   * Checks if session is expired on each render. If session is expired, user is signed out.
   */
  useEffect(() => {
    if (authState === AuthState.SignedIn) {
      if (user.getSignInUserSession().getIdToken().getExpiration() * 1000 <= Date.now()) {
        Auth.signOut();
      }
    }
  });

  /**
   *  Listens for changed authorization. Updates user and authorization state if changed.
   */
  async function setAuthListener() {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case 'signUp':
          fetch('https://api.plantbeats.io/create-customer', {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': user['signInUserSession']['idToken']['jwtToken'],
              'x-api-key': apiKey
            },
            body: JSON.stringify({
              email: user.getSignInUserSession().getIdToken().payload.email,
              sub: user.attributes.sub
            })
          })
            .then(r => r.json())
            .then(data => {
              return data.customer;
            });
          break;
        case 'signIn':
          setUser(data);
          setAuthState(AuthState.SignedIn);
          break;
        case 'signOut':
          setAuthState(AuthState.SignIn);
          break;
        default:
          break;
      }
    });
  }

  /**
   * Checks user authorization.
   */
  async function checkUser() {
    dispatch({ type: 'LOADING' });
    try {
      const authUser = await Auth.currentAuthenticatedUser();
      setUser(authUser);
      setAuthState(AuthState.SignedIn);
      const apiKey = fetch('https://api.plantbeats.io/config', {
        method: 'GET',
        headers: {
          'Authorization': authUser['signInUserSession']['idToken']['jwtToken']
        }
      })
        .then(r => r.json())
        .then(data => {
          setApiKey(data['PB_API_KEY']);
          return data['PB_API_KEY']
        }).catch(console.log);
      dispatch({ type: 'SUCCESS' });
    } catch (err) {
      setUser(null);
      setAuthState(AuthState.SignIn);
      dispatch({ type: 'SUCCESS' });
    }
  }

  /**
   * Fetches stripe customer info and their active subscription based on the user's email
   */
  async function fetchStripe() {
    dispatch({ type: 'LOADING' });

    let customer, subscription;

    customer = await fetch("https://api.plantbeats.io/get-customer?" + new URLSearchParams({ email: user.attributes.email }), {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': user['signInUserSession']['idToken']['jwtToken'],
        'x-api-key': apiKey
      },
    })
      .then(res => res.json())
      .then(data => {
        if (!data.customer) {
          throw Error("User does not have an associated Stripe customer.");
        }
        return data.customer;
      }).catch(console.log);

    if (customer) {
      subscription = await fetch("https://api.plantbeats.io/get-subscription?" + new URLSearchParams({ customer: customer.id }), {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': user['signInUserSession']['idToken']['jwtToken'],
          'x-api-key': apiKey
        },
      })
        .then(res => res.json())
        .then(data => {
          return data.subscriptions.data.filter(sub => sub.status === 'active')[0];
        });
    }

    setStripe({ customer: customer, subscription: subscription });
    dispatch({ type: 'SUCCESS' });
  }

  /**
   * Fetches and initializes all metrics for nodes.
   */
  async function fetchMetrics() {
    dispatch({ type: 'LOADING' });
    fetch('https://api.plantbeats.io/metrics',
      {
        method: 'GET',
        mode: "cors",
        headers: {
          'Authorization': user['signInUserSession']['idToken']['jwtToken'],
          'x-api-key': apiKey
        }
      })
      .then(res => res.json())
      .then((data) => {
        setMetrics(data);
        dispatch({ type: 'SUCCESS' })
      })
      .catch(err => {
        console.log(err);
        dispatch({ type: 'SUCCESS' })
      });
  }

  /**
   * Fetches and initializes all nodes.
   */
  async function fetchNodes() {
    dispatch({ type: 'LOADING' });
    fetch('https://api.plantbeats.io/nodes',
      {
        method: 'GET',
        mode: "cors",
        headers: {
          'Authorization': user['signInUserSession']['idToken']['jwtToken'],
          'x-api-key': apiKey
        }
      })
      .then(res => res.json())
      .then((data) => {
        setNodes(data);
        dispatch({ type: 'SUCCESS' })
      })
      .catch(err => {
        console.log(err);
        dispatch({ type: 'SUCCESS' })
      });
  }

  /**
   * Fetches and initializes the user personalization for graphs.
   */
  async function fetchPersonalize() {
    dispatch({ type: 'LOADING' });
    fetch('https://api.plantbeats.io/personalize?username=' + user['username'],
      {
        method: 'GET',
        mode: "cors",
        headers: {
          'Authorization': user['signInUserSession']['idToken']['jwtToken'],
          'x-api-key': apiKey
        }
      })
      .then(res => res.json())
      .then((data) => {
        data.map((post) => {
          post['metadata'].map((chart) => {
            if (!sapflows[chart['gid']]) {
              sapflows[chart['gid']] = [];
            }
            chart['nodeIds'].map((nodeId) => {
              fetchSapflows(chart, nodeId);
            })
          });
          setSapflows({ ...sapflows });
          setCharts(post['metadata']);
        });
        dispatch({ type: 'SUCCESS' })
      })
      .catch(err => {
        console.log(err);
        dispatch({ type: 'SUCCESS' })
      });
  }

  /**
   * Posts an edit for the user personalization of graphs in the database.
   * @param body - current graphs state
   */
  async function fetchEditPersonalization(body) {
    fetch('https://api.plantbeats.io/personalize?username=' + user['username'],
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Authorization': user['signInUserSession']['idToken']['jwtToken'],
          'x-api-key': apiKey
        }
      })
      .then(() => setCharts([...body]))
      .catch(err => {
        console.log(err);
      });
  }

  /**
   * Fetches and initializes the sapflows for user charts.
   */
  async function fetchSapflows(chart, nodeId) {
    dispatch({ type: 'LOADING' });
    if (stripe.subscription || parseDate(chart['fromDate']).getTime() + oneWeekUnixTime >= parseDate(chart['toDate']).getTime()) {
      fetch('https://api.plantbeats.io/sapflows?nodeID=' + nodeId + '&fromDate=' + chart['fromDate'] + '&toDate=' + chart['toDate'],
        {
          method: 'GET',
          mode: "cors",
          headers: {
            'Authorization': user['signInUserSession']['idToken']['jwtToken'],
            'x-api-key': apiKey
          }
        })
        .then(res => res.json())
        .then((data) => {
          data.map((sapflow) => {
            sapflows[chart['gid']].push(sapflow);
          });
          setSapflows({ ...sapflows });
          dispatch({ type: 'SUCCESS' })
        })
        .catch(err => {
          console.log(err);
          dispatch({ type: 'SUCCESS' })
        });
    } else {
      dispatch({ type: 'SUCCESS' })
    }
  }

  /**
   * Posts an annotation to the database.
   * @param body - new annotation
   */
  async function fetchAddAnnotation(body) {
    fetch('https://api.plantbeats.io/annotations?username=brian.duong587@gmail.com',
      {
        method: 'GET',
        body: body,
        mode: "cors",
        headers: {
          'Authorization': user['signInUserSession']['idToken']['jwtToken'],
          'x-api-key': apiKey
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
  return authState === AuthState.SignedIn && user ?
    (
      <div className="App">
        <div style={contentStyle}>
          <UserContext.Provider value={{ user, apiKey }}>
            <Switch>
              <Route path="/graphs" render={() =>
                <Graphs
                  nodes={nodes}
                  charts={charts}
                  setCharts={setCharts}
                  sapflows={sapflows}
                  setSapflows={setSapflows}
                  loading={loading}
                  fetchSapflows={fetchSapflows}
                  fetchEditPersonalization={fetchEditPersonalization}
                />
              } />
              <Route path="/nodes" render={() =>
                <Nodes
                  nodes={nodes}
                  nodeFavorites={nodeFavorites}
                  setNodeFavorites={setNodeFavorites}
                  metrics={metrics}
                />
              } />
              <Route path="/nodeexample" render={() =>
                <NodeExample
                  nodes={nodes}
                  setNodes={setNodes}
                  charts={charts}
                  setCharts={setCharts}
                  user={user}
                  nodeFavorites={nodeFavorites}
                  setNodeFavorites={setNodeFavorites}
                  sapflows={sapflows}
                  fetchEditPersonalization={fetchEditPersonalization}
                />
              } />
              <Route path="/dashboard" render={() =>
                <Dashboard
                  nodes={nodes}
                  charts={charts}
                  setCharts={setCharts}
                  nodeFavorites={nodeFavorites}
                  setNodeFavorites={setNodeFavorites}
                  sapflows={sapflows}
                  loading={loading}
                  fetchEditPersonalization={fetchEditPersonalization}
                  metrics={metrics}
                />
              } />
              <Route path="/rosestemmhr" render={() =>
                <RoseStemMHR
                  nodes={nodes}
                  charts={charts}
                  setCharts={setCharts}
                  nodeFavorites={nodeFavorites}
                  setNodeFavorites={setNodeFavorites}
                  sapflows={sapflows}
                  setSapflows={setSapflows}
                  loading={loading}
                  fetchSapflows={fetchSapflows}
                  fetchEditPersonalization={fetchEditPersonalization}
                  metrics={metrics}
                  annotations={annotations}
                  setAnnotations={setAnnotations}
                  fetchAddAnnotation={fetchAddAnnotation}
                  stripe={stripe}
                />
              } />
              <Route path="/terms" component={Terms} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/settings" render={() => <Settings nodes={nodes} stripe={stripe} setStripe={setStripe} />} />
              <Route path="*"><Redirect to="/dashboard" /></Route>
            </Switch>
          </UserContext.Provider>
        </div>
        <Footer />
      </div>
    ) : (
      <div className="App">
        {loading === 0 &&
          <Switch>
            <Route
              path="/login"
              render={() =>
                <Login
                  setUser={setUser}
                  authState={authState}
                  setAuthState={setAuthState}
                  setApiKey={setApiKey}
                />
              }
            />
            <Route
              path="/signup"
              render={() =>
                <Signup
                  setUser={setUser}
                  authState={authState}
                  setAuthState={setAuthState}
                  setStripe={setStripe}
                  setApiKey={setApiKey}
                />
              }
            />
            <Route path="/home" component={Home} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/terms" component={Terms} />
            <Route path="*"><Redirect to="/home" /></Route>
          </Switch>
        }
      </div>
    );
}

export default App;