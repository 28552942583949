import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import '../index.css'
import {UserContext} from "../App";

const GreenSwitch = withStyles({
  switchBase: {
    color: green[300],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles((theme) => ({
  standard: {
    color: '#454545',
    fontFamily: 'Nunito',
    fontSize: 16,
    fontWeight: 500,
    margin: theme.spacing(1),
  },
  standardChange: {
    color: '#000000',
    fontFamily: 'Nunito',
    fontSize: 16,
    fontWeight: 500,
    margin: theme.spacing(1),
    textAlign: 'center',
    whiteSpace: "nowrap",
    overflow: 'hidden',
    textOverflow: "ellipsis"
  },
  iconCircle: {
    color: '#999999',
    margin: theme.spacing(1, 3, 0, 0),
  },
  widgetColumn: {
    display: 'flex',
    marginRight: '10pt',
  },
  widgetLabel: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

const fullText = {
  'meanHeatRatio': 'Mean Heat Ratio',
  'meanOutsideTemp': 'Mean Outside Temperature',
  'meanSoilMoisture': 'Mean Soil Moisture'
}

export default function SingleNotificationInfo(props) {
  const classes = useStyles();

  const {user, apiKey} = React.useContext(UserContext);

  const [state, setState] = React.useState({
    checked: props.alert.status === "Active",
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    snoozeAlert();
  };

  const getBound = () => {
    const ranges = JSON.parse(props.alert.ranges.replace(/'/g, '"').replace(/None/g, "null"));
    if (ranges[0].lowerLimit === ranges[0].upperLimit) {
      return " equal to " + ranges[0].lowerLimit;
    } else if (ranges[0].upperLimit) {
      return " below " + ranges[0].upperLimit;
    } else {
      return " above " + ranges[0].lowerLimit;
    }
  }

  async function snoozeAlert() {
    fetch('https://api.plantbeats.io/alerts?' + new URLSearchParams({
      alertid: props.alert.id,
      username: props.user.username,
      status: state.checked ? "Inactive" : "Active"
    }),
      {
        method: 'POST',
        headers: {
          'Authorization' : props.user['signInUserSession']['idToken']['jwtToken'],
          'x-api-key' : apiKey
        }
      })
      .catch(console.log);
  }

  return (
    <React.Fragment>
      <div className={classes.widgetLabel}>
        <FiberManualRecordIcon className={classes.iconCircle} fontSize="small" />
        <div className={classes.widgetColumn}>
          <p className={classes.standard}>Notify me when </p>
          <p className={classes.standardChange} style={{width: 150}}> {fullText[props.alert.variable]} </p>
          <p className={classes.standard}> of </p>
          <p className={classes.standardChange} style={{width: 200}}> {props.alert.nodeId} </p>
          <p className={classes.standard}> has a value </p>
          <p className={classes.standardChange} style={{width: 125}}>{getBound()}</p>
        </div>
        <FormControlLabel
          control={
          <GreenSwitch
              size="small"
              checked={state.checked}
              onChange={handleChange}
              name="checked"
              color="primary"
          />
          }
         />
      </div>

    </React.Fragment>
  );
}
