import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import largeLogo from './img/largeLogo.png';
import img1 from './img/01.png'
import img2 from './img/02.png'
import img3 from './img/03.png'
import Footer from "./components/Footer";

const useStyles = makeStyles((theme) => ({
  navbar: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '.5rem 1rem',
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 1030,
  },
  container: {
    width: '100%',
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '75rem',
  },
  signin: {
    width: 'auto',
    listStyle: 'none',
    display: 'flex',
  },
  navItem: {
    padding: '1rem',
    fontSize: 'medium',
  },
  navLink: {
    color: '#007bff',
    fontWeight: 400,
  },
  navbarBrand: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '.3125rem',
    paddingBottom: '.3125rem',
    marginRight: '1rem',
    fontSize: '1.25rem',
    lineHeight: 'inherit',
    whiteSpace: 'nowrap',
  },
  headerHeading: {
    fontSize: 40,
    marginBottom: 0,
  },
  headerSubheading: {
    fontSize: 32,
    marginBottom: '1rem',
  },
  header: {
    textAlign: 'center',
    color: 'rgba(0,0,0,.5)',
  },
  section: {
    width: '75rem',
    margin: 'auto',
  },
  sectionContent: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: '-15px',
    marginLeft: '-15px',
    alignItems: 'center',
  },
  p5: {
    padding: '3rem',
  },
  colLg6: {
    flex: '0 0 50%',
    maxWidth: '50%',
  },
  order1: {
    order: 1,
  },
  order2: {
    order: 2,
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '50%',
  },
  display4: {
    fontSize: '3.5rem',
    fontWeight: 300,
    lineHeight: 1.2,
  },
  desc: {
    fontSize: '1rem',
    fontWeight: 400,
  },
  font: {
    fontFamily: `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
  },
  footer: {
    left: 0,
    right: 0,
    position: 'absolute',
    background: 'black',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  copyright: {
    margin: 0,
    textAlign: 'center',
    color: 'white',
    fontSize: 'small',
    fontWeight: 400,
    lineHeight: 1.5,
    paddingBottom: 5,
    '& a': {
      color: 'white'
    }
  },
  footerLinks: {
    '& a': {
      marginLeft: 30,
      marginRight: 30,
    }
  }
}));

export default function Home() {
  const classes = useStyles();
  return (
    <>
      <nav className={classes.navbar}>
        <div className={`${classes.container} ${classes.nav}`}>
          <a className={classes.navbarBrand} href="#">
            <img
              src={largeLogo}
              width="240"
              title="PlantBeats"
              alt="PlantBeats"
              style={{paddingBottom: 12, paddingLeft: 40}}
            />
          </a>
          <div className={classes.navbarNav} id="navbarResponsive">
            <ul className={classes.signin}>
              <li className={classes.navItem}>
                <a
                  className={`${classes.navLink} ${classes.font}`}
                  href="/signup"
                >
                  Sign Up
                </a>
              </li>
              <li className={classes.navItem}>
                <a
                  className={`${classes.navLink} ${classes.font}`}
                  href="/login"
                >
                  Log In
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <header className={classes.header}>
        <div className="masthead-content">
          <div className={classes.container}>
            <h1 className={`${classes.headerHeading} ${classes.font}`}>
              Intuitive.
            </h1>
            <h2 className={`${classes.headerSubheading} ${classes.font}`}>
              Get insights into your plants.
            </h2>
          </div>
        </div>
      </header>

      <section className={classes.section}>
        <div className={classes.container}>
          <div className={classes.sectionContent}>
            <div className={`${classes.colLg6} ${classes.order2}`}>
              <div className={classes.p5}>
                <img
                  className={classes.image}
                  src={img1}
                  alt=""
                />
              </div>
            </div>
            <div className={`${classes.colLg6} ${classes.order1}`}>
              <div className={classes.p5}>
                <h2 className={`${classes.display4} ${classes.font}`}>
                  Plant sensing
                </h2>
                <p className={`${classes.desc} ${classes.font}`}>
                  {' '}
                  Open-source, accurate, robust, low-cost, and low-power system
                  to get plant water usage, and environmental attributes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={classes.section}>
        <div className={classes.container}>
          <div className={classes.sectionContent}>
            <div className={classes.colLg6}>
              <div className={classes.p5}>
                <img
                  className={classes.image}
                  src={img2}
                  alt=""
                />
              </div>
            </div>
            <div className={classes.colLg6}>
              <div className={classes.p5}>
                <h2 className={`${classes.display4} ${classes.font}`}>
                  Cloud and Local
                </h2>
                <p className={`${classes.desc} ${classes.font}`}>
                  {' '}
                  Support indoor, and outdoor deployment. Our networks support
                  WiFi, LTE, and LoRA (in-works). Support farm-based and
                  cloud-enabled deployment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={classes.section}>
        <div className={classes.container}>
          <div className={classes.sectionContent}>
            <div className={`${classes.colLg6} ${classes.order2}`}>
              <div className={classes.p5}>
                <img
                  className={classes.image}
                  src={img3}
                  alt=""
                />
              </div>
            </div>
            <div className={`${classes.colLg6} ${classes.order1}`}>
              <div className={classes.p5}>
                <h2 className={`${classes.display4} ${classes.font}`}>
                  End-to-End Integrated System
                </h2>
                <p className={`${classes.desc} ${classes.font}`}>
                  Multi-platform to view and assess your individual plants
                  health, and key insights into your farms.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer/>
    </>
  );
}
