import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  font: {
    fontFamily: `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
  },
  footer: {
    left: 0,
    right: 0,
    position: 'absolute',
    background: 'black',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  copyright: {
    margin: 0,
    textAlign: 'center',
    color: 'white',
    fontSize: 'small',
    fontWeight: 400,
    lineHeight: 1.5,
    paddingBottom: 5,
    '& a': {
      color: 'white'
    }
  },
  footerLinks: {
    '& a': {
      marginLeft: 30,
      marginRight: 30,
    }
  }
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <div className={`${classes.copyright} ${classes.font}`}>
        <p>
          Copyright &copy; <a href="https://dotmotelabs.com/">DotMote Labs</a> {new Date().getFullYear()}
        </p>
        <p>
          PlantBeats is a registered trademark of <a href="https://dotmotelabs.com/">DotMote Labs</a>.
        </p>
        <div className={classes.footerLinks}>
          <a href="/terms">Terms of Use</a>
          <a href="/privacy">Privacy Policy</a>
          <a href="http://docs.plantbeats.io/" target="_blank">Documentation</a>
        </div>
      </div>
    </footer>
  );
}