import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import XYFrame from "semiotic/lib/XYFrame"
import {scaleTime} from "d3-scale";

const useStyles = makeStyles((theme) => ({
    relative: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(5),
        position: 'relative',
    },
}));

const attrNameToString = {
    'meanHeatRatio' : 'Mean Heat Ratio',
    'meanSoilMoisture' : 'Mean Soil Moisture',
    'meanOutsideTemp' : 'Mean Outside Temperature',
    'meanOutsideHumidity' : 'Mean Outside Humidity'
};

export default function ScatterPlot(props) {
    const classes = useStyles();

    const [points, setPoints] = useState([]);

    useEffect(() => {
        getPoints();
    }, [props.charts, props.rawData]);

    function getPoints() {
        let result = [];
        props.chart['lines'].map((line) => {
            props.rawData.filter((data) => data["customerId#nodeId"].substring(2) === line['name']).map((data) => (
                result.push({timestamp: data['timestamp'], title: line['name'], amount : props.chart['inverse']? 1 / data[props.chart['attribute']] : data[props.chart['attribute']]})))
        });
        setPoints(result);
    }

    const frameProps = {
        points: points,
        size: [900, 700],
        margin: {left: 60, bottom: 90, right: 10, top: 40},
        xScaleType: scaleTime(),
        xAccessor: "timestamp",
        yAccessor: "amount",
        yExtent: [props.chart['yMin'] !== undefined? props.chart['yMin'] : undefined, props.chart['yMax'] !== undefined? props.chart['yMax'] : undefined],
        xExtent:[props.chart['xMin'] !== undefined? props.chart['xMin']: undefined, props.chart['xMax'] !== undefined? props.chart['xMax']: undefined],
        pointStyle: (d) => {
            return {
                r: 5,
                fill: props.chart['lines'].filter((line) => line['name'] === d.title)[0]['stroke']
            }
        },
        axes: [{orient: "left", label: attrNameToString[props.chart['attribute']]},
            {
                orient: "bottom", tickFormat: function (unixTime) {
                    let date = new Date(unixTime * 1000);
                    let hours = "0" + date.getUTCHours();
                    let minutes = "0" + date.getUTCMinutes();
                    return (date.getUTCMonth() + 1) + '/' + date.getUTCDate() + '\n' + hours.substr(-2) + ':' + minutes.substr(-2);
                }, label: "Time", ticks: 5
            }],
        hoverAnnotation: true,
        tooltipContent: d => (
            <div className="tooltip-content">
                <p className="body4"><strong>Time:</strong> {(new Date(d.timestamp * 1000)).toString()}</p>
                <p className="body4"><strong>{attrNameToString[props.chart['attribute']]}</strong> {d['amount']}</p>
            </div>
        ),
    };
    return (
        <div className={classes.relative}>
            <XYFrame {...frameProps} />
        </div>
    )
}
