import React from "react";
import PrivacyPolicy from "./components/PrivacyPolicy";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: "40vw",
    marginBottom: "10vh"
  }
}));

/**
 * Initializes and maintains the privacy page of the app.
 */
export default function Privacy() {
  const classes = useStyles();

  return (
    <Typography className={classes.root}>
      <div style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        margin: 50,
      }}>
        <a href="/home">
          <img
            src={require('./img/largeLogo.png')}
            style={{
              padding: 0,
              paddingRight: 150,
            }}
            alt="Plantbeats"
          />
        </a>
      </div>
      <h1 style={{textAlign: "center"}}>Privacy Policy</h1>
      <PrivacyPolicy/>
    </Typography>
  )
}