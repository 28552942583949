import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import Dashboard from './Dashboard'
import Nodes from './Nodes'
import LoginDesktop from './LoginDesktop'
import Login from './Login'
import Graphs from './Graphs'
import Signup from './Signup'
import RoseStemMHR from './RoseStemMHR'
import NodeExample from './NodeExample'
import AddGraph from './AddGraph'
import { BrowserRouter } from 'react-router-dom';
import DesktopBreakpoint from './responsive_utilities/desktop_breakpoint'
import PhoneBreakpoint from './responsive_utilities/phone_breakpoint'
import TabletBreakpoint from './responsive_utilities/tablet_breakpoint'
import App from './App';

import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
  ,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
