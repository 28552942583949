import React, {useCallback, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {FormControl, Grid, Input, MenuItem, Select, Chip} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDateTimePicker} from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import TimelineIcon from '@material-ui/icons/Timeline';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import GraphColorIcon from "./GraphColorIcon";
import StarIcon from "@material-ui/icons/Star";
import ButtonM from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from '@material-ui/icons/Add';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import debounce from "./UseDebounce";
import AddAnnotation from "./AddAnnotation";
import {attributeToString, dateToString, getDarkColor, oneWeekUnixTime, parseAttribute, parseDate, stringToAttributeJSON} from "./GlobalFunctions";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '24ch',
    },
  },
  rootDate: {
    '& > *': {
      margin: theme.spacing(1),
      width: '13ch',

    },
    '& .MuiFormLabel-root': {
      paddingLeft: 0,
    },
  },
  rootDateAndTime: {
    '& > *': {
      margin: theme.spacing(1),
      width: '10ch',

    },
    '& .MuiFormLabel-root': {
      paddingLeft: 0,
    },
  },
  rootValue: {
    '& > *': {
      margin: theme.spacing(1),
      width: '8ch',
    },
    display: 'inline',
  },
  rootTag: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  tag: {
    margin: theme.spacing(0.5),
  },
  graphTitle: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginRight: theme.spacing(3),
  },
  input: {
    height: 20,
    margin: theme.spacing(0, 1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    '& > *': {
      width: '26ch',
    },
  },
  formControlSmall: {
    margin: theme.spacing(1),
    width: '24ch',
    '& > *': {
      width: '16ch',
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    marginRight: 2,
    height: '19px',
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  rotate: {
    transform: 'rotate(-90deg)',
  },
  favoriteButton: {
    border: '1px solid #FFC700',
    borderRadius: 5,
    height: 35,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: '200px',
    margin: 'auto'
  },
  deleteButton: {
    border: '1px solid #373737',
    borderRadius: 5,
    height: 35,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: '200px'
  },
  applyButton: {
    border: '1px solid #97D89A',
    borderRadius: 5,
    height: 35,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: '200px'
  },
  invertButton: {
    border: '1px solid #FF7F7F',
    borderRadius: 5,
    height: 35,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: '200px'
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const annotationName = [
  'All points and lines',
  'All points',
  'All lines',
];

function getStyles(name, fieldName, theme) {
  return {
    fontWeight:
      fieldName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function initDateAndTime(date) {
  let d = new Date();
  d.setTime(date * 1000);
  return d;
}

export default function GraphFields(props) {
  const classes = useStyles();

  const theme = useTheme();

  const history = useHistory();

  const [graphTitle, setGraphTitle] = React.useState(props.chart['graphTitle']);
  const [nodeFieldName, setNodeFieldName] = React.useState(props.chart['nodeIds']);
  const [attrFieldName, setAttrFieldName] = React.useState(attributeToString(props.chart['attribute']));
  const [annoFieldName, setAnnoFieldName] = React.useState(annotationName[0]);
  const [selectedFromDate, setSelectedFromDate] = React.useState(parseDate(props.chart['fromDate']));
  const [selectedToDate, setSelectedToDate] = React.useState(parseDate(props.chart['toDate']));
  const [xAxisMin, setXAxisMin] = React.useState(props.chart['xMin'] !== undefined ? initDateAndTime(props.chart['xMin']) : parseDate(props.chart['fromDate']));
  const [xAxisMax, setXAxisMax] = React.useState(props.chart['xMax'] !== undefined ? initDateAndTime(props.chart['xMax']) : parseDate(props.chart['toDate']));
  const [yAxisMin, setYAxisMin] = React.useState(props.chart['yMin']);
  const [yAxisMax, setYAxisMax] = React.useState(props.chart['yMax']);

  const [favorite, setFavorite] = React.useState(props.chart['favorite']);
  const [inverse, setInverse] = React.useState(props.chart['inverse']);

  const [annoOpen, setAnnoOpen] = React.useState(false);

  const debouncedSaveGraphTitle = useCallback(debounce(nextValue => handleGraphTitleOnDebounce(nextValue), 1000), []);
  const debouncedSaveYAxisMin = useCallback(debounce(nextValue => handleYAxisMinOnDebounce(nextValue), 1000), []);
  const debouncedSaveYAxisMax = useCallback(debounce(nextValue => handleYAxisMaxOnDebounce(nextValue), 1000), []);
  const debouncedSaveXAxisMin = useCallback(debounce(nextValue => handleXAxisMinOnDebounce(nextValue), 2000), []);
  const debouncedSaveXAxisMax = useCallback(debounce(nextValue => handleXAxisMaxOnDebounce(nextValue), 2000), []);
  const debouncedSaveFromDate = useCallback(debounce(nextValue => handleFromDateOnDebounce(nextValue), 2000), []);
  const debouncedSaveToDate = useCallback(debounce(nextValue => handleToDateOnDebounce(nextValue), 2000), []);

  const [dateRangeError, setDateRangeError] = React.useState(false);
  const [dateRangeErrorMessage, setDateRangeErrorMessage] = React.useState('');

  useEffect(() => {
    dateRangeHasError(parseDate(props.chart['fromDate']), parseDate(props.chart['toDate']))
  }, []);

  function handleEditMessage(msg) {
    let date = new Date();
    props.chart['editHistory'].push("Edited on " + date.toString().slice(4, 21) + " | " + msg);
  }

  const handleGraphTitleOnChange = async (event) => {
    setGraphTitle(event.target.value);
    debouncedSaveGraphTitle(event.target.value)
  };

  const handleGraphTitleOnDebounce = async (graphTitle) => {
    handleEditMessage("Changed graph title from \"" + props.chart['graphTitle'] + "\" to \"" + graphTitle + "\"");
    props.chart['graphTitle'] = graphTitle;
    await props.fetchEditPersonalization(props.charts);
  };

  const handleNodeFieldOnChange = async (event) => {
    setNodeFieldName(event.target.value);
    event.target.value.filter((nodeId) => !props.chart['nodeIds'].includes(nodeId)).map(async (nodeId) => {
      handleEditMessage("Added \"" + nodeId + "\" node");
      props.chart['lines'].push({
        name: nodeId,
        stroke: getDarkColor()
      });
      await props.fetchSapflows(props.chart, nodeId);
    });
    props.chart['nodeIds'].filter((nodeId) => !event.target.value.includes(nodeId)).map(async (nodeId) => {
      handleEditMessage("Removed \"" + nodeId + "\" node");
      props.chart['lines'] = props.chart['lines'].filter((line) => line['name'] !== nodeId);
      props.sapflows[props.chart['gid']] = (props.sapflows[props.chart['gid']].filter((data) => data["customerId#nodeId"].substring(2) !== nodeId));
    });
    props.chart['nodeIds'] = event.target.value;
    await props.fetchEditPersonalization(props.charts);
  };

  const handleAttrFieldOnChange = async (event) => {
    setAttrFieldName(event.target.value);
    handleEditMessage("Changed attribute from \"" + attributeToString(props.chart['attribute']) + "\" to \"" + event.target.value + "\"");
    props.chart['attribute'] = parseAttribute(event.target.value);
    await props.fetchEditPersonalization(props.charts);
  };

  const handleAnnoFieldName = (event) => {
    setAnnoFieldName(event.target.value);
  };

  const dateRangeHasError = (fromDate, toDate) => {
    if (!props.stripe.subscription) {
      let result = fromDate.getTime() + oneWeekUnixTime < toDate.getTime();
      setDateRangeError(result);
      setDateRangeErrorMessage('Must subscribe to have a greater date range');
      return result;
    }
    return false;
  };

  const handleFromDateOnChange = async (date) => {
    setSelectedFromDate(date);
    if (props.chart['xMin'] === undefined) {
      setXAxisMin(date);
    }
    debouncedSaveFromDate(date);
  };

  const handleFromDateOnDebounce = async (date) => {
    dateRangeHasError(date, selectedToDate);
    handleEditMessage("Changed from date from \"" + props.chart['fromDate'] + "\" to \"" + date.getFullYear() + '/'
      + String(date.getMonth() + 1).padStart(2, '0') + '/' + String(date.getDate()).padStart(2, '0') + "\"");
    props.chart['fromDate'] = dateToString(date);
    await handleDateOnChange();
  };

  const handleToDateOnChange = async (date) => {
    setSelectedToDate(date);
    if (props.chart['xMax'] === undefined) {
      setXAxisMax(date);
    }
    debouncedSaveToDate(date);
  };

  const handleToDateOnDebounce = async (date) => {
    dateRangeHasError(selectedFromDate, date)
    handleEditMessage("Changed to date from \"" + props.chart['toDate'] + "\" to \"" + date.getFullYear() + '/'
      + String(date.getMonth() + 1).padStart(2, '0') + '/' + String(date.getDate()).padStart(2, '0') + "\"");
    props.chart['toDate'] = dateToString(date);
    await handleDateOnChange();
  };

  const handleDateOnChange = async () => {
    await props.fetchEditPersonalization(props.charts);
    props.sapflows[props.chart['gid']] = [];
    props.chart['lines'].map(async (line) => {
      await props.fetchSapflows(props.chart, line['name']);
    });
  };

  const handleXAxisMinOnChange = async (date) => {
    setXAxisMin(date);
    debouncedSaveXAxisMin(date);
  };

  const handleXAxisMinOnDebounce = async (date) => {
    handleEditMessage("Changed x-axis min value from \"" + new Date(props.chart['xMin'] * 1000).toString().substring(0, 21) + "\" to \"" + date.toString().substring(0, 21) + "\"");
    props.chart['xMin'] = date.getTime() / 1000;
    await props.fetchEditPersonalization(props.charts);
  };

  const handleXAxisMaxOnChange = async (date) => {
    setXAxisMax(date);
    debouncedSaveXAxisMax(date);
  };

  const handleXAxisMaxOnDebounce = async (date) => {
    handleEditMessage("Changed x-axis max value from \"" + new Date(props.chart['xMax'] * 1000).toString().substring(0, 21) + "\" to \"" + date.toString().substring(0, 21) + "\"");
    props.chart['xMax'] = date.getTime() / 1000;
    await props.fetchEditPersonalization(props.charts);
  };

  const handleYAxisMinOnChange = async (event) => {
    if (event.target.value < yAxisMax || yAxisMax === undefined || event.target.value === "") {
      debouncedSaveYAxisMin(event.target.value);
    }
  };

  const handleYAxisMinOnDebounce = async (value) => {
    if (value === "") {
      setYAxisMin(undefined);
      handleEditMessage("Changed y-axis min value from \"" + props.chart['yMin'] + "\" to \"auto\"");
      props.chart['yMin'] = undefined;
    } else {
      setYAxisMin(value);
      handleEditMessage("Changed y-axis min value from \"" + props.chart['yMin'] + "\" to \"" + value + "\"");
      props.chart['yMin'] = value;
    }
    await props.fetchEditPersonalization(props.charts);
  };

  const handleYAxisMaxOnChange = async (event) => {
    if (event.target.value > yAxisMin || yAxisMin === undefined || event.target.value === "") {
      debouncedSaveYAxisMax(event.target.value);
    }
  };

  const handleYAxisMaxOnDebounce = async (value) => {
    if (value === "") {
      setYAxisMax(undefined);
      handleEditMessage("Changed y-axis max value from \"" + props.chart['yMax'] + "\" to \"auto\"");
      props.chart['yMax'] = undefined;
    } else {
      setYAxisMax(value);
      handleEditMessage("Changed y-axis max value from \"" + props.chart['yMax'] + "\" to \"" + value + "\"");
      props.chart['yMax'] = value;
    }
    await props.fetchEditPersonalization(props.charts);
  };

  const handleFavoriteOnChange = async () => {
    setFavorite(!favorite);
    props.chart['favorite'] = !props.chart['favorite'];
    await props.fetchEditPersonalization(props.charts);
  };

  const [values, setAnnotationVisibility] = React.useState({
    annotation: '',
    showAnnotation: false,
  });

  const handleClickShowAnnotation = () => {
    setAnnotationVisibility({...values, showAnnotation: !values.showAnnotation});
  };

  const handleMouseDownShowAnnotation = (event) => {
    event.preventDefault();
  };

  const [tagData, setTagData] = React.useState(props.chart['tags']);
  const handleTagDelete = (tagToDelete) => async () => {
    setTagData((tags) => tags.filter((tag) => tag.key !== tagToDelete.key));
    handleEditMessage("Removed tag \"" + tagToDelete.label + "\"");
    props.chart['tags'].filter((tag) => tag.key !== tagToDelete.key);
    await props.fetchEditPersonalization(props.charts);
  };

  const handleAddTag = async () => {
    let newTag = prompt('Keyword Tag');
    if (newTag) {
      setTagData(tagData.concat([{key: tagData.length, label: newTag}]));
      handleEditMessage("Added tag \"" + newTag + "\"");
      props.chart['tags'].push({key: tagData.length, label: newTag});
      await props.fetchEditPersonalization(props.charts);
    }
  };

  const handleDeleteOnClick = async (e) => {
    props.setCharts(
      props.charts.filter((temp) => temp !== props.chart)
    );
    await props.fetchEditPersonalization(props.charts.filter((temp) => temp !== props.chart));
    e.preventDefault();
    history.push("/graphs");
  };

  const handleInverseOnClick = async () => {
    setInverse(!inverse);
    props.chart['inverse'] = !props.chart['inverse'];
    await props.fetchEditPersonalization(props.charts);
  };

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <div className={classes.graphTitle}>
            <p className="body4"><strong>Title </strong></p>
            <form className={classes.root} style={{}} noValidate autoComplete="off">
              <Input defaultValue={graphTitle} inputProps={{'aria-label': 'description'}}
                     onChange={handleGraphTitleOnChange}/>
            </form>
          </div>
          <div className={classes.graphTitle}>
            <p className="body4"><strong>From </strong></p>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker className={classes.rootDate}
                                      format="MM/dd/yy"
                                      variant="inline"
                                      value={selectedFromDate}
                                      maxDate={selectedToDate}
                                      onChange={handleFromDateOnChange}
                                      KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                      }}
                                      {...(dateRangeError ? {error: true} : {})}
                                      {...(dateRangeError ? {helperText: dateRangeErrorMessage} : {})}
              />
            </MuiPickersUtilsProvider>
            <p className="body4"><strong>To </strong></p>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker className={classes.rootDate}
                                      format="MM/dd/yy"
                                      variant="inline"
                                      value={selectedToDate}
                                      minDate={selectedFromDate}
                                      onChange={handleToDateOnChange}
                                      KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                      }}
                                      {...(dateRangeError ? {error: true} : {})}
                                      {...(dateRangeError ? {helperText: dateRangeErrorMessage} : {})}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className={classes.graphTitle}>
            <p className="body4"><strong>Annotation</strong></p>
            <IconButton
              aria-label="toggle annotation visibility"
              onClick={handleClickShowAnnotation}
              onMouseDown={handleMouseDownShowAnnotation}
              edge="end"
            >
              {values.showAnnotation ? <VisibilityIcon/> : <VisibilityOffIcon/>}
            </IconButton>
            <FormControl className={classes.formControlSmall}>
              <Select
                labelId="select attribute"
                id="select attribute"
                value={annoFieldName}
                onChange={handleAnnoFieldName}
                MenuProps={MenuProps}
                className={classes.selectEmpty}
              >
                {annotationName.map((name) => (
                  <MenuItem key={name} value={name} style={getStyles(name, annoFieldName, theme)}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.graphTitle}>
            <p className="body4"><strong>Node</strong></p>
            <FormControl className={classes.formControl}>
              <Select
                labelId="select node"
                id="select node"
                multiple
                value={nodeFieldName}
                onChange={handleNodeFieldOnChange}
                input={<Input id="select node options"/>}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} className={classes.chip}/>
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {props.nodes.map((node) => (
                  <MenuItem key={node} value={node['nodeId']}
                            style={getStyles(node['nodeId'], nodeFieldName, theme)}>
                    {node['nodeId']}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.graphTitle}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                className={classes.rootDateAndTime}
                maxDate={xAxisMax}
                value={xAxisMin}
                onChange={handleXAxisMinOnChange}
                format="MM/dd"
                variant="inline"
              />
            </MuiPickersUtilsProvider>
            <p className="body4" style={{display: 'inline'}}><strong>&#60; &#160; X-axis
              Range &#160; &#60;</strong></p>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                className={classes.rootDateAndTime}
                minDate={xAxisMin}
                value={xAxisMax}
                onChange={handleXAxisMaxOnChange}
                format="MM/dd"
                variant="inline"
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className={classes.graphTitle}>
            <p className="body4"><strong>Style &#160;</strong></p>
            {props.chart['lines'].map((line) => (
              <GraphColorIcon key={line['name']}
                              line={line}
                              charts={props.charts}
                              setCharts={props.setCharts}
                              fetchEditPersonalization={props.fetchEditPersonalization}
              />
            ))}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.graphTitle}>
            <p className="body4"><strong>Attribute</strong></p>
            <FormControl className={classes.formControl}>
              <Select
                labelId="select attribute"
                id="select attribute"
                value={attrFieldName}
                onChange={handleAttrFieldOnChange}
                MenuProps={MenuProps}
                className={classes.selectEmpty}
              >
                {Object.keys(stringToAttributeJSON).map((name) => (
                  <MenuItem key={name} value={name}
                            style={getStyles(name, attrFieldName, theme)}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.graphTitle}>
            <form className={classes.rootValue} style={{}} noValidate autoComplete="off">
              <Input type="number" inputProps={{'aria-label': 'description'}} placeholder="auto"
                     value={yAxisMin} onChange={handleYAxisMinOnChange}/>
            </form>
            <p className="body4" style={{display: 'inline'}}><strong>&#60; &#160; Y-axis
              Range &#160; &#60;</strong></p>
            <form className={classes.rootValue} style={{}} noValidate autoComplete="off">
              <Input type="number" inputProps={{'aria-label': 'description'}} placeholder="auto"
                     value={yAxisMax} onChange={handleYAxisMaxOnChange}/>
            </form>
          </div>
          <div className={classes.graphTitle}>
            <p className="body4"><strong>Graph Type &#160; </strong></p>
            <TimelineIcon htmlColor={props.graphType === 0 ? "#000000" : "#818181"}
                          style={{margin: '10 0 10 10'}} onClick={() => props.setGraphType(0)}/>
            <EqualizerIcon htmlColor={props.graphType === 1 ? "#000000" : "#818181"}
                           style={{margin: '10 0 10 10'}} onClick={() => props.setGraphType(1)}/>
            <ScatterPlotIcon htmlColor={props.graphType === 2 ? "#000000" : "#818181"}
                             style={{margin: '10 0 10 10'}} onClick={() => props.setGraphType(2)}/>
            {/*{props.chart['nodeIds'].length === 2? <BlurOffIcon htmlColor={props.graphType === 3? "#000000": "#818181"}  className={classes.rotate} style={{margin: '10 0 10 10'}} onClick={()=>props.setGraphType(3)}/> : null}*/}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <div className={classes.center}>
            <ButtonM
              className={classes.favoriteButton}
              elevation={0}
              startIcon={<StarIcon htmlColor="#FFC700" fontSize="small"/>}
              onClick={handleFavoriteOnChange}
              style={favorite ? {backgroundColor: '#f8ffa6'} : {}}
            >
              <p className="body3">Favorite</p>
            </ButtonM>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.center}>
            <ButtonM
              className={classes.applyButton}
              elevation={0}
              startIcon={<AddIcon htmlColor="#97D89A" fontSize="small"/>}
              onClick={() => setAnnoOpen(true)}
            >
              <p className="body3">Annotate &#160;</p>
            </ButtonM>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.center}>
            <ButtonM
              className={classes.deleteButton}
              elevation={0}
              startIcon={<DeleteIcon htmlColor="#878686" fontSize="small"/>}
              onClick={handleDeleteOnClick}
            >
              <p className="body3">Delete &#160;</p>
            </ButtonM>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.center}>
            <ButtonM
              className={classes.invertButton}
              elevation={0}
              startIcon={<CompareArrowsIcon htmlColor="#FF7F7F" fontSize="small"/>}
              onClick={handleInverseOnClick}
              style={inverse ? {backgroundColor: '#FFBFBF'} : {}}
            >
              <p className="body3">Invert &#160;</p>
            </ButtonM>
          </div>
        </Grid>
      </Grid>
      <div className={classes.graphTitle}>
        <p className="body4"><strong>Tags</strong></p>
        <div>
          {tagData.map((data) => {
            let icon;
            return (
              <div style={{display: 'inline'}} key={data.key}>
                <Chip
                  icon={icon}
                  label={data.label}
                  onDelete={data.label === 'React' ? undefined : handleTagDelete(data)}
                  className={classes.tag}
                />
              </div>
            );
          })}
        </div>
        <IconButton
          onClick={handleAddTag}
          disableFocusRipple
          disableRipple
          style={{backgroundColor: 'transparent'}}
          size='small'
        >
          <AddCircleIcon htmlColor="#878686" style={{marginLeft: '5'}}/>
        </IconButton>
        <AddAnnotation
          annoOpen={annoOpen}
          setAnnoOpen={setAnnoOpen}
          chart={props.chart}
          annotations={props.annotations}
          setAnnotations={props.setAnnotations}
          fetchAddAnnotation={props.fetchAddAnnotation}/>
      </div>
    </React.Fragment>
  );
}
