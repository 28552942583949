import ChartB from './ChartB';
import { makeStyles } from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Tooltip from "@material-ui/core/Tooltip";
import NodeFilterB from "./NodeFilterB";
import AttributeFilter from "./AttributeFilter";
import Chart from "./Chart";


const useStyles = makeStyles((theme) => ({
  chart: {
    marginBottom: theme.spacing(10),
    maxWidth: 300,
    maxHeight: 200,
    padding: theme.spacing(2),
  },
  widgetLabel: {
    display: 'flex',
    flexDirection: 'row',
  },
  widgetMargin: {
    marginRight: theme.spacing(2),
  },
  widgetRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(1),
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
  },
  block: {
    display: 'block'
  }
}));

export default function Dashboard(props) {
  const classes = useStyles();

  const [filteredCharts, setFilteredCharts] = useState([]);

  useEffect(() => {
    setFilteredCharts(props.charts.slice(0, 3));
  }, [props.charts]);

  const handleAttributeFilterOnChange = (e) => {
    switch (e.target.value) {
      case "all":
        setFilteredCharts(props.charts.slice(0, 3));
        break;
      case "meanHeatRatio":
        setFilteredCharts(props.charts.filter((chart) => chart['attribute'] === 'meanHeatRatio').slice(0, 3));
        break;
      case "meanOutsideTemp":
        setFilteredCharts(props.charts.filter((chart) => chart['attribute'] === 'meanOutsideTemp').slice(0, 3));
        break;
      case "meanSoilMoisture":
        setFilteredCharts(props.charts.filter((chart) => chart['attribute'] === 'meanSoilMoisture').slice(0, 3));
        break;
      case "meanOutsideHumidity":
        setFilteredCharts(props.charts.filter((chart) => chart['attribute'] === 'meanOutsideHumidity').slice(0, 3));
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.block}>
      <Tooltip title="Thumbnails and shortcuts to last viewed graphs. " placement="top-start">
        <div className={classes.widgetLabel}>
          <ScheduleIcon
            color="disabled"
            fontSize="large"
            className={classes.widgetMargin}
          />
          <h2>Last Viewed</h2>
          <div className={classes.filters}>
            {/*<NodeFilterB/>*/}
            <AttributeFilter onChange={handleAttributeFilterOnChange}/>
          </div>
        </div>
      </Tooltip>
      <div className={classes.widgetRow}>
        {filteredCharts.map((chart) =>
            <div key={chart['graphTitle']} className={classes.chart}>
              <Chart
                  chart={chart}
                  nodes={props.nodes}
                  charts={props.charts}
                  setCharts={props.setCharts}
                  sapflows={props.sapflows}
                  fetchEditPersonalization={props.fetchEditPersonalization}
              />
            </div>
        )}
      </div>
    </ div>
  );
}
