import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { makeStyles } from '@material-ui/core/styles';
import '../index.css'


const useStyles = makeStyles((theme) => ({
  caption: {
    color: '#878686',
    fontFamily: 'Nunito',
    fontSize: 14,
    fontStyle: 'italic',
    fontWeight: 400,
    margin: theme.spacing(1),
    textAlign: 'right',
  },
  disabledIcon: {
    color: '#ECECEC',
    margin: theme.spacing(1),
  },
  iconCircle: {
    color: '#FFC700',
    margin: theme.spacing(1, 3, 0, 0),
  },
  widgetColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  widgetLabel: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
  },
  widgetMargin: {
    marginRight: theme.spacing(2),
  },
  widgetRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(1),
  },
}));

export default function NotificationItems(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      {props.notifications.map((notification, i) =>
        <div key={i} className={classes.widgetLabel}>
          <FiberManualRecordIcon className={classes.iconCircle} fontSize="small" />
          <div className={classes.widgetColumn}>
            {notification.message}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

NotificationItems.defaultProps = {
  notifications: []
}