import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Profile from "./Profile";
import Notification from "./Notification";
import Billing from "./Billing";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      minHeight: '70vh',
      paddingTop: '2vh',
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
      minWidth: 150,
      marginRight: 50,
    },
    content: {
      width: 1000,
      paddingRight: 75
    }
  }));

  const paths = {
    '/profile': 0,
    '/account': 1,
    '/notification': 2,
    '/organization': 3
  }

  export default function NavSetting(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(props.value || 0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs"
          className={classes.tabs}
          indicatorColor="primary"
          textColor="inherit"
          style={{marginTop: 0}}
          TabIndicatorProps={{style: {background: "#74BB88"}}}

        >
          <Tab label="Profile" {...a11yProps(0)} />
          <Tab label="Account" {...a11yProps(1)} disabled/>
          <Tab label="Notification" {...a11yProps(2)} />
          <Tab label="Organization" {...a11yProps(3)} disabled/>
          <Tab label="Billing" {...a11yProps(4)}/>
        </Tabs>
        <TabPanel value={value} index={0}>
          <div className={classes.content}>
            <Profile/>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1} style={{padding: 0}}>
          <div className={classes.content}>
            {/* TODO: Account Page */}
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Notification nodes={props.nodes}/>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div className={classes.content}>
            {/* TODO: Organization Page */}
          </div>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <div className={classes.content}>
            <Billing stripe={props.stripe} setStripe={props.setStripe}/>
          </div>
        </TabPanel>
      </div>
    );
  }
