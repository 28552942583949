import React from "react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import PaymentForm from "./PaymentForm";

const STRIPE_PUBLIC_KEY = 'pk_test_51JUh3WDWnxRi2VSjNePh1VKIjaU0T2pMB6yGcLY180DBo9y4erxr9lX5ql6d7LK4VFvCzJVQJmSAkyWjXfJPW6Mr00XfUV20W7';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export default function Payment(props) {

  return (
    <div>
      <Elements stripe={stripePromise}>
        <PaymentForm stripe={props.stripe} setStripe={props.setStripe}/>
      </Elements>
    </div>
  )
}