import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from './Checkbox'
import SingleNodeInfo from './SingleNodeInfo'
import {Link} from 'react-router-dom';
import '../index.css'

const useStyles = makeStyles((theme) => ({
  checkbox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
  },
  img: {
    marginLeft: theme.spacing(2),
  },
  node: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    margin: theme.spacing(3, 0)
  },
  singleNode: {
    alignItems: 'center',
    flexGrow: 1,
    width: "100%"
  },
}));

export default function SelectNode(props) {
  const classes = useStyles();

  const handleChange = (e) => {
    if (e.target.checked) {
      props.onSelect(props.node);
    } else {
      props.onDeselect(props.node);
    }
  }

  return (
    <div className={classes.node}>
      <div className={classes.checkbox}>
        <Checkbox onChange={handleChange} checked={props.checked}/>
      </div>
      <div className={classes.singleNode}>
        <SingleNodeInfo node={props.node} nodeFavorites={props.nodeFavorites} setNodeFavorites={props.setNodeFavorites} metrics={props.metrics}/>
      </div>
      <img
        src={require('../img/Map.png')}
        className={classes.img}
        alt="Node location on a map"
      />
    </div>
  );
}
