import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Container, CssBaseline, Grid} from '@material-ui/core';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import './index.css'
import Chart from "./components/Chart";
import NavPrimary from './components/NavPrimary'
import SearchNode from './components/SearchNode'
import NodeFields from './components/NodeFields'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#FFF',
  },
  appBar: {
    color: '#000',
    backgroundColor: '#FFF',
    flexDirection: 'row',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: 'auto',
    // overflow: 'auto',
  },
  chart: {
    marginBottom: theme.spacing(10),
    maxWidth: 300,
    maxHeight: 200,
    padding: theme.spacing(2),
  },
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4),
  },
  fixedHeight: {
    height: 650,
  },
  h2: {
    borderBottom: '1px solid #61A487',
    lineHeight: '0.1em',
    margin: theme.spacing(7, 0),
    textAlign: 'center',
    width: '100%',
  },
  icon: {
    margin: theme.spacing(0, 1),
  },
  node: {
    display: 'flex',
    flexDirection: 'row',
  },
  search: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(10),
  },
  selectAll: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
  },
  singleNodeInfo: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '90%'
  },
  span: {
    backgroundColor: '#FFF',
    padding: theme.spacing(5),
  },
  button: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: theme.spacing(0),
  },
  widgetRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(1),
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tertiaryButton: {
    backgroundColor: '#61A487',
    borderRadius: 5,
    color: '#DEF3DF',
    height: 35,
    padding: theme.spacing(2),
  },
}));

/**
 * Initializes and maintains the interface for node details page.
 * @param props
 */
export default function Nodes(props) {
  const classes = useStyles();
  const location = useLocation();

  const [search, setSearch] = React.useState({
    search: '',
    sort: 'favorite',
    filter: 'all'
  });

  const nodeId = new URLSearchParams(location.search).get('id');
  const node = props.nodes.find(node => node['nodeId'] === nodeId);

  return (
    <div className={classes.root}>
      <CssBaseline/>
      <NavPrimary/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer}/>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.search}>
                <SearchNode search={search} setSearch={setSearch}/>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h2 className={classes.h2}>
              <span className={classes.span}>Edit Node Info</span>
            </h2>
          </Grid>
          <Grid item xs={12}>
            <NodeFields node={node} nodes={props.nodes} setNodes={props.setNodes} user={props.user}
                        favorites={props.nodeFavorites} setFavorites={props.setNodeFavorites}/>
          </Grid>
          <Grid item xs={12}>
            <h2 className={classes.h2}>
              <span className={classes.span}>Graphs</span>
            </h2>
            <div className={classes.buttonRow}>
              <Button
                className={classes.tertiaryButton}
                elevation={0}
                variant="contained"
                startIcon={<ViewModuleIcon htmlColor="#DEF3DF" fontSize="inherit"/>}
              >
                <Link to="/graphs" style={{color: '#fff'}}><p className="body3">View All</p></Link>
              </Button>
            </div>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {props.charts.filter(chart => chart.nodeIds.includes(nodeId)).map((chart, i) => (
                  <div key={i} className={classes.chart}>
                    <Chart nodes={props.nodes} charts={props.charts} setCharts={props.setCharts} chart={chart}
                           user={props.user} sapflows={props.sapflows}
                           fetchEditPersonalization={props.fetchEditPersonalization}/>
                  </div>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
