import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {AppBar, Avatar, Badge, IconButton, Menu, MenuItem, Popover, Toolbar, Typography} from '@material-ui/core';
import {AccountCircle, ExpandLess, ExpandMore, MoreVert, Notifications} from '@material-ui/icons';
import NotificationItems from './NotificationItems';
import {Link} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import leafLogo from "../img/leafLogo.png";
import plantbeats from "../img/plantbeats.png";
import {UserContext} from "../App.js";

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: '#FFF',
        borderRadius: '0',
        color: '#FFC700',
        padding: '10px',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    navigation: {
        display: 'flex',
        margin: theme.spacing(0, 4),
    },
    navH2: {
        color: '#000',
        padding: theme.spacing(0, 4),
        margin: theme.spacing(2),
    },
    navH3: {
        color: '#000'
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    typography: {
        padding: theme.spacing(2),
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(4),
        marginRight: 10,
    },
    avatar: {
        height: 35,
        width: 35,
        fontSize: 17.5
    }
}));

const activeStyle = {
    fontWeight: "bold",
    color: "#61A487"
};

export default function PrimarySearchAppBar(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = React.useState([]);

    const {user, apiKey} = React.useContext(UserContext);

    useEffect(() => {
        if (apiKey) {
            fetchNotifications();
        }
    }, [apiKey]);

    async function fetchNotifications() {
        fetch('https://api.plantbeats.io/notifications?username=' + user['username'],
            {
                method: 'GET',
                mode: "cors",
                headers: {
                    'Authorization': user['signInUserSession']['idToken']['jwtToken'],
                    'x-api-key': apiKey
                }
            })
            .then(res => res.json())
            .then(data => setNotifications(data))
            .catch(console.log);
    }

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

    // Notification Popover
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const handlePopoverOpen = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl2(null);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={Boolean(anchorEl)}
            onClose={() => {
                setAnchorEl(null);
            }}
            getContentAnchorEl={null}
        >
            <MenuItem component={Link} to={{pathname: "/settings", state: 0}}>Profile</MenuItem>
            <MenuItem component={Link} to={{pathname: "/settings", state: 2}}>Notification</MenuItem>
            <MenuItem component={Link} to={{pathname: "/settings", state: 1}}>Organization</MenuItem>
            <MenuItem onClick={() => Auth.signOut()}>Logout</MenuItem>
        </Menu>
    );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(mobileMoreAnchorEl)}
      onClose={() => {
        handlePopoverClose();
        setMobileMoreAnchorEl(null);
      }}
    >
      <MenuItem onClick={anchorEl2 ? handlePopoverClose : handlePopoverOpen}>
        <IconButton aria-label="notifications">
          <Badge badgeContent={notifications.length} color="secondary">
            <Notifications htmlColor="#FFC700" />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
        >
          <AccountCircle htmlColor="#D7D7D7" />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

    const open = Boolean(anchorEl2);
    return (
        <div>
            <AppBar className={classes.appBar} position="fixed">
                <Toolbar>
                    <Link to='/'>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            aria-label="open drawer"
                        >
                            <img src={leafLogo} alt="leaf logo"/>
                        </IconButton>
                    </Link>
                    <div className={classes.title}>
                        <Link to='/'>
                            <img src={plantbeats} alt="plantbeats"/>
                        </Link>
                    </div>
                    <div className={classes.grow}/>
                    <div className={classes.sectionDesktop}>
                        <Typography className={classes.navigation}>
                            <Link style={window.location.pathname === "/dashboard" ? activeStyle : {}}
                                  className={classes.navH2} to="/dashboard">Dashboard</Link>
                            <Link
                                style={window.location.pathname === "/graphs" || window.location.pathname.substring(0, 12) === "/rosestemmhr" ? activeStyle : {}}
                                className={classes.navH2} to="/graphs">Graphs</Link>
                            <Link
                                style={window.location.pathname === "/nodes" || window.location.pathname.substring(0, 12) === "/nodeexample" ? activeStyle : {}}
                                className={classes.navH2} to="/nodes">Nodes</Link>
                        </Typography>
                        <div>
                            <Typography
                                aria-owns={open ? 'mouse-over-popover' : undefined}
                                aria-haspopup="true"
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                            >
                                <IconButton aria-label="show 4 new notifications">
                                    <Badge badgeContent={notifications.length} color="secondary">
                                        <Notifications htmlColor="#FFC700" fontSize="large"/>
                                    </Badge>
                                </IconButton>
                            </Typography>
                            <Popover
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                id="mouse-over-popover"
                                className={classes.popover}
                                classes={{
                                    paper: classes.paper,
                                }}
                                open={open}
                                anchorEl={anchorEl2}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                            >
                                <NotificationItems notifications={notifications}/>
                            </Popover>
                        </div>
                        <Link to="/settings">
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                            >
                                <Avatar
                                    className={classes.avatar}
                                    alt={user.getSignInUserSession().getIdToken().payload.name}
                                    src={user.getSignInUserSession().getIdToken().payload.picture || '/'}
                                />
                            </IconButton>
                        </Link>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            size="small"
                        >{Boolean(anchorEl) ? <ExpandLess/> : <ExpandMore/>}</IconButton>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                        >
                            <MoreVert/>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </div>
    );
}

PrimarySearchAppBar.defaultProps = {
    nodes: [],
    alerts: [],
};