import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, CircularProgress, Container, CssBaseline, Grid} from '@material-ui/core';
import {Apps, Delete, List, Star} from '@material-ui/icons';
import Chart from './components/Chart';
import ChartB from "./components/ChartB";
import Checkbox from './components/Checkbox'
import NavPrimary from './components/NavPrimary'
import Search from './components/Search'
import './index.css'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#FFF',
  },
  appBar: {
    color: '#000',
    backgroundColor: '#FFF',
    flexDirection: 'row',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: 'auto',
  },
  chart: {
    marginBottom: theme.spacing(10),
    maxWidth: 300,
    maxHeight: 400,
    padding: theme.spacing(2),
    paddingBottom: 200
  },
  chartB: {
    marginBottom: theme.spacing(10),
    width: '100%',
    maxHeight: 200,
    padding: theme.spacing(2),
  },
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4),
  },
  fixedHeight: {
    height: 650,
  },
  icon: {
    margin: theme.spacing(0, 1),
  },
  node: {
    display: 'flex',
    flexDirection: 'row',
  },
  search: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(5),
  },
  selectAll: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
  },
  widget: {
    backgroundColor: '#ECECEC',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
  },
  widgetRow: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: "space-between"
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  favoriteButton: {
    border: '1px solid #FFC700',
    borderRadius: 5,
    height: 35,
    padding: theme.spacing(2),
    marginRight: '20px',
  },
  deleteButton: {
    border: '1px solid #373737',
    borderRadius: 5,
    height: 35,
    padding: theme.spacing(2),
    marginLeft: '20px',
  },
}));

/**
 * Initializes and maintains the interface for the Graphs page.
 * @param props - props given by parent component
 */
export default function Graphs(props) {
  const classes = useStyles();

  const [filteredCharts, setFilteredCharts] = useState([]);
  const [showApps, setShowApps] = useState(true);
  const [selectedCharts, setSelectedCharts] = useState([]);

  /**
   * Initializes filtered charts whenever charts is updated
   */
  useEffect(() => {
    initFilteredCharts();
  }, [props.charts]);

  /**
   * Initializes filtered charts.
   */
  const initFilteredCharts = () => {
    setFilteredCharts(props.charts.sort((a) => {
      if (a['favorite']) {
        return -1;
      }
      return 1;
    }));
  };

  /**
   * Handles the select all box on change.
   * If select all is checked, selected charts contains all chart ids.
   * If not, selected charts is empty.
   * @param e - event
   */
  const handleSelectAllOnChange = (e) => {
    if (e.target.checked) {
      props.charts.map((chart) => setSelectedCharts(selectedCharts => ([...selectedCharts, chart['gid']])));
    } else {
      setSelectedCharts([]);
    }
  };

  /**
   * Handles the favorite button on click. Set favorite status of selected charts to true.
   */
  const handleFavoriteOnClick = () => {
    setFilteredCharts(filteredCharts => (filteredCharts.map((temp) => selectedCharts.includes(temp['gid']) ? {
      ...temp,
      favorite: true
    } : temp)));
    props.setCharts(props.charts.map((temp) => selectedCharts.includes(temp['gid']) ? {
      ...temp,
      favorite: true
    } : temp));
    props.fetchEditPersonalization(JSON.stringify([...props.charts]));
    setSelectedCharts([]);
  };

  /**
   * Handles the delete button on click. Deletes all selected charts.
   */
  const handleDeleteOnClick = () => {
    setFilteredCharts(filteredCharts.filter((temp) => !selectedCharts.includes(temp['gid'])));
    props.setCharts(props.charts.filter((temp) => !selectedCharts.includes(temp['gid'])));
    props.fetchEditPersonalization(JSON.stringify(props.charts.filter((temp) => !selectedCharts.includes(temp['gid']))));
    setSelectedCharts([]);
  };

  return (
    <Grid className={classes.root}>
      <CssBaseline/>
      <NavPrimary/>
      {props.loading === 0 && <main className={classes.content}>
        <div className={classes.appBarSpacer}/>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.search}>
                <Search
                  nodes={props.nodes}
                  charts={props.charts}
                  setCharts={props.setCharts}
                  sapflows={props.sapflows}
                  setSapflows={props.setSapflows}
                  filteredCharts={filteredCharts}
                  setFilteredCharts={setFilteredCharts}
                  fetchEditPersonalization={props.fetchEditPersonalization}
                  fetchSapflows={props.fetchSapflows}
                />
                <Apps htmlColor={showApps ? "#000000" : "#818181"} className={classes.icon}
                      fontSize="large" onClick={() => setShowApps(true)}/>
                <List htmlColor={showApps ? "#818181" : "#000000"} className={classes.icon}
                      fontSize="large" onClick={() => setShowApps(false)}/>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={classes.selectAll}>
                <Checkbox onChange={handleSelectAllOnChange}/>
                <p className="body2">Select All</p>
              </div>
            </Grid>
            <Grid item xs={10}>
              <div className={classes.buttonRow}>
                <Button
                  className={classes.favoriteButton}
                  elevation={0}
                  startIcon={<Star htmlColor="#FFC700" fontSize="small"/>}
                  onClick={handleFavoriteOnClick}
                  disabled={!selectedCharts.length}
                >
                  <p className="body3">Favorite</p>
                </Button>
                <Button
                  className={classes.deleteButton}
                  elevation={0}
                  startIcon={<Delete htmlColor="#878686" fontSize="small"/>}
                  onClick={handleDeleteOnClick}
                  disabled={!selectedCharts.length}
                >
                  <p className="body3">Delete</p>
                </Button>
              </div>
            </Grid>
          </Grid>
          {showApps ?
            <Grid container spacing={3}>
              {filteredCharts.map((chart) => (
                  <div key={chart['gid']} className={classes.widgetRow}>
                    <div className={classes.chart}>
                      <Chart
                        chart={chart}
                        nodes={props.nodes}
                        charts={props.charts}
                        setCharts={props.setCharts}
                        sapflows={props.sapflows}
                        filteredCharts={filteredCharts}
                        setFilteredCharts={setFilteredCharts}
                        selectedCharts={selectedCharts}
                        setSelectedCharts={setSelectedCharts}
                        fetchEditPersonalization={props.fetchEditPersonalization}
                      />
                    </div>
                  </div>
                )
              )}
            </Grid> :
            <div>
              {filteredCharts.map((chart) => (
                  <div key={chart['gid']} className={classes.widgetRow}>
                    <div className={classes.chartB}>
                      <ChartB
                        chart={chart}
                        nodes={props.nodes}
                        charts={props.charts}
                        setCharts={props.setCharts}
                        sapflows={props.sapflows}
                        filteredCharts={filteredCharts}
                        setFilteredCharts={setFilteredCharts}
                        selectedCharts={selectedCharts}
                        setSelectedCharts={setSelectedCharts}
                        fetchEditPersonalization={props.fetchEditPersonalization}
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          }
        </Container>
      </main>}
      {props.loading > 0 &&
      <CircularProgress style={{position: "absolute", top: "50%", left: "50%", color: "#61A487"}}/>}
    </Grid>
  );
}
