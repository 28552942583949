import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {CircularProgress, Container, CssBaseline, Paper} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import LastHeard from './components/LastHeard';
import NodeInfo from './components/NodeInfo';
import NavPrimary from './components/NavPrimary';
import Summary from './components/Summary';
import LastViewed from './components/LastViewed';
import './index.css';
import {ResizableBox} from 'react-resizable';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#FFF',
  },
  addWidget: {
    alignItems: 'center',
    backgroundColor: '#ECECEC',
    display: 'flex',
    height: 120,
    justifyContent: 'center',
    margin: 20
  },
  appBar: {
    color: '#000',
    backgroundColor: '#FFF',
    flexDirection: 'row',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: 'auto',
    // overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4),
  },
  widget: {
    backgroundColor: '#ECECEC',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    margin: 10
  },
  flex: {
    display: 'flex',
  }
}));

/**
 * Initializes and maintains the interface for the Dashboard page.
 * @param props - props given by parent component
 */
export default function Dashboard(props) {
  const classes = useStyles();

  const [isResize, setResize] = React.useState(false);

  const [summaryWidth, setSummaryWidth] = React.useState(1125);
  const [summaryHeight, setSummaryHeight] = React.useState(150);

  const [nodeInfoWidth, setNodeInfoWidth] = React.useState(825);
  const [nodeInfoHeight, setNodeInfoHeight] = React.useState(300);

  const [lastHeardWidth, setLastHeardWidth] = React.useState(387);
  const [lastHeardHeight, setLastHeardHeight] = React.useState(300);

  const [totalRowWidth, setTotalRowWidth] = React.useState(1225);

  const [lastViewedWidth, setLastViewedWidth] = React.useState(1225);
  const [lastViewedHeight, setLastViewedHeight] = React.useState(650);

  /**
   * Handles the resize of the summary container
   * @param event - event
   * @param size - updated size of container
   */
  const onResizeSummary = (event, {size}) => {
    setSummaryWidth(size.width);
    setSummaryHeight(size.height);
  };

  /**
   * Handles the resize of the node info container
   * @param event - event
   * @param size - updated size of container
   */
  const onResizeNodeInfo = (event, {size}) => {
    setNodeInfoWidth(size.width);
    setLastHeardWidth(totalRowWidth - size.width);
    setNodeInfoHeight(size.height);
    setLastHeardHeight(size.height);
  };

  /**
   * Handles the resize of the last heard container
   * @param event - event
   * @param size - updated size of container
   */
  const onResizeLastHeard = (event, {size}) => {
    setLastHeardWidth(size.width);
    setLastHeardHeight(size.height);
    setNodeInfoHeight(size.height);
    setTotalRowWidth(nodeInfoWidth + size.width);
  };

  /**
   * Handles the resize of the last viewed container
   * @param event - event
   * @param size - updated size of container
   */
  const onResizeLastViewed = (event, {size}) => {
    setLastViewedWidth(size.width);
    setLastViewedHeight(size.height);
  };

  return (
    <div className={classes.root}>
      <CssBaseline/>
      <NavPrimary/>
      {props.loading === 0 && <main className={classes.content}>
        <div className={classes.appBarSpacer}/>
        <Container maxWidth="lg" className={classes.container}>
          <div>
            {/* Add Widget */}
            <div className={classes.flex}>
              <Paper className={classes.addWidget} style={{width: 70}} elevation={0}>
                <MenuIcon color="disabled" fontSize="large"/>
              </Paper>
              {isResize ?
                <ResizableBox
                  width={summaryWidth}
                  height={summaryHeight}
                  minConstraints={[900, 150]}
                  maxConstraints={[1150, 300]}
                  onResize={onResizeSummary}
                  className={classes.widget}
                >
                  <Summary nodes={props.nodes} isResize={isResize} setResize={setResize}/>
                </ResizableBox> :
                <Paper className={classes.widget} style={{width: summaryWidth, height: summaryHeight}}
                       elevation={0}>
                  <Summary nodes={props.nodes} isResize={isResize} setResize={setResize}/>
                </Paper>
              }
            </div>
            <div className={classes.flex}>
              {/*Node Info*/}
              {
                isResize ?
                  <ResizableBox
                    width={nodeInfoWidth}
                    height={nodeInfoHeight}
                    minConstraints={[300, 300]}
                    maxConstraints={[825, 500]}
                    onResize={onResizeNodeInfo}
                    className={classes.widget}
                  >
                    <NodeInfo nodes={props.nodes} nodeFavorites={props.nodeFavorites}
                              setNodeFavorites={props.setNodeFavorites} metrics={props.metrics}/>
                  </ResizableBox> :
                  <Paper className={classes.widget}
                         style={{width: nodeInfoWidth, height: nodeInfoHeight}} elevation={0}>
                    <NodeInfo nodes={props.nodes} nodeFavorites={props.nodeFavorites}
                              setNodeFavorites={props.setNodeFavorites} metrics={props.metrics}/>
                  </Paper>
              }
              {/*Last Heard*/}
              {
                isResize ?
                  <ResizableBox
                    width={lastHeardWidth}
                    height={lastHeardHeight}
                    minConstraints={[300, 300]}
                    maxConstraints={[825, 500]}
                    onResize={onResizeLastHeard}
                    className={classes.widget}
                  >
                    <LastHeard nodes={props.nodes}/>
                  </ResizableBox> :
                  <Paper className={classes.widget}
                         style={{width: lastHeardWidth, height: lastHeardHeight}} elevation={0}>
                    <LastHeard nodes={props.nodes}/>
                  </Paper>
              }
            </div>
            {/* Last Viewed */}
            <div className={classes.flex}>
              {
                isResize ?
                  <ResizableBox
                    width={lastViewedWidth}
                    height={lastViewedHeight}
                    minConstraints={[925, 400]}
                    maxConstraints={[1225, 650]}
                    onResize={onResizeLastViewed}
                    className={classes.widget}>
                    <LastViewed
                      nodes={props.nodes}
                      charts={props.charts}
                      setCharts={props.setCharts}
                      sapflows={props.sapflows}
                      setSapflows={props.setSapflows}
                      fetchEditPersonalization={props.fetchEditPersonalization}/>
                  </ResizableBox> :
                  <Paper className={classes.widget}
                         style={{width: lastViewedWidth, height: lastViewedHeight}} elevation={0}>
                    <LastViewed
                      nodes={props.nodes}
                      charts={props.charts}
                      setCharts={props.setCharts}
                      sapflows={props.sapflows}
                      setSapflows={props.setSapflows}
                      fetchEditPersonalization={props.fetchEditPersonalization}/>
                  </Paper>
              }
            </div>
          </div>
        </Container>
      </main>
      }
      {props.loading > 0 &&
      <CircularProgress style={{position: "absolute", top: "50%", left: "50%", color: "#61A487"}}/>}
    </div>
  );
}
