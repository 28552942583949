import React, {useEffect, useState} from 'react';
import XYFrame from "semiotic/lib/XYFrame";
import {curveCatmullRom} from "d3-shape";
import {scaleTime} from "d3-scale";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import '../index.css';

const useStyles = makeStyles((theme) => ({
    relative: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(5),
        position: 'relative',
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        height: 650,
        width: 650
    }
}));

const attrNameToString = {
    'meanHeatRatio' : 'Mean Heat Ratio',
    'meanSoilMoisture' : 'Mean Soil Moisture',
    'meanOutsideTemp' : 'Mean Outside Temperature',
    'meanOutsideHumidity' : 'Mean Outside Humidity'
};

export default function AnnotateGraph(props) {
    const classes = useStyles();

    const [lines, setLines] = useState([]);
    const [annotations, setAnnotations] = useState([]);

    useEffect( () => {
        getLines();
        addAnnotations();
    }, [props.charts, props.rawData, props.annotations]);

    const getLines = () => {
        setLines([]);
        let result = [];
        props.chart['lines'].map((line) => {
            result.push({
                title: line['name'],
                coordinates: props.rawData.filter((data) => data["customerId#nodeId"].substring(2) === line['name']).map((data) => (
                    {
                        timestamp : data['timestamp'],
                        [props.chart['attribute']] : props.chart['inverse'] && data[props.chart['attribute']] !== 0 ? 1 / data[props.chart['attribute']] : data[props.chart['attribute']]
                    }))
            })
        });
        setLines(result);
    };

    const addAnnotations = (annotations) => {
        setAnnotations(props.annotations.filter((annotation) => annotation['gid'] === props.chart['gid']).map((annotation) => {
            switch (annotation['annotationType']) {
                case "x":
                    return {
                        type: annotation['annotationType'],
                        timestamp: annotation['fromTimestamp'],
                        note: {
                            label: annotation['label'],
                            align: "middle",
                            lineType: null,
                            wrap: 100
                        },
                        color: annotation['color'],
                        dx: annotation['dx'],
                        dy: annotation['dy'],
                        connector: { end: "none" }
                    };
                case "bounds":
                    return {
                        type: annotation['annotationType'],
                        bounds: [{ timestamp: annotation['fromTimestamp'] }, { timestamp: annotation['toTimestamp'] }],
                        label: annotation['label'],
                        dx: annotation['dx'],
                        dy: annotation['dy'],
                        color: annotation['color']
                    };
            }
        }));
    };

    const frameProps = {
        /* --- Data --- */
        lines: lines,
        /* --- Line --- */
        lineStyle: (d, i) => {
            return({
            stroke: props.chart['lines'][i] !== undefined? props.chart['lines'][i]['stroke'] : undefined,
            strokeWidth: 2,
            fill: "none"
        })},
        lineType: {type: "line", interpolator: curveCatmullRom},
        /* --- XY Coordinates --- */
        xScaleType: scaleTime(),
        xAccessor: "timestamp",
        yAccessor: props.chart['attribute'],
        yExtent: [props.chart['yMin'] !== undefined? props.chart['yMin'] : undefined, props.chart['yMax'] !== undefined? props.chart['yMax'] : undefined],
        xExtent:[props.chart['xMin'] !== undefined? props.chart['xMin']: undefined, props.chart['xMax'] !== undefined? props.chart['xMax']: undefined],
        axes: [{orient: "left", label: attrNameToString[props.chart['attribute']]},
            {
                orient: "bottom", tickFormat: function (unixTime) {
                    let date = new Date(unixTime * 1000);
                    let hours = "0" + date.getHours();
                    let minutes = "0" + date.getMinutes();
                    return (date.getMonth() + 1) + '/' + date.getDate() + '\n' + hours.substr(-2) + ':' + minutes.substr(-2);
                }, label: "Date and Time", ticks: 5
            }],
        /* --- Interact --- */
        hoverAnnotation: true,
        /* --- Annotate --- */
        tooltipContent: d => (
            <div className="tooltip-content">
                <p className="body4"><strong>Time:</strong> {(new Date(d.timestamp * 1000)).toString()}</p>
                <p className="body4"><strong>{attrNameToString[props.chart['attribute']]}</strong> {d[props.chart['attribute']]}</p>
            </div>
        ),

        annotations: annotations,
        /* --- Other Style --- */
        margin: {left: 80, bottom: 90, right: 0, top: 20},
        size: [900, 700]
    };

    return (
        <div className={classes.relative}>
            <XYFrame {...frameProps} />
        </div>
    )
}
