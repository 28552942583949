import React from 'react';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { makeStyles, lighten } from '@material-ui/core/styles';
import '../index.css'
import { Link } from "react-router-dom";

const HISTORY_LENGTH = 8; // number of days displayed in message history (<= 8)
const DARKEST = 100; // threshold for darkest color for message history

const useStyles = makeStyles((theme) => ({
  disabledIcon: {
    color: '#ECECEC',
    margin: theme.spacing(1),
  },
  iconStar: {
    color: '#FFC700',
    margin: theme.spacing(1),
  },
  widgetBorder: {
    border: '1pt solid #61A487',
    borderRadius: 5,
    marginBottom: '5pt',
    padding: theme.spacing(3),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  widgetColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  widgetLabel: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
  },
  widgetMargin: {
    marginRight: theme.spacing(2),
  },
  widgetPadding: {
    marginRight: theme.spacing(1),
  },
  widgetRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(1),
  },
  messageHistoryContainer: {
    height: "1rem",
    width: `${HISTORY_LENGTH}rem`,
    margin: 0, padding: 0, display: "flex",
  },
  messageHistoryBox: {
    height: "1rem",
    width: "1rem",
    margin: 0,
    padding: 0,
    marginLeft: -1,
    border: '1px solid black'
  }
}));

export default function NodeInfo(props) {
  const classes = useStyles();

  const calculateSleepTime = () => {
    let day = Math.floor((Date.now() - new Date(props.node.lastHeardTimestamp)) / (1000 * 60 * 60 * 24));
    let hour = Math.floor((Date.now() - new Date(props.node.lastHeardTimestamp)) / (1000 * 60 * 60)) % 24;
    let min = Math.floor((Date.now() - new Date(props.node.lastHeardTimestamp)) / (1000 * 60)) % 60;
    return day + "d " + hour + "h " + min + "m"
  };

  const handleFavoriteClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (props.nodeFavorites.includes(props.node)) {
      props.setNodeFavorites(props.nodeFavorites.filter(node => node !== props.node));
    } else {
      props.setNodeFavorites([...props.nodeFavorites, props.node]);
    }
  };

  const dateDiff = (d1, d2) => {
    return Math.floor((d1 - d2) / (1000 * 60 * 60 * 24));
  };

  const getMessageHistory = () => {
    let history = new Array(HISTORY_LENGTH);

    if (props.metrics.length > 0) {
      console.log(props);
      for (let i = props.metrics.length - HISTORY_LENGTH; i < props.metrics.length; i++) {
        history[i] = props.metrics[i][props.node.nodeId] || 0;
      }
    } else {
      history = new Array(1);
    }

    return history;
  };

  return (
    <Link key={props.node.nodeId} to={{ pathname: "/nodeexample", search: `id=${props.node.nodeId}` }} style={{ color: '#000' }}>
      <React.Fragment>
        {/* Node #1 */}
        <div className={classes.widgetBorder}>
          {props.nodeFavorites.includes(props.node)
            ? <StarIcon className={classes.iconStar} onClick={handleFavoriteClick} />
            : <StarBorderIcon className={classes.iconStar} onClick={handleFavoriteClick} />
          }
          {/* Column 1 */}
          <div className={classes.widgetColumn}>
            <div className={classes.widgetRow}>
              <h4 className={classes.widgetPadding}>Node ID</h4>
              <p className="body4">{props.node.nodeId}</p>
            </div>
            <div className={classes.widgetRow}>
              <h4 className={classes.widgetPadding}>Node Location</h4>
              <p className="body4">{props.node.nodeLocation}</p>
            </div>
            <div className={classes.widgetRow}>
              <h4 className={classes.widgetPadding}>Plant Location</h4>
              <p className="body4">{props.node.plantLocation}</p>
            </div>
          </div>
          {/* Column 2 */}
          <div className={classes.widgetColumn}>
            <div className={classes.widgetRow}>
              <h4 className={classes.widgetPadding}>Registration Time</h4>
              <p className="body4">1234-56-78</p>
            </div>
            <div className={classes.widgetRow}>
              <h4 className={classes.widgetPadding}>Status</h4>
              <p className="body4">{
                (Date.now() - new Date(props.node.lastHeardTimestamp)) < 1000 * 60 * 60 * 3 ? "Active" : "Inactive"}</p>
            </div>
            <div className={classes.widgetRow}>
              <h4 className={classes.widgetPadding}>Plant Type</h4>
              <p className="body4">{props.node.plant}</p>
            </div>
          </div>
          {/* Column 3 */}
          <div className={classes.widgetColumn}>
            <div className={classes.widgetRow}>
              <h4 className={classes.widgetPadding}>Message History</h4>
              {/*<p className="body4">{props.node['lastHeardValue'] ? (props.node['lastHeardValue']['heatPulseDataUsed'].length) : 0}</p>*/}
              <div className={classes.messageHistoryContainer} style={{ height: "1rem", width: "5rem", margin: 0, padding: 0, display: "flex", }} >
                {getMessageHistory().map((i, index) =>
                  <div key={index} className={classes.messageHistoryBox} style={{ backgroundColor: lighten('#61A487', 1 - (Math.min(1, i / DARKEST))) }} />
                )}
              </div>
            </div>
            <div className={classes.widgetRow}>
              <h4 className={classes.widgetPadding}>Heat Pulse Duration</h4>
              <p className="body4">{props.node['lastReceivedShadow'] ? props.node['lastReceivedShadow']['heatPulseDuration'] : ''}</p>
            </div>
            <div className={classes.widgetRow}>
              <h4 className={classes.widgetPadding}>Sleep Interval</h4>
              <p className="body4">{calculateSleepTime()}</p>
            </div>
          </div>
        </div>
      </React.Fragment>
    </Link>
  );
}

NodeInfo.defaultProps = {
  nodeFavorites: [],
  setNodeFavorites: () => { }
}