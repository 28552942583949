import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import XYFrame from "semiotic/lib/XYFrame"

const useStyles = makeStyles((theme) => ({
  relative: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(5),
    position: 'relative',
  },
}));

export default function RegressionGraph(props) {
  const classes = useStyles();
  const [points, setPoints] = useState([]);

  useEffect(() => {
    getPoints();
  }, [props.charts, props.rawData]);

  function getPoints() {
    let result = [];
    props.chart['lines'].map((line) => {
      props.rawData.filter((data) => data["customerId#nodeId"].substring(2) === line['nodeId']).map((data) => (
          result.push({timestamp: data['timestamp'], [line['nodeId']]: data[props.chart['attribute']]})))
    });
    setPoints(result);
  }

  const frameProps = {
    points: points,
    size: [900, 700],
    margin: { left: 60, bottom: 90, right: 10, top: 40 },
    xAccessor: props.chart['nodeIds'][0],
    yAccessor: props.chart['nodeIds'][1],
    pointStyle: d => {
      console.log(d);
      return {
        r: 5,
        fill: "61A487" }
    },
    axes: [{ orient: "left", label: props.chart['nodeIds'][0] },
      { orient: "bottom", label: { name: props.chart['nodeIds'][1], locationDistance: 55 } }]
  };

  return (
    <div className={classes.relative}>
      <XYFrame {...frameProps} />
    </div>
  )
}
