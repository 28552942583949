import React, {useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Chip, FormControl, Grid, Input, MenuItem, Select} from '@material-ui/core';
import Button from "./Button";
import {stringToAttributeJSON} from "./GlobalFunctions"

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '26ch',
    },
  },
  graphTitle: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginRight: theme.spacing(3),
  },
  input: {
    height: 20,
    margin: theme.spacing(0, 1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    '& > *': {
      width: '26ch',
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    marginRight: 2,
    height: '19px',
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  space: {
    marginTop: theme.spacing(4),
  },
}));

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/**
 * Gets style of field depending on its data type.
 * @param name - name of selection
 * @param fieldName - items in field
 * @param theme - theme of component
 * @returns {{fontWeight: (*)}}
 */
function getStyles(name, fieldName, theme) {
  return {
    fontWeight:
      fieldName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

/**
 * Initializes and maintains the add by node form in add graph.
 * @param props - props from the parent component
 */
export default function AddByNode(props) {
  const classes = useStyles();

  const theme = useTheme();
  const [filteredNodes, setFilteredNodes] = React.useState(props.nodes);
  const [graphTitle, setGraphTitle] = React.useState('');
  const [attributeField, setAttributeField] = React.useState('');
  const [nodeLocationField, setNodeLocationField] = React.useState('all');
  const [plantTypeField, setPlantTypeField] = React.useState('all');
  const [plantLocationField, setPlantLocationField] = React.useState('all');
  const [nodeField, setNodeField] = React.useState([]);
  const [nodeLocationOptions, setNodeLocationOptions] = React.useState([]);
  const [plantTypeOptions, setPlantTypeOptions] = React.useState([]);
  const [plantLocationOptions, setPlantLocationOptions] = React.useState([]);

  /**
   * Filters node location, plant location, and plant type property options in the form.
   */
  const filter = () => {
    let temp = props.nodes.filter((node) => {
      let result = true;
      if (nodeLocationField !== 'all') {
        result = result && node['nodeLocation'] === nodeLocationField;
      }
      if (plantLocationField !== 'all') {
        result = result && node['plantLocation'] === plantLocationField;
      }
      if (plantTypeField !== 'all') {
        result = result && node['plant'] === plantTypeField;
      }
      return result;
    });
    let nodeLocSet = new Set();
    let plantTypeSet = new Set();
    let plantLocSet = new Set();
    nodeLocSet.add('all');
    plantTypeSet.add('all');
    plantLocSet.add('all');
    temp.map((node) => {
        if (node['nodeLocation']) {
          nodeLocSet.add(node['nodeLocation']);
        }
        if (node['plant']) {
          plantTypeSet.add(node['plant']);
        }
        if (node['plantLocation']) {
          plantLocSet.add(node['plantLocation']);
        }
      }
    );
    setNodeLocationOptions(Array.from(nodeLocSet));
    setPlantTypeOptions(Array.from(plantTypeSet));
    setPlantLocationOptions(Array.from(plantLocSet));
    setFilteredNodes(temp);
  };

  /**
   * Updates the filtered nodes whenever a field is changed.
   */
  useEffect(() => {
    filter();
  }, [nodeLocationField, plantTypeField, plantLocationField]);

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <p>Compare a single attribute of nodes with the same properties.</p>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <div className={classes.graphTitle}>
            <p className="body4"><br/><strong>Graph Title </strong></p>
          </div>
          <div className={classes.graphTitle}>
            <form className={classes.root} style={{}} noValidate autoComplete="off">
              <Input inputProps={{'aria-label': 'description'}} onChange={(e) => setGraphTitle(e.target.value)}/>
            </form>
          </div>
        </Grid>
        <Grid item xs={6}>
        </Grid>
      </Grid>
      <div className={classes.space}/>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <div className={classes.graphTitle}>
            <p className="body4"><strong>Attributes</strong></p>
          </div>
          <div className={classes.graphTitle}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="select attribute"
                id="select attribute"
                value={attributeField}
                onChange={(e) => setAttributeField(e.target.value)}
                MenuProps={MenuProps}
                className={classes.selectEmpty}
              >
                {Object.keys(stringToAttributeJSON).map((name) => (
                  <MenuItem key={name} value={stringToAttributeJSON[name]}
                            style={getStyles(name, attributeField, theme)}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.space}/>
          <div className={classes.graphTitle}>
            <p className="body4"><strong>Node Location</strong></p>
          </div>
          <div className={classes.graphTitle}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="select node location"
                id="select node location"
                value={nodeLocationField}
                onChange={(e) => setNodeLocationField(e.target.value)}
                MenuProps={MenuProps}
                className={classes.selectEmpty}
              >
                {nodeLocationOptions.map((name) => (
                  <MenuItem key={name} value={name} style={getStyles(name, nodeLocationField, theme)}>
                    {name}
                  </MenuItem>)
                )}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.graphTitle}>
            <p className="body4"><strong>Plant Type</strong></p>
          </div>
          <div className={classes.graphTitle}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="select plant type"
                id="select plant type"
                value={plantTypeField}
                onChange={(e) => setPlantTypeField(e.target.value)}
                MenuProps={MenuProps}
                className={classes.selectEmpty}
              >
                {plantTypeOptions.map((name) => (
                  <MenuItem key={name} value={name} style={getStyles(name, plantTypeField, theme)}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.space}/>
          <div className={classes.graphTitle}>
            <p className="body4"><strong>Plant Location</strong></p>
          </div>
          <div className={classes.graphTitle}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="select plant type"
                id="select plant type"
                value={plantLocationField}
                onChange={(e) => setPlantLocationField(e.target.value)}
                MenuProps={MenuProps}
                className={classes.selectEmpty}
              >
                {plantLocationOptions.map((name) => (
                  <MenuItem key={name} value={name} style={getStyles(name, plantLocationField, theme)}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Grid>
      </Grid>
      <div className={classes.space}/>
      <div className={classes.graphTitle}>
        <p className="body4"><strong>Nodes to Choose From</strong></p>
      </div>
      <div className={classes.graphTitle}>
        <FormControl className={classes.formControl}>
          <Select
            labelId="select node"
            id="select node"
            multiple
            value={nodeField}
            onChange={(e) => setNodeField(e.target.value)}
            input={<Input id="select node options"/>}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value} label={value} className={classes.chip}/>
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {filteredNodes.map((node) => (
              <MenuItem key={node['nodeId']} value={node['nodeId']} style={getStyles(node['nodeId'], nodeField, theme)}>
                {node['nodeId']}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={classes.buttonRow}>
        <Button color="secondary"
                onClick={() => props.handleSubmit(graphTitle, nodeField, attributeField)}>Create</Button>
      </div>
    </React.Fragment>
  );
}
