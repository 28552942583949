import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Backdrop, Fade, FormControl, FormControlLabel, Modal, Radio, RadioGroup} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddByNode from './components/AddByNode';
import AddByProperty from './components/AddByProperty';
import {dateToString, getDarkColor} from "./components/GlobalFunctions";
import './index.css';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: 650,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  space: {
    marginTop: theme.spacing(2),
  },
}));

const oneDay = 1000 * 60 * 60 * 24;

/**
 * Initializes the to date that will be queried to the api.
 * @param maxDate - the latest last heard date of all selected nodes
 * @returns {string} - to date string
 */
function createToDate() {
  let temp = new Date();
  temp.setTime(Date.now());
  return dateToString(temp);
}

/**
 * Initializes the from date that will be queried to the api.
 * @param maxDate - the latest last heard date of all selected nodes
 * @returns {string} - from date string
 */
function createFromDate() {
  let temp = new Date();
  temp.setTime(Date.now() - oneDay);
  return dateToString(temp);
}

/**
 * Initializes and maintains the interface for the Add Graph form.
 * @param props - props from parent component
 */
export default function AddGraph(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [showByNode, setShowByNode] = React.useState(true);

  /**
   * Handles the modal on open.
   */
  const handleOpen = () => {
    setOpen(true);
  };

  /**
   * Handles the modal on close.
   */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Generates a new graph id whenever a new graph is created.
   * @returns {number} - new graph id
   */
  const generateId = () => {
    if (props.charts.length > 0) {
      let greatestGid = 0;
      props.charts.map((chart) => {
        if (chart['gid'] > greatestGid) {
          greatestGid = chart['gid']
        }
      });
      return greatestGid + 1;
    }
    return 0;
  };

  /**
   * Handles the submit of the add graph form.
   * @param graphTitle - graph title
   * @param nodes - selected node ids
   * @param attribute - attribute
   */
  const handleSubmit = (graphTitle, nodes, attribute) => {
    if (graphTitle.length !== 0 && nodes.length !== 0 && attribute.length !== 0) {
      let date = new Date();
      let lines = [];
      nodes.map((node) => {
        lines.push(
          {
            name: node,
            stroke: getDarkColor()
          }
        )
      });
      let gid = generateId();
      let data = {
        gid: gid,
        graphTitle: graphTitle,
        lines: lines,
        nodeIds: nodes,
        attribute: attribute,
        timestamp: date.getTime(),
        favorite: false,
        inverse: false,
        toDate: createToDate(),
        fromDate: createFromDate(),
        editHistory: ["Created on " + date.toString().slice(4, 21)],
        tags: []
      };
      props.sapflows[gid] = [];
      nodes.map((nodeId) => {
        props.fetchSapflows(data, nodeId);
      });
      props.fetchEditPersonalization([...props.charts, data]);
      handleClose();
    }
  };

  return (
    <div>
      <AddCircleOutlineIcon
        color="disabled"
        className={classes.widgetPadding}
        onClick={handleOpen}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.space}/>
            <h2 id="transition-modal-title">
              Add a Graph
            </h2>
            <div id="transition-modal-description">
              <FormControl component="fieldset">
                <RadioGroup style={{flexDirection: "row"}} aria-label="radio group" name="radio group"
                            defaultValue={showByNode ? "byNode" : "byProperty"} onChange={() => {
                  setShowByNode(!showByNode);
                }}>
                  <FormControlLabel value="byNode" control={<Radio/>} label="By Node"/>
                  <FormControlLabel value="byProperty" control={<Radio/>} label="By Property"/>
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{display: (showByNode ? 'block' : 'none')}}>
              <AddByNode
                handleClose={handleClose}
                nodes={props.nodes}
                charts={props.charts}
                setCharts={props.setCharts}
                sapflows={props.sapflows}
                setSapflows={props.setSapflows}
                fetchSapflows={props.fetchSapflows}
                fetchEditPersonalization={props.fetchEditPersonalization}
                handleSubmit={handleSubmit}
              />
            </div>
            <div style={{display: (showByNode ? 'none' : 'block')}}>
              <AddByProperty
                handleClose={handleClose}
                nodes={props.nodes}
                charts={props.charts}
                setCharts={props.setCharts}
                sapflows={props.sapflows}
                setSapflows={props.setSapflows}
                fetchSapflows={props.fetchSapflows}
                fetchEditPersonalization={props.fetchEditPersonalization}
                handleSubmit={handleSubmit}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
