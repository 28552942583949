import React from 'react';
import Button from './components/Button';
import Input from './components/Input';
import Password from './components/Password';
import './Login.css';

function LoginDesktop() {
  return (
    <div className="LoginDesktop">
      <header className="Login-header">
        <img src={require('./img/largeLogo.png')} alt="logo"/>
      </header>
      <div className="Login-toggle">
        <h1>Log in</h1>
        <h1><a href="" className="link-faded">Sign up</a></h1>
      </div>
      <form className="Login-form" noValidate autoComplete="off">
        <Input></Input>
        <Password></Password>
      </form>
      <div className="Login-confirmation-desktop">
        <Button>
          <a>Log in</a>
        </Button>
        <div className="center">
          <a href="" className="body2 link-underline">Forgot password?</a>
        </div>
      </div>
      <div className="Login-3rd-party">
        <a href="">
          <img src={require('./img/Google.png')} alt="login with Google"/>
        </a>
        <a href="">
          <img src={require('./img/Microsoft.png')} alt="login with Microsoft"/>
        </a>
      </div>
    </div>
  );
}

export default LoginDesktop;
