import React, {useCallback} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
import {Link, useLocation} from 'react-router-dom';
import InputBase from '@material-ui/core/InputBase';
import FavoriteNodeFilter from './FavoriteNodeFilter';
import NodeFilter from './NodeFilter';
import '../index.css';
import debounce from "./UseDebounce";

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
  },
  formControl: {
    backgroundColor: '#97D89A',
    borderRadius: 5,
    padding: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  pageNavigation: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    width: '100%',
  },
  inputInput: {
    color: '#878686',
    display: 'flex',
    fontFamily: 'Nunito',
    fontSize: 14,
    fontWeight: 400,
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 0,
      '&:focus': {
        border: '1px solid #878686',
        borderRadius: 17.5,
        width: '20ch',
      },
    },
  },
  search: {
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  widgetPadding: {
    marginRight: theme.spacing(2),
  },
  widgetRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(1),
  },
}));

export default function Search(props) {
  const classes = useStyles();
  const location = useLocation();
  const nodeId = new URLSearchParams(location.search).get('id');

  const debouncedSaveSearch = useCallback(debounce(nextValue => props.setSearch(nextValue), 300), []);

  return (
    <React.Fragment>
      <div className={classes.pageNavigation}>
        {/* Breadcrumbs */}
        <div className={classes.breadcrumbs}>
          <AddCircleOutlineIcon
            color="disabled"
            className={classes.widgetPadding}
          />
          <Link to={"/nodes"} style={{color: 'black'}}><h2>All Nodes </h2></Link>
          {location.search !== '' && <h2>{`\t/${nodeId}`}</h2>}
        </div>
        <div className={classes.grow} />
        {/* Search bar */}
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon color="disabled" />
          </div>
          <InputBase
              placeholder="Search by keywords"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={(e) => debouncedSaveSearch({...props.search, search: e.target.value})}
              disabled={location.search !== ''}
          />
          {/* Filters */}
          <FavoriteNodeFilter value={props.search.sort} onChange={(e) => props.setSearch({...props.search, sort: e.target.value})} disabled={location.search !== ''}/>
          <NodeFilter value={props.search.filter} onChange={(e) => props.setSearch({...props.search, filter: e.target.value})} disabled={location.search !== ''}/>
        </div>
      </div>
    </React.Fragment>
  );
}
