import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, CssBaseline, Container, Grid} from '@material-ui/core';
import {Delete, Star} from "@material-ui/icons";
import NavPrimary from './components/NavPrimary'
import SearchNode from './components/SearchNode'
import Checkbox from './components/Checkbox'
import SelectNode from './components/SelectNode'
import './index.css'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#FFF'
  },
  addWidget: {
    alignItems: 'center',
    backgroundColor: '#ECECEC',
    display: 'flex',
    height: 120,
    justifyContent: 'center'
  },
  appBar: {
    color: '#000',
    backgroundColor: '#FFF',
    flexDirection: 'row'
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: 'auto'
  },
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4)
  },
  node: {
    display: 'flex',
    flexDirection: 'row'
  },
  search: {
    marginTop: theme.spacing(5)
  },
  selectAll: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2)
  },
  widget: {
    backgroundColor: '#ECECEC',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: theme.spacing(3)
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  favoriteButton: {
    border: '1px solid #FFC700',
    borderRadius: 5,
    height: 35,
    padding: theme.spacing(2),
    marginRight: '20px'
  },
  deleteButton: {
    border: '1px solid #373737',
    borderRadius: 5,
    height: 35,
    padding: theme.spacing(2),
    marginLeft: '20px'
  },
}));

// TODO: map feature and add node feature

/**
 * Initializes and maintains the nodes page
 * @param props
 */
export default function Nodes(props) {
  const classes = useStyles();
  const [search, setSearch] = React.useState({
    search: '',
    sort: 'favorite',
    filter: 'all'
  });
  const [selected, setSelected] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [filteredNodes, setFilteredNodes] = React.useState([]);

  const sorts = {
    'favorite': (a, b) => {
      if (props.nodeFavorites.includes(a) === props.nodeFavorites.includes(b)) {
        return 0;
      } else {
        return props.nodeFavorites.includes(a) ? -1 : 1;
      }
    },
    'mostRecent': (a, b) => {
      return new Date(b['lastHeardTimestamp']) - new Date(a['lastHeardTimestamp']);
    },
    'leastRecent': (a, b) => {
      return new Date(a['lastHeardTimestamp']) - new Date(b['lastHeardTimestamp']);
    },
    'graphAZ': (a, b) => {
      return 0;
    },
    'graphZA': (a, b) => {
      return 0;
    }
  };

  const filters = {
    'all' : () => {
      return true;
    },
    'active' : (node) => {
      return (Date.now() - new Date(node['lastHeardTimestamp'])) < 1000 * 60 * 60 * 3;
    },
    'inactive' : (node) => {
      return (Date.now() - new Date(node['lastHeardTimestamp'])) >= 1000 * 60 * 60 * 3;
    }
  };

  /**
   * Updates filtered nodes when nodes or search is changed.
   */
  useEffect(() => {
    initFilteredNodes();
  }, [props.nodes, search]);

  /**
   * Initializes filtered nodes.
   */
  const initFilteredNodes = () => {
    setFilteredNodes(
        props.nodes
        .sort(sorts[search.sort])
        .filter(node => node['nodeId'].toLowerCase().includes(search.search.toLowerCase()))
        .filter(filters[search.filter])
    );
  };

  /**
   * Handles select feature of a given node.
   * @param node - selected node
   */
  const onNodeSelect = (node) => {
    setSelected(prevSelected => [...prevSelected, node]);
    setSelectAll(selected.length + 1 === filteredNodes.length);
  };

  /**
   * Handles the deselect feature of a given node.
   * @param node - node to deselect
   */
  const onNodeDeselect = (node) => {
    setSelected(selected.filter(item => item !== node));
    setSelectAll(false);
  };

  /**
   * Handles the select all nodes feature of the page.
   * @param e - event
   */
  const selectAllOnChange = (e) => {
    if (e.target.checked) {setSelected([...filteredNodes])
      setSelectAll(true);
      setSelected([...filteredNodes])
    } else {
      setSelectAll(false);
      setSelected([]);
    }
  };

  /**
   * Deselect all nodes when filter changes.
   */
  const onFilterChange = () => {
    setSelectAll(false);
    setSelected([]);
  };

  /**
   * Handles the on click function of the favorites button.
   */
  const onClickFavorite = () => {
    if (props.nodeFavorites.includes(selected[0])) {
      props.setNodeFavorites(props.nodeFavorites.filter(node => selected.indexOf(node) === -1));
    } else {
      props.setNodeFavorites(props.nodeFavorites.concat(selected.filter(node => props.nodeFavorites.indexOf(node) === -1)));
    }
    onFilterChange();
  };

  /**
   * Handles the on click function for the delete button.
   */
  const onClickDelete = () => {
    console.log("Delete", selected.map(node => node['nodeId']));
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <NavPrimary />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.search}>
              <SearchNode search={search} setSearch={setSearch}/>
            </Grid>
            <Grid item xs={2}>
              <div className={classes.selectAll}>
                <Checkbox onChange={selectAllOnChange} checked={selectAll}/>
                <p className="body2">Select All</p>
              </div>
            </Grid>
            <Grid item xs={10}>
              <div className={classes.buttonRow} style={selected.length ? {} : {display: 'none'}}>
                <Button
                  className={classes.favoriteButton}
                  elevation={0}
                  startIcon={<Star htmlColor="#FFC700" fontSize="small" />}
                  onClick={onClickFavorite}
                >
                  <p className="body3">Favorite</p>
                </Button>
                <Button
                  disabled
                  className={classes.deleteButton}
                  elevation={0}
                  startIcon={<Delete htmlColor="#878686" fontSize="small" />}
                  onClick={onClickDelete}
                >
                  <p className="body3">Delete</p>
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {filteredNodes.map(node =>
              <SelectNode
                key={node['nodeId']}
                node={node}
                nodeFavorites={props.nodeFavorites}
                setNodeFavorites={props.setNodeFavorites}
                onSelect={onNodeSelect}
                onDeselect={onNodeDeselect}
                checked={selected.includes(node)}
                metrics={props.metrics}
              />
            )}
          </Grid>
        </Container>
      </main>
    </div>
  );
}
