import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MapIcon from '@material-ui/icons/Map';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import GoogleMap from './GoogleMap'
import '../index.css'


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  tertiaryButton: {
    backgroundColor: '#61A487',
    borderRadius: 5,
    color: '#DEF3DF',
    height: 35,
    padding: theme.spacing(2),
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function CustomizedDialogs() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        className={classes.tertiaryButton}
        elevation={0}
        onClick={handleClickOpen}
        startIcon={<MapIcon htmlColor="#DEF3DF" fontSize="small" />}
        variant="contained">
        <p className="body3">Map</p>
      </Button>
      <Dialog
        aria-labelledby="customized-dialog-title"
        onClose={handleClose}
        fullScreen={true}
        open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <h2>Node location</h2>
        </DialogTitle>
        <DialogContent dividers>
          <GoogleMap />
        </DialogContent>
      </Dialog>
    </div>
  );
}
