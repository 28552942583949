import React, {useState, useRef, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {Input} from "@material-ui/core";
import Button from "./Button";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import StopIcon from "@material-ui/icons/Stop";
import {SketchPicker} from "react-color";
import {parseDate} from "./GlobalFunctions";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        width: 650,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        alignItems: "center",
        alignContent: "center"
    },
    space: {
        marginTop: theme.spacing(2),
    },
    buttonRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 50
    },
    input: {
        height: 20,
        margin: theme.spacing(0, 1),
    },
    formControl: {
        minWidth: 120,
        maxWidth: 300,
        '& > *': {
            width: '26ch',
        },
    },
    graphTitle: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        marginRight: theme.spacing(3),
    },
}));

const annotationTypes = [
    'x',
    'bounds'
];

export default function AddAnnotation(props) {
    const classes = useStyles();

    const inputRef = useRef({});
    const [annotationType, setAnnotationType] = useState("");
    const [fromTimestamp, setFromTimestamp] = useState(parseDate(props.chart['fromDate']));
    const [toTimestamp, setToTimestamp] = useState(parseDate(props.chart['toDate']));
    const [color, setColor] = useState("#61A487");

    const [colorPickerShow, setColorPickerShow] = React.useState(false);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function setRef(refName, element) {
        inputRef.current[refName] = element.target;
    }

    const handleClose = () => {
        props.setAnnoOpen(false);
    };

    const handleSubmit = () => {
        if (inputRef.current['label'] && inputRef.current['label'] !== '' && annotationType !== '' && inputRef.current['dx'] !== '' && inputRef.current['dy'] !== '') {
            let annotation = {
                gid : props.chart['gid'],
                label : inputRef.current['label'].value,
                annotationType : annotationType,
                dx : inputRef.current['dx'].value,
                dy : inputRef.current['dy'].value,
                fromTimestamp : fromTimestamp.getTime()/1000,
                toTimestamp : toTimestamp.getTime()/1000,
                color : color
            };
            props.fetchAddAnnotation(annotation);
            props.setAnnotations([...props.annotations, annotation]);
            inputRef.current={};
            props.setAnnoOpen(false);
        }
    };

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setColorPickerShow(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props.annoOpen}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.annoOpen}>
                    <div className={classes.paper}>
                        <div className={classes.space}/>
                        <h2 id="transition-modal-title">
                            Annotate
                        </h2>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <div className={classes.graphTitle}>
                                    <p className="body4"><br/><strong>Label</strong></p>
                                </div>
                                <div className={classes.graphTitle}>
                                    <form className={classes.root} style={{}} noValidate autoComplete="off">
                                        <Input inputProps={{ 'aria-label': 'description' }} onChange={(e) => setRef('label', e)}/>
                                    </form>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className={classes.graphTitle}>
                                    <p className="body4"><br/><strong>Annotation Type</strong></p>
                                </div>
                                <div className={classes.graphTitle}>
                                    <FormControl className={classes.formControl}>
                                        <Select
                                            labelId="select annotation type"
                                            id="select annotation type"
                                            className={classes.selectEmpty}
                                            onChange={(e) => setAnnotationType(e.target.value)}
                                        >
                                            {annotationTypes.map((type) => (
                                                <MenuItem key={type} value={type}>
                                                    {type}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                        </Grid>
                        <div className={classes.space}/>
                        {annotationType === "bounds"?
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <div className={classes.graphTitle}>
                                    <p className="body4"><br/><strong>From Date</strong></p>
                                </div>
                                <div className={classes.graphTitle}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDateTimePicker
                                            className={classes.rootDateAndTime}
                                            format="MM/dd/yy hh:mm"
                                            value={fromTimestamp}
                                            onChange={(date) => setFromTimestamp(date)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className={classes.graphTitle}>
                                    <p className="body4"><br/><strong>To Date</strong></p>
                                </div>
                                <div className={classes.graphTitle}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDateTimePicker
                                            className={classes.rootDateAndTime}
                                            format="MM/dd/yy hh:mm"
                                            value={toTimestamp}
                                            onChange={(date) => setToTimestamp(date)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Grid>
                        </Grid>:
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <div className={classes.graphTitle}>
                                        <p className="body4"><br/><strong>Date</strong></p>
                                    </div>
                                    <div className={classes.graphTitle}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDateTimePicker
                                                className={classes.rootDateAndTime}
                                                format="MM/dd/yy hh:mm"
                                                value={fromTimestamp}
                                                onChange={(date) => {setToTimestamp(date);
                                                                    setFromTimestamp(date);}}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </Grid>
                            </Grid>
                        }

                        <div className={classes.space}/>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <div className={classes.graphTitle}>
                                    <p className="body4"><br/><strong>Label Position x</strong></p>
                                </div>
                                <div className={classes.graphTitle}>
                                    <form className={classes.root} style={{}} noValidate autoComplete="off">
                                        <Input type="number" inputProps={{ 'aria-label': 'description' }} onChange={(e) => setRef('dx', e)}/>
                                    </form>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className={classes.graphTitle}>
                                    <p className="body4"><br/><strong>Label Position y</strong></p>
                                </div>
                                <div className={classes.graphTitle}>
                                    <form className={classes.root} style={{}} noValidate autoComplete="off">
                                        <Input type="number" inputProps={{ 'aria-label': 'description' }} onChange={(e) => setRef('dy', e)}/>
                                    </form>
                                </div>
                            </Grid>
                        </Grid>
                        <div className={classes.buttonRow}>
                            <div className={classes.graphTitle} style={{display: "flex", alignItems: "center"}}>
                                <p className="body4"><br/><strong>Color</strong></p>
                                <StopIcon htmlColor={color} style={{margin: '10 10 10 10'}} onClick={() => setColorPickerShow(true)}/>
                            </div>
                        </div>
                        <div className={classes.buttonRow}>
                            <div ref={wrapperRef} style={{position: "absolute", zIndex: 99}}>
                                {colorPickerShow? <SketchPicker color={color} onChange={(color) => setColor(color.hex)} disableAlpha={true}/>: null}
                            </div>
                        </div>
                        <div className={classes.buttonRow}>
                            <Button color="secondary" onClick={handleSubmit}>Create</Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
