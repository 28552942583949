import React, {useRef, useEffect, useState} from 'react'
import StopIcon from "@material-ui/icons/Stop";
import { SketchPicker } from 'react-color';

export default function GraphColorIcon(props) {

    const [color, setColor] = useState(props.line['stroke']);

    const [colorPickerShow, setColorPickerShow] = React.useState(false);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const handleColorPickerOnChangeComplete = async (color) => {
        props.line['stroke'] = color.hex;
        props.setCharts([...props.charts]);
        await props.fetchEditPersonalization(JSON.stringify(props.charts));
    };

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setColorPickerShow(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    return (
        <div style={{position: "relative"}}>
            <div style={{display: "flex", alignItems: "center"}}>
                <StopIcon htmlColor={color} style={{margin: '10 0 10 10'}} onClick={() => setColorPickerShow(true)}/>
                <p className="body4">{props.line['name']}</p>
            </div>
            <div ref={wrapperRef} style={{position: "absolute", zIndex: 99}}>
                {colorPickerShow? <SketchPicker color={color} onChange={(color) => setColor(color.hex)} onChangeComplete={(color) => handleColorPickerOnChangeComplete(color)} disableAlpha={true}/>: null}
            </div>
        </div>
    );
}
