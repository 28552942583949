export const oneWeekUnixTime = 1000 * 60 * 60 * 24 * 7;

export const attributeToStringJSON = {
    'meanHeatRatio': 'Mean Heat Ratio',
    'meanSoilMoisture': 'Mean Soil Moisture',
    'meanOutsideTemp': 'Mean Outside Temperature',
    'meanOutsideHumidity': 'Mean Outside Humidity'
};

export const stringToAttributeJSON = {
    'Mean Heat Ratio': 'meanHeatRatio',
    'Mean Soil Moisture': 'meanSoilMoisture',
    'Mean Outside Temperature': 'meanOutsideTemp',
    'Mean Outside Humidity': 'meanOutsideHumidity'
};

export function attributeToString(attribute) {
    return attributeToStringJSON[attribute];
}

export function parseAttribute(str) {
    return stringToAttributeJSON[str]
}

/**
 * Converts date to string format of date in the api.
 * @param date - date to be converted
 * @returns {string}
 */
export function dateToString(date) {
    return date.getUTCFullYear() + '/'
      + String(date.getUTCMonth() + 1).padStart(2, '0') + '/'
      + String(date.getUTCDate()).padStart(2, '0')
      + ' '
      + String(date.getUTCHours()).padStart(2, '0') + ':'
      + String(date.getUTCMinutes()).padStart(2, '0') + ':'
      + String(date.getUTCSeconds()).padStart(2, '0');
}

export function parseDate(str) {
    let dateAndTimeArr = str.split(' ');
    let dateArr = dateAndTimeArr[0].split('/');
    let timeArr = dateAndTimeArr[1].split(':');
    let d = new Date();
    d.setUTCFullYear(parseInt(dateArr[0]), parseInt(dateArr[1]) - 1, parseInt(dateArr[2]));
    d.setUTCHours(parseInt(timeArr[0]), parseInt(timeArr[1]), parseInt(timeArr[2]), 0);
    return new Date(d.getTime());
}

export function getDarkColor() {
    let letters = '123456789';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 9)];
    }
    return color;
}